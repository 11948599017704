import { Box, Flex, Link as RadixLink, Text } from "@radix-ui/themes";

export const Footer = () => (
  <Box px={{ initial: "5" }} mt="5" py="5">
    <Flex justify="between">
      <Box>&copy;{new Date().getFullYear()} Oyi Labs, Inc.</Box>

      <Flex gap="3">
        <RadixLink
          href="https://adtwin.ai/terms-of-service"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </RadixLink>
        <Text>•</Text>
        <RadixLink
          href="https://adtwin.ai/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </RadixLink>
      </Flex>
    </Flex>
  </Box>
);

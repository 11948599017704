import { RadioCards } from "@radix-ui/themes";
import { motion, useDragControls } from "framer-motion";
import React, { MouseEvent, ReactNode, cloneElement, useRef } from "react";
import { useController } from "react-hook-form";

type Props = {
  children: React.ReactNode;
  defaultValue?: string | null;
  name: string;
  onValueChange?: (arg1: unknown) => void;
};

export const RadioCard = ({
  children,
  defaultValue,
  name,
  onValueChange,
}: Props) => {
  const controls = useDragControls();
  const dragConstraintsRef = useRef(null);
  const isDraggingRef = useRef(false);

  const {
    field: { onChange, value },
    // fieldState: { error },
  } = useController({ name, defaultValue });

  //   const _error = error?.message as string;

  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (isDraggingRef.current) {
      e.preventDefault();
    }
  };

  const handleSelect = (value: string) => {
    if (isDraggingRef.current) return;
    onChange(value);

    if (onValueChange) {
      onValueChange(value);
    }
  };

  const count = React.Children.count(children);
  const leftOffset = (count - 2) * -300;

  return (
    <motion.div
      drag="x"
      ref={dragConstraintsRef}
      onDragStart={() => {
        isDraggingRef.current = true;
      }}
      onDragEnd={() => {
        isDraggingRef.current = false;
      }}
      dragControls={controls}
      dragConstraints={{ left: leftOffset, right: 0 }}
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(6, 300px)",
        paddingLeft: "calc((100vw - 668px) / 2 - 12px)",
        paddingRight: "4rem",
        gap: "1.5rem",
        overflow: "visible",
      }}
      className="hide-scrollbar"
    >
      <RadioCards.Root
        defaultValue={defaultValue ?? ""}
        columns={{
          initial: "1",
          sm: `repeat(${count}, minmax(300px, 1fr))`,
        }}
        onValueChange={handleSelect}
        value={value}
      >
        {React.Children.map(children, (child: ReactNode) =>
          cloneElement(child as any, {
            onClick,
          }),
        )}
      </RadioCards.Root>
    </motion.div>
  );
};

import { Text } from "@radix-ui/themes";

type InputErrorProps = {
  error: string;
};

export const InputError = ({ error }: InputErrorProps) => {
  return (
    error &&
    error !== "undefined" && (
      <Text size="2" color="red" mt="1">
        {String(error)}
      </Text>
    )
  );
};

import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { axiosInstance } from "@/lib/api";

export const narratorSchema = z.object({
  speech_library_name: z.string().optional(),
});

export type Narrator = z.infer<typeof narratorSchema>;

export type NarratorResponse = Narrator & {
  id: number;
  name: string;
  speech_library_name: string;
  avatar: string;
  bio: string;
  tags: string[];
  created_at?: string;
  updated_at?: string;
};

const getNarrators = async (params: Narrator): Promise<NarratorResponse[]> => {
  const { data } = await axiosInstance.get<NarratorResponse[]>(
    `/api/narrators?speech_library_name=${params.speech_library_name}`,
  );

  return data;
};

export const useNarrators = (params: Narrator) => {
  return useQuery({
    queryKey: ["narrators", params.speech_library_name],
    queryFn: () => getNarrators(params),
  });
};

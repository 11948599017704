import { useCampaign } from "@/api/campaigns";
import { Container, Flex, Heading } from "@radix-ui/themes";
import { createFileRoute } from "@tanstack/react-router";
import { redirect } from "@tanstack/react-router";

import { FEATURE_FLAGS } from "@/lib/featureFlags";

export const Route = createFileRoute(
  "/_authenticated/campaigns/$campaignId/edit",
)({
  component: CampaignsByIdEdit,
  beforeLoad: () => {
    if (!FEATURE_FLAGS.campaigns.enabled) redirect({ to: "/commercials" });
  },
});

function CampaignsByIdEdit() {
  const { campaignId } = Route.useParams();
  const id = Number(campaignId);

  const { data } = useCampaign(id);

  if (!data) return <div>Loading...</div>;
  const { name } = data;

  return (
    <Container>
      <Flex mb="5" justify="between">
        <Heading as="h1" size="8">
          Editing {name}
        </Heading>
      </Flex>

      {/* <CampaignEditForm campaignId={id} /> */}
    </Container>
  );
}

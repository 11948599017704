import { useRouterState } from "@tanstack/react-router";
import NProgress from "nprogress";
import { useEffect } from "react";

export const Progress = () => {
  const routerState = useRouterState();

  useEffect(() => {
    NProgress.configure({
      showSpinner: false
    });

    if (routerState.isLoading) {
      NProgress.start();
    }

    if (!routerState.isLoading) {
      NProgress.done();
    }
  }, [routerState]);

  return null;
};

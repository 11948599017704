import { useWizard } from "@/hooks/wizard";
import { Box } from "@radix-ui/themes";
import { ReactNode } from "@tanstack/react-router";
import React, { cloneElement, useEffect } from "react";

type Props = {
  children: React.ReactNode;
};

export const Wizard = ({ children }: Props) => {
  const refs = useWizard((state) => state.refs);
  const incrementStep = useWizard((state) => state.incrementStep);
  const currentStep = useWizard((state) => state.currentStep);
  const setData = useWizard((state) => state.setData);

  const onSubmit = (data: Record<string, unknown>) => {
    setData(data);
    if (currentStep === refs.length - 1) return;

    incrementStep();
  };

  // For testing
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (currentStep === 3) return;
  //     incrementStep();
  //   }, 5000);

  //   return () => clearInterval(interval);
  // }, [incrementStep, currentStep]);

  useEffect(() => {
    if (!refs[currentStep]) return;

    const bounding = refs[currentStep].getBoundingClientRect();
    const scrollY = window.scrollY;

    if (currentStep === 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      window.scrollTo({
        top: bounding.top + scrollY - 100,
        behavior: "smooth",
      });
    }
  }, [currentStep, refs]);

  return (
    <Box py="9">
      <Box pb="9">
        {React.Children.map(children, (child: ReactNode, index: number) =>
          cloneElement(child, {
            step: index,
            onSubmit,
            active: index <= currentStep,
          }),
        )}
      </Box>
    </Box>
  );
};

import { useCampaign } from "@/api/campaigns";
import {
  Badge,
  Button,
  Card,
  Container,
  Flex,
  Heading,
} from "@radix-ui/themes";
import { Link } from "@tanstack/react-router";
import { createFileRoute } from "@tanstack/react-router";
import { redirect } from "@tanstack/react-router";
import { format } from "date-fns";
import capitalize from "lodash/capitalize";

import { FEATURE_FLAGS } from "@/lib/featureFlags";

export const Route = createFileRoute("/_authenticated/campaigns/$campaignId/")({
  component: CampaignsByIdShow,
  beforeLoad: () => {
    if (!FEATURE_FLAGS.campaigns.enabled) redirect({ to: "/commercials" });
  },
});

function CampaignsByIdShow() {
  const { campaignId } = Route.useParams();

  const { data } = useCampaign(Number(campaignId));

  if (!data) return;

  const { created_at: start_date, name, id } = data;

  return (
    <Container>
      <Flex mb="5" justify="between">
        <Heading as="h1" size="8">
          {name}
        </Heading>

        <Button size="2" asChild>
          <Link
            to="/campaigns/$campaignId/edit"
            params={{ campaignId: String(id) }}
          >
            Edit Campaign
          </Link>
        </Button>
      </Flex>

      <Card size="4">
        <Flex pb="2" gap="3">
          <Badge color="orange">{capitalize(status)}</Badge>
          <Badge color="green">{format(start_date, "MMM dd, yyyy")}</Badge>
        </Flex>
      </Card>
    </Container>
  );
}

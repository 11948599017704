import { type CampaignResponse } from "@/api/campaigns";
import { Avatar, Badge, Box, Card, Flex, Heading } from "@radix-ui/themes";
import { Link } from "@tanstack/react-router";
import { format } from "date-fns";
import capitalize from "lodash/capitalize";

type CampaignCardProps = {
  campaign: CampaignResponse;
};

export const CampaignCard = ({ campaign }: CampaignCardProps) => {
  const { created_at: start_date, name, id } = campaign;

  return (
    <Card mb="5" size="2" asChild>
      <Link
        to="/campaigns/$campaignId"
        params={{
          campaignId: String(id),
        }}
      >
        <Flex gap="5" align="start">
          <Avatar
            size="7"
            src={`https://picsum.photos/seed/${id}/200`}
            loading="lazy"
            fallback={<Box width="5" height="5"></Box>}
          />
          <Box>
            <Flex pb="2" gap="3">
              <Badge color="orange">{capitalize(status)}</Badge>
              <Badge color="green">{format(start_date, "MMM dd, yyyy")}</Badge>
            </Flex>

            <Heading as="h3" size="7">
              {name}
            </Heading>

            {/* <Text as="p" style={{ maxWidth: "50rem" }}>
              {proposal}
            </Text> */}
          </Box>
        </Flex>
      </Link>
    </Card>
  );
};

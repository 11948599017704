import { Box, Card, Heading, Text } from "@radix-ui/themes";
import { ReactNode } from "react";

type ComponentProps = {
  children: ReactNode;
};

type ContentCardHeadProps = {
  children: ReactNode;
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | undefined;
};

const CardHeader = ({ children }: ComponentProps) => {
  return <Box mb="5">{children}</Box>;
};

const CardHeading = ({ children, as = "h3" }: ContentCardHeadProps) => {
  return (
    <Heading as={as} size="8">
      {children}
    </Heading>
  );
};

const CardSubHeading = ({ children }: ComponentProps) => {
  return (
    <Text as="p" size="2" color="gray" mt="1">
      {children}
    </Text>
  );
};

export const ContentCard = ({ children }: ComponentProps) => {
  return (
    <Card size="4" my="8">
      {children}
    </Card>
  );
};

ContentCard.Header = CardHeader;
ContentCard.Heading = CardHeading;
ContentCard.SubHeading = CardSubHeading;

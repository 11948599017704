import { useMutation } from "@tanstack/react-query";

import { axiosInstance } from "@/lib/api";
import { deleteSession } from "@/lib/session";
import { useSession } from "@/hooks/session";

const signOutRequest = async (): Promise<void> => {
  await axiosInstance.delete("/users/sign_out");
  deleteSession();
};

export const useSignOut = () => {
  const updateSession = useSession((state) => state.updateSession);

  return useMutation({
    mutationFn: signOutRequest,
    onSuccess: updateSession,
  });
};

import { type Company, createCompany } from "@/api/companies";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import pick from "lodash/pick";
import { z } from "zod";

import { axiosInstance } from "@/lib/api";

export const userProfileSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  address: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zip: z.string().optional(),
});

export type UserProfile = z.infer<typeof userProfileSchema>;
export type UserAndCompanyProfile = UserProfile & Company;

export type UserProfileResponse = UserProfile & {
  id: number;
  user_id: number;
  created_at: string;
  updated_at: string;
};

export const getUserProfile = async (): Promise<UserProfileResponse> => {
  const { data } = await axiosInstance.get("/api/user_profile");
  return data;
};

const createUserProfile = async (
  params: UserProfile,
): Promise<UserProfileResponse> => {
  const payload = userProfileSchema.parse(params);
  const { data } = await axiosInstance.post("/api/user_profile", {
    user_profile: payload,
  });

  return data;
};

const updateUserProfile = async (
  params: UserProfile,
): Promise<UserProfileResponse> => {
  const payload = userProfileSchema.parse(params);
  const { data } = await axiosInstance.patch("/api/user_profile", {
    user_profile: payload,
  });

  return data;
};

export const useUserProfile = () => {
  return useQuery({
    queryKey: ["user-profile"],
    queryFn: getUserProfile,
  });
};

export const useCreateUserProfile = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: UserProfile) => createUserProfile(params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user-profile"] });
    },
  });
};

export const useUpdateUserProfile = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: UserProfile) => updateUserProfile(params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user-profile"] });
    },
  });
};

export const useCreateUserAndCompanyProfile = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: UserAndCompanyProfile) => {
      const userParams = pick(params, ["first_name", "last_name"]);
      const companyParams = pick(params, ["name", "title", "image"]);

      const userProfile = await createUserProfile(userParams);
      const company = await createCompany(companyParams);

      return { userProfile, company };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user-profile"] });
      queryClient.invalidateQueries({ queryKey: ["companies"] });
      queryClient.invalidateQueries({ queryKey: ["user-companies"] });
    },
  });
};

import { useRootRef } from "@/hooks/rootRef";
import { Box, Flex, Text, TextField } from "@radix-ui/themes";
import { formatISO } from "date-fns";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { useEffect, useRef } from "react";
import { useController, useFormContext } from "react-hook-form";

import { InputError } from "./InputError";
import { Label } from "./Label";
import "./calendar.scss";

type Props = {
  id?: string;
  label: string;
  name: string;
  defaultValue?: string | number;
  helper?: string;
  placeholder?: string | null;
  register?: unknown;
  type?: string;
  addon?: string;
  disabled?: boolean;
};

export const Calendar = ({
  id,
  helper,
  label,
  name,
  defaultValue = formatISO(new Date()),
  placeholder = null,
  disabled,
}: Props) => {
  const rootRef = useRootRef((state) => state.rootRef);

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const {
    field: { onChange },
  } = useController({ name, defaultValue });

  const el = useRef(null);

  useEffect(() => {
    if (!el.current || !rootRef) return;

    flatpickr(el.current, {
      enableTime: true,
      appendTo: rootRef,
      defaultDate: defaultValue,
      onChange: (_, dateString) => {
        onChange(dateString);
      },
    });
  }, [el, rootRef, defaultValue, onChange]);

  const error = errors[name]?.message as string;

  return (
    <Box position="relative">
      <Flex direction="column">
        <Label text={label} htmlFor={id ?? name} />

        <TextField.Root
          className="calendar"
          placeholder={placeholder ?? ""}
          {...register(name)}
          id={id ?? name}
          ref={el}
          size="3"
          disabled={disabled}
        />

        {helper && (
          <Text size="1" color="gray" mt="1">
            {helper}
          </Text>
        )}

        <InputError error={error} />
      </Flex>
    </Box>
  );
};

export const getFilenameFromUrl = (url: string): string => {
  return url.split("/").pop() as string;
};

export const getAbsoluteUrl = (url: string): string => {
  if (!url.startsWith("http") && !url.startsWith("blob")) {
    return String(import.meta.env.VITE_API_URL) + url;
  }

  return url;
};

export const getFiletype = (filename: string): string => {
  const audioFileTypes = [".mp3", ".aac", ".ogg", ".wav", ".flac", ".wma"];
  const extension = audioFileTypes.find((type) => {
    if (filename.includes(type)) return type;
  });

  // Don't worry, it's probably an mp3
  if (!extension) return audioFileTypes[0];
  return extension;
};

export const downloadFile = async (src: string | undefined) => {
  if (!src) return;

  const filetype = getFiletype(src);
  const request = await fetch(getAbsoluteUrl(src));
  const blob = await request.blob();
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${crypto.randomUUID()}${filetype}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

import { Flex, TextArea as RadixTextArea, Text } from "@radix-ui/themes";
import { useFormContext, useWatch } from "react-hook-form";

import { InputError } from "./InputError";
import { Label } from "./Label";

type Props = {
  id?: string;
  label: string;
  name: string;
  defaultValue?: string;
  placeholder?: string;
  register?: any;
  optional: boolean;
  maxCharacters: number;
};

export const TextArea = ({
  id,
  label,
  name,
  defaultValue,
  placeholder,
  optional,
  maxCharacters,
  ...rest
}: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const error = errors[name]?.message as string;
  const currentCharacters = useWatch({ name });

  const currentLength = currentCharacters ? currentCharacters.length : 0;

  return (
    <Flex direction="column">
      <Flex direction="row" justify="between">
        <Label text={label} htmlFor={id ?? name} />
        {optional && (
          <Text size="1" htmlFor={id ?? name} weight="bold" color="gray">
            OPTIONAL
          </Text>
        )}
      </Flex>
      <RadixTextArea
        defaultValue={defaultValue ?? ""}
        {...register(name, {
          maxLength: {
            value: maxCharacters,
            message: `Maximum ${maxCharacters} characters allowed`,
          },
        })}
        id={id ?? name}
        placeholder={placeholder}
        resize="both"
        rows={10}
        size="3"
        {...rest}
      />

      <Flex direction="row" justify="between" mt="1">
        <div>
          <InputError error={error} />
        </div>
        <Text size="1" color="gray">
          {currentLength}/{maxCharacters}
        </Text>
      </Flex>
    </Flex>
  );
};

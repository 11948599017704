import { useBackgroundMusic } from "@/api/backgroundMusic";
import { Box, Flex } from "@radix-ui/themes";

import { Container } from "@/components/Container";
import { Field } from "@/components/Field";
import { Submit } from "@/components/Field/Submit";
import { Form } from "@/components/Form/Form";

type Props = {
  onSubmit?: () => Promise<unknown>;
  active?: boolean;
};

export const CommercialCreateStepMusic = ({ onSubmit, active }: Props) => {
  const bgMusic = useBackgroundMusic();

  if (!onSubmit) return null;

  return (
    <Form
      action={onSubmit}
      isSuccess={true}
      isPending={false}
      schema="createCommercialStep4"
      isDisabled={!active}
    >
      <Container size="2">
        <Flex gap="5" direction="column" mb="4">
          {bgMusic.isSuccess && (
            <Box>
              <Field
                data-testid="form-commercial-music"
                name="background_music_id"
                label="Music"
                type="select-music"
                music={bgMusic.data.filter(
                  (music) => music.name !== "No Music",
                )}
              />
            </Box>
          )}

          <Submit
            text="Continue"
            disabled={!active}
            data-testid="commercial-create-music-submit-button"
          />
        </Flex>
      </Container>
    </Form>
  );
};

import { isDevelopment } from "@/util/environment";

export const FEATURE_FLAGS = {
  campaigns: {
    enabled: isDevelopment(),
  },
  scriptNarrators: {
    enabled: true,
  },
  soundEffects: {
    enabled: false,
  },
};

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { axiosInstance } from "@/lib/api";

export const companySchema = z.object({
  name: z.string(),
  description: z.string().optional(),
  title: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zip: z.string().optional(),
  address: z.string().optional(),
  industry: z.string().optional(),
  image: z.string().optional(),
});

export type Company = z.infer<typeof companySchema>;

export type CompanyResponse = Company & {
  id: number;
  slug: string;
  user_id: number;
  created_at: string;
  updated_at: string;
};

export const getUserCompanies = async (
  userId: number,
): Promise<CompanyResponse[]> => {
  const { data } = await axiosInstance.get(`/api/users/${userId}/companies`);
  return data;
};

const getCompany = async (id: number): Promise<CompanyResponse> => {
  const { data } = await axiosInstance.get(`/api/companies/${id}`);
  return data;
};

export const createCompany = async (
  params: Company,
): Promise<CompanyResponse> => {
  const payload = companySchema.parse(params);
  const { data } = await axiosInstance.post("/api/companies", {
    company: payload,
  });

  return data;
};

const updateCompany = async (
  id: number,
  params: Company,
): Promise<CompanyResponse> => {
  const payload = companySchema.parse(params);
  const { data } = await axiosInstance.patch(`/api/companies/${id}`, {
    company: payload,
  });

  return data;
};

export const useCompany = (id: number) => {
  return useQuery({
    queryKey: ["companies", id],
    queryFn: () => getCompany(id),
    refetchOnWindowFocus: false,
  });
};

export const useUserCompany = (userId: number) => {
  return useQuery({
    queryKey: ["user-company", userId],
    queryFn: async () => {
      const companies = await getUserCompanies(userId);

      if (companies.length === 0) {
        throw new Error("User has no companies");
      }

      return companies[0];
    },
    refetchOnWindowFocus: false,
  });
};

export const useUpdateUserCompany = (userId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: Company) => {
      const companies = await getUserCompanies(userId);

      if (companies.length === 0) {
        throw new Error("User has no companies");
      }

      return await updateCompany(companies[0].id, params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["companies"] });
      queryClient.invalidateQueries({ queryKey: ["user-companies"] });
    },
  });
};

export const useUserCompanies = (userId: number) => {
  return useQuery({
    queryKey: ["user-companies", userId],
    queryFn: () => getUserCompanies(userId),
  });
};

export const useCreateCompany = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: Company) => createCompany(params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["companies"] });
      queryClient.invalidateQueries({ queryKey: ["user-companies"] });
      queryClient.invalidateQueries({ queryKey: ["user-company"] });
    },
  });
};

export const useUpdateCompany = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: Company) => updateCompany(id, params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["companies", id] });
      queryClient.invalidateQueries({ queryKey: ["user-companies"] });
    },
  });
};

import { type SourceFilesType } from "@/hooks/ffmpeg";
import {
  compressAndLimitFilters,
  getStreams,
  volumePanningFilters,
} from "@/hooks/ffmpeg/util";
import { FFmpeg } from "@ffmpeg/ffmpeg";

export const mp3Transcode = async (
  ffmpeg: FFmpeg,
  input: string,
  sourceFile: SourceFilesType,
): Promise<string> => {
  const mp3Output = `${crypto.randomUUID()}.mp3`;
  const filters = volumePanningFilters(sourceFile);

  const mp3Response = await ffmpeg.exec([
    "-i",
    input,
    "-c:a",
    "libmp3lame",
    "-filter_complex",
    filters,
    "-map",
    "[out]",
    "-ar",
    "44100",
    "-ab",
    "320k",
    mp3Output,
  ]);

  if (mp3Response !== 0) {
    throw new Error(`MP3 Transcoding Error: ${mp3Response}`);
  }

  return mp3Output;
};

export const sequentialCombine = async (
  ffmpeg: FFmpeg,
  files: string[],
): Promise<string> => {
  const output = `${crypto.randomUUID()}.mp3`;
  const streams = getStreams(files);

  const response = await ffmpeg.exec([
    "-i",
    ...files.join(" -i ").split(" "),
    "-filter_complex",
    `${streams}concat=n=${files.length}:v=0:a=1[out]`,
    "-map",
    "[out]",
    output,
  ]);

  if (response !== 0) {
    throw new Error(`Sequential Combine Error: ${response}`);
  }

  return output;
};

export const finalMix = async (
  ffmpeg: FFmpeg,
  files: string[],
  length: number,
): Promise<string> => {
  const output = `${crypto.randomUUID()}.mp3`;

  const response = await ffmpeg.exec([
    "-i",
    ...files.join(" -i ").split(" "),
    "-filter_complex",
    compressAndLimitFilters(files, length),
    "-map",
    "[mix]",
    output,
  ]);

  if (response !== 0) {
    throw new Error(`Sequential Combine Error: ${response}`);
  }

  return output;
};

import { PauseIcon, PlayIcon } from "@radix-ui/react-icons";
import { Button } from "@radix-ui/themes";
import React, { useRef, useState } from "react";

interface AudioPlayerProps {
  src: string;
}

export const SimpleAudioPlayer: React.FC<AudioPlayerProps> = ({ src }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlayPause = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div>
      <audio ref={audioRef} src={src} />

      <Button
        onClick={togglePlayPause}
        style={{ padding: "12px" }}
        size="4"
        color={isPlaying ? undefined : "gray"}
        radius="full"
      >
        {isPlaying ? (
          <PauseIcon height="24" width="24" />
        ) : (
          <PlayIcon height="24" width="24" />
        )}
      </Button>
    </div>
  );
};

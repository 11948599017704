import { Box, Flex, Grid, Heading, RadioCards, Text } from "@radix-ui/themes";
import { useWatch } from "react-hook-form";

import { Container } from "@/components/Container";
import { Field } from "@/components/Field";
import { Calendar } from "@/components/Field/Calendar";
import { RadioCard } from "@/components/Field/RadioCard";
import { Submit } from "@/components/Field/Submit";
import { Form } from "@/components/Form/Form";

type Props = {
  onSubmit?: () => Promise<unknown>;
  active?: boolean;
};

const PLAN_OPTIONS = [
  {
    title: "2 Weeks",
    cost: "$500.00",
    endDate: "August 14",
    impressions: 125000,
  },
  {
    title: "4 Weeks",
    cost: "$500.00",
    endDate: "August 14",
    impressions: 125000,
  },
  {
    title: "6 Weeks",
    cost: "$500.00",
    endDate: "August 14",
    impressions: 125000,
  },
  {
    title: "Custom",
    cost: "$500.00",
    endDate: "August 14",
    impressions: 125000,
  },
];

export const CampaignCreateStepSchedule = ({ onSubmit, active }: Props) => {
  if (!onSubmit) return null;

  return (
    <Form
      action={onSubmit}
      isSuccess
      isPending={false}
      // schema="campaignCreateStepCommercial"
      isDisabled={!active}
    >
      <Inner />
    </Form>
  );
};

const Inner = () => {
  const plansValue = useWatch({ name: "plans" });

  return (
    <Flex gap="6" direction="column" mb="4" style={{ overflow: "hidden" }}>
      <Box>
        <Container size="2">
          <Heading size="5" mb="3">
            Plans
          </Heading>
        </Container>

        <RadioCard name="plans">
          {PLAN_OPTIONS.map((option) => (
            <RadioCards.Item value={option.title} key={option.title}>
              <Flex direction="column" width="100%">
                <Flex mb="2" justify="between">
                  <Text weight="bold" size="4">
                    {option.title}
                  </Text>
                  <Text weight="bold" size="4">
                    {option.cost}
                  </Text>
                </Flex>
                <Text size="2">Ends {option.endDate}</Text>
                <Text size="2">{option.impressions} Est. Impressions</Text>
              </Flex>
            </RadioCards.Item>
          ))}
        </RadioCard>
      </Box>

      <Container size="2">
        <Grid columns="1fr 1fr" gap="5">
          <Field name="start_date" label="Start Date" type="calendar" />
          <Calendar
            name="end_date"
            label="End Date"
            disabled={plansValue !== "Custom"}
          />
        </Grid>
      </Container>

      <Container size="2">
        {plansValue === "Custom" && (
          <Grid columns="1fr 1fr" gap="5" mb="6">
            <Field name="budget" label="Budget" type="money" />
          </Grid>
        )}

        <Submit text="Continue" />
      </Container>
    </Flex>
  );
};

import { type ScriptLineResponse } from "@/api/scriptLines";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { z } from "zod";

import { axiosInstance } from "@/lib/api";

import { BackgroundMusic, getBackgroundMusic } from "./backgroundMusic";

export const scriptCreateSchema = z.object({
  length: z.number(),
  background_music_id: z.number(),
  narrator_id: z.string(),
  commercial_id: z.number(),
});

export const scriptUpdateSchema = z.object({
  background_music_id: z.number(),
});

export type ScriptCreate = z.infer<typeof scriptCreateSchema>;
export type ScriptUpdate = z.infer<typeof scriptUpdateSchema>;
export type ScriptState =
  | "fresh"
  | "generating_script"
  | "script_generated"
  | "generating_speeches"
  | "speeches_generated"
  | "tts_started"
  | "combining"
  | "combined"
  | "hidden";

export type ScriptResponse = {
  id: number;
  prompt: string;
  aasm_state: ScriptState;
  length: number;
  background_music?: BackgroundMusic | null;
  script_lines: ScriptLineResponse[];
  created_at: string;
  updated_at: string;
};

export const getCommercialScripts = async (
  commercialId: number,
): Promise<ScriptResponse[]> => {
  const { data } = await axiosInstance.get<ScriptResponse[]>(
    `api/commercials/${commercialId}/scripts`,
  );
  return data;
};

export const getScript = async (
  commercialId: number,
  id: number,
): Promise<ScriptResponse> => {
  const { data } = await axiosInstance.get<ScriptResponse>(
    `/api/commercials/${commercialId}/scripts/${id}`,
  );

  return data;
};

export const createScript = async (
  commercialId: number,
  params: ScriptCreate,
): Promise<ScriptResponse> => {
  const payload = scriptCreateSchema.parse(params);
  const { data } = await axiosInstance.post<ScriptResponse>(
    `/api/commercials/${commercialId}/scripts`,
    {
      script: {
        commercial_id: payload.commercial_id,
        background_music_id: payload.background_music_id,
        narrator_id: +payload.narrator_id,
        length: Number(payload.length),
      },
    },
  );

  return data;
};

const updateScript = async (
  commercialId: number,
  scriptId: number,
  params: ScriptUpdate,
): Promise<ScriptResponse> => {
  const payload = scriptUpdateSchema.parse(params);
  const { data } = await axiosInstance.patch<ScriptResponse>(
    `/api/commercials/${commercialId}/scripts/${scriptId}`,
    {
      script: payload,
    },
  );

  return data;
};

export const prefetchCommercialScripts = async (
  queryClient: QueryClient,
  commercialId: number,
) => {
  await queryClient.prefetchQuery({
    queryKey: ["commercial-scripts", commercialId],
    queryFn: async () => getCommercialScripts(commercialId),
    staleTime: 5000,
  });
};

export const useCommercialScripts = (commercialId: number, opts?: object) => {
  return useQuery({
    queryKey: ["commercial-scripts", commercialId],
    queryFn: async () => getCommercialScripts(commercialId),
    refetchOnWindowFocus: false,
    retryDelay: 5000,
    retry: true,
    ...opts,
  });
};

export const useScript = (commercialId: number, id: number, opts?: object) => {
  return useQuery({
    queryKey: ["scripts", id],
    queryFn: async () => getScript(commercialId, id),
    retryDelay: 5000,
    retry: true,
    ...opts,
  });
};

export const useUpdateScript = (commercialId: number, scriptId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: ScriptUpdate): Promise<void> => {
      await updateScript(commercialId, scriptId, params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["scripts", scriptId],
      });

      queryClient.invalidateQueries({
        queryKey: ["commercial-scripts", commercialId],
      });
    },
  });
};

export const useCreateScript = (commercialId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: ScriptCreate): Promise<number> => {
      // Add no music by default
      // Leaves it open for us to easily allow users
      // to set their background music before script creation
      const backgroundMusic = await getBackgroundMusic();
      const noMusic = backgroundMusic.find((music) => {
        return music.name === "No Music";
      });

      if (noMusic && !params.background_music_id) {
        params.background_music_id = noMusic.id;
      }

      const script = await createScript(commercialId, params);
      return script.id;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["commercials", commercialId],
      });

      queryClient.invalidateQueries({
        queryKey: ["commercial-scripts", commercialId],
      });
    },
  });
};

import {
  type CompleteCommercial,
  useCreateCompleteCommercial,
} from "@/api/commercials";
import { useCreateCompany, useUserCompanies } from "@/api/companies";
import { useSession } from "@/hooks/session";
import { useWizard } from "@/hooks/wizard";
import { Flex } from "@radix-ui/themes";
import { useNavigate } from "@tanstack/react-router";
import { useWatch } from "react-hook-form";

import { Container } from "@/components/Container";
import { Field } from "@/components/Field";
import { Submit } from "@/components/Field/Submit";
import { Form } from "@/components/Form/Form";

import { getUserId } from "@/lib/session";

const LENGTH_OPTIONS = [
  {
    label: "15 Seconds",
    value: "15",
  },
  {
    label: "30 Seconds",
    value: "30",
  },
  {
    label: "45 Seconds",
    value: "45",
  },
  {
    label: "60 Seconds",
    value: "60",
  },
];

type Props = {
  onSubmit?: () => Promise<unknown>;
  active?: boolean;
};

export const CommercialCreateStepMeta = ({ active }: Props) => {
  const { session } = useSession();
  const formData = useWizard((state) => state.data);
  const navigate = useNavigate();

  const completeCommercialCreate = useCreateCompleteCommercial();
  const userCompanies = useUserCompanies(getUserId());
  const createCompany = useCreateCompany();

  const findOrCreateCompany = async (
    companyName: string,
    companyDescription: string,
  ): Promise<number> => {
    if (!userCompanies.isSuccess) throw new Error("Companies not loaded.");

    const existingCompanies = userCompanies.data.map((company) => company.name);
    if (!existingCompanies.includes(companyName)) {
      const newCompany = await createCompany.mutateAsync({
        name: companyName,
        description: companyDescription,
      });

      return newCompany.id;
    }

    const company = userCompanies.data.find(
      (company) => company.name === companyName,
    );

    if (!company) throw new Error("Company not found");

    return company.id;
  };

  const onSubmit = async (stepData: CompleteCommercial) => {
    const data = { ...formData, ...stepData };

    if (!session?.is_internal) {
      data.speech_library_id = String(formData?.speech_library_id) || "1";
    }

    const companyId = await findOrCreateCompany(
      data.company_name,
      data.company_description,
    );

    const commercial = await completeCommercialCreate.mutateAsync({
      ...data,
      company_id: companyId,
    });

    if (commercial) {
      navigate({
        to: "/commercials/$commercialId",
        params: { commercialId: String(commercial.id) },
      });
    }
  };

  const isSuccess = completeCommercialCreate.isSuccess;
  const isPending = completeCommercialCreate.isPending;

  return (
    <Container size="2">
      <Form
        action={onSubmit}
        isSuccess={isSuccess}
        isPending={isPending}
        schema="createCommercialStep5"
        isDisabled={!active}
      >
        <InnerForm isPending={isPending} />
      </Form>
    </Container>
  );
};

type InnerFormProps = {
  isPending: boolean;
};

const InnerForm = ({ isPending }: InnerFormProps) => {
  const name = useWatch({ name: "name" });
  const length = useWatch({ name: "length" });

  const disabled = isPending || !name || !length;

  return (
    <Flex gap="5" direction="column" mb="4">
      <Field
        data-testid="commercial-create-name"
        name="name"
        label="What do you want to name this commercial?"
        placeholder="Best Commercial MVP"
      />

      <Field
        data-testid="commercial-create-length"
        name="length"
        label="Commercial Length"
        type="select"
        options={LENGTH_OPTIONS}
        placeholder="How long do you want your commercial to be?"
      />

      <Submit
        text="Create Your Commercial"
        disabled={disabled}
        loading={isPending}
        data-testid="commercial-create-meta-submit-button"
      />
    </Flex>
  );
};

import { Box } from "@radix-ui/themes";
import { Outlet, createRootRoute } from "@tanstack/react-router";
import { Suspense, lazy } from "react";

import { Navigation } from "@/components/Navigation";
import { Progress } from "@/components/Progress";

const TanStackRouterDevtools =
  String(import.meta.env.VITE_DEV_TOOLS) === "false"
    ? () => null
    : lazy(() =>
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
        })),
      );

const ReactQueryDevtools =
  String(import.meta.env.VITE_DEV_TOOLS) === "false"
    ? () => null
    : lazy(() =>
        import("@tanstack/react-query-devtools").then((res) => ({
          default: res.ReactQueryDevtools,
        })),
      );

export const Route = createRootRoute({
  component: () => (
    <Box>
      <Progress />
      <Navigation />

      <Outlet />
      <Suspense fallback={null}>
        <TanStackRouterDevtools />
        <ReactQueryDevtools initialIsOpen={false} />
      </Suspense>
    </Box>
  ),
  notFoundComponent: NotFound,
});

function NotFound() {
  return <>Not Found.</>;
}

import { useMutation } from "@tanstack/react-query";
import { z } from "zod";

import { axiosInstance } from "@/lib/api";

const changePasswordSchema = z.object({
  current_password: z.string(),
  new_password: z.string(),
});

type ChangePassword = z.infer<typeof changePasswordSchema>;

const changePasswordRequest = async (params: ChangePassword): Promise<void> => {
  const payload = changePasswordSchema.parse(params);
  await axiosInstance.post<void>("/users/tokens/reset_password", payload);
};

export const useChangePassword = () => {
  return useMutation({
    mutationFn: changePasswordRequest,
  });
};

import { ImageIcon, ReloadIcon } from "@radix-ui/react-icons";
import { Avatar, Box, Flex, Text } from "@radix-ui/themes";
import { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useController, useFormContext } from "react-hook-form";
import { toast } from "react-hot-toast";

import { ActiveStorageCallbackType, upload } from "@/lib/activeStorage";

import { InputError } from "./InputError";
import { Label } from "./Label";

type ImageProps = {
  name: string;
  id?: string;
  label: string;
  defaultValue?: string;
};

const baseStyle = {
  alignItems: "center",
  alignSelf: "center",
  color: "#aaa",
  cursor: "pointer",
  display: "flex",
  height: "140px",
  justifyContent: "center",
  outline: "none",
  transition: "border .24s ease-in-out",
  width: "140px",
  borderRadius: "1000px",
  border: "1px solid #333",
};

const focusedStyle = {
  background: "#fff",
  borderColor: "#ccc",
};

const acceptStyle = {
  borderColor: "#00e676",
  color: "#666",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export const ImageField = ({
  name,
  id,
  label,
  defaultValue = "",
}: ImageProps) => {
  const [uploadedImage, setUploadedImage] = useState<string>(
    defaultValue ?? "",
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  const {
    field: { onChange },
  } = useController({ name });

  const error = errors[name]?.message as string;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    isFocused,
  } = useDropzone({
    onDrop: async (acceptedFiles) => {
      setIsLoading(true);

      if (acceptedFiles.length) {
        await upload(
          acceptedFiles[0],
          async (blob: ActiveStorageCallbackType) => {
            if (blob.error) {
              setIsLoading(false);
              toast.error("Image could not be uploaded. Please try again.");
              return;
            }

            // hacky, but works.
            // we're trying to trigger isDirty in the form state when an image is updated.
            onChange([]);
            setUploadedImage(blob.url);
            setValue(name, blob.signed_id);
            setIsLoading(false);
          },
        );
      } else {
        setIsLoading(false);
        toast.error(
          "Image was not accepted. Please check your file and try again.",
        );
      }
    },
    accept: {
      "image/jpg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
  });

  const getImageUrl = (url: string): string => {
    if (!url) return "";

    if (url.includes("http")) {
      return url;
    }

    return String(import.meta.env.VITE_API_URL) + url;
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <Flex direction="column">
      <Label text={label} htmlFor={id ?? name} />

      <div {...getRootProps({ style })}>
        <input {...getInputProps()} id={id ?? name} />

        <Box>
          {uploadedImage && !isLoading && !isDragActive && (
            <>
              <Avatar
                size="9"
                radius="full"
                src={getImageUrl(uploadedImage)}
                alt="An uploaded image."
                fallback="A"
              />
            </>
          )}
          {!uploadedImage && !isLoading && !isDragActive && (
            <Flex direction="column" align="center">
              <ImageIcon width={30} height={30} />
              <Text size="2" weight="bold" mt="2">
                Upload Image
              </Text>
            </Flex>
          )}
          {!isLoading && isDragActive && (
            <Flex direction="column" align="center">
              <ImageIcon width={50} height={50} />
              <Text size="3" weight="bold">
                Drop the image
              </Text>
            </Flex>
          )}
          {isLoading && (
            <Flex direction="column" align="center">
              <ReloadIcon className="animate-spin" width={75} height={75} />
              <Text size="3" weight="bold">
                Uploading
              </Text>
            </Flex>
          )}
        </Box>
      </div>
      <InputError error={error} />
    </Flex>
  );
};

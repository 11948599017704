import { useQuery } from "@tanstack/react-query";

import { axiosInstance } from "@/lib/api";

export type StripeBillingPortalResponse = {
  id: string;
  customer: string;
  url: string;
};

const getStripeBillingPortalSession = async (): Promise<StripeBillingPortalResponse> => {
  const { data } = await axiosInstance.get("/api/stripe_billing_portal");
  return data;
};

export const useStripeBillingPortal = () => {
  return useQuery({
    queryKey: ["stripe-billing-portal"],
    queryFn: getStripeBillingPortalSession,
  });
};

import { useMutation } from "@tanstack/react-query";
import { z } from "zod";

import { axiosInstance } from "@/lib/api";

export const userConfirmationSchema = z.object({
  confirmation_token: z.string(),
});

export type UserConfirmation = z.infer<typeof userConfirmationSchema>;

export type UserConfirmationResponse = {
  message: string;
};

export const userConfirmation = async (
  params: UserConfirmation,
): Promise<UserConfirmationResponse> => {
  const payload = userConfirmationSchema.parse(params);
  const { data } = await axiosInstance.get(
    `/users/confirmation?confirmation_token=${payload.confirmation_token}`,
  );

  return data;
};

export const useUserConfirmation = () => {
  return useMutation({
    mutationFn: (params: UserConfirmation) => userConfirmation(params),
  });
};

import { useStripeCustomer } from "@/api/stripeCustomers";
import { useCreateStripeSetupIntent } from "@/api/stripeSetupIntent";
import { useProcessPayment } from "@/hooks/payments";
import {
  Box,
  Card,
  Container,
  Grid,
  Heading,
  Separator,
  Text,
} from "@radix-ui/themes";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { addDays, format } from "date-fns";
import { useEffect, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { z } from "zod";

import { AddCard } from "@/components/Forms/Stripe";

import { getSession } from "@/lib/session";

const stripeSearchSchema = z.object({
  setup_intent: z.string().optional(),
  setup_intent_client_secret: z.string().optional(),
  redirect_status: z.enum([
    "succeeded",
    "processing",
    "requires_payment_method",
    "pending",
  ]),
});

export const Route = createFileRoute("/_authenticated/onboarding/billing/")({
  component: OnboardingBillingShow,
  validateSearch: stripeSearchSchema,
  beforeLoad: async () => {
    const session = getSession();

    if ((session && session.billing_present) || session?.trial) {
      throw redirect({
        to: "/commercials",
      });
    }
  },
});

function OnboardingBillingShow() {
  useStripeCustomer();
  const search = Route.useSearch();
  const navigate = Route.useNavigate();
  const payment = useProcessPayment(search.redirect_status);
  const isPaymentProcessed = useRef(false);
  const intent = useCreateStripeSetupIntent();
  const stripePromise = loadStripe(
    String(import.meta.env.VITE_STRIPE_PUBLIC_KEY),
  );

  const nextInvoiceDate = format(addDays(new Date(), 2), "MM/dd/yyyy");

  useEffect(() => {
    if (
      !isPaymentProcessed.current &&
      !payment.isLoading &&
      payment.isProcessing()
    ) {
      isPaymentProcessed.current = true;
      payment.process();
    }
  }, [payment]);

  useEffect(() => {
    if (!payment.isRedirect) return;

    if (payment.isSuccess) {
      navigate({
        to: "/commercials",
      });
    }

    if (!payment.isSuccess) {
      navigate({
        to: "/onboarding/billing",
        search: { redirect_status: "pending" },
      });
    }
  }, [payment, navigate]);

  return (
    <Container mt={{ initial: "5", md: "8" }}>
      <Grid columns="1fr 2fr" gap="8">
        <Box>
          <Heading as="h1" size="8">
            Billing Details
          </Heading>

          <Heading as="h2" size="4" mt="2" weight="light">
            2 day free trial. Just $10 per month after.
          </Heading>

          <Separator my="5" size="4" />

          <Grid columns="1fr 1fr" gap="3" mb="3">
            <Text weight="light" color="gray">
              Next Invoice
            </Text>
            <Text align="right" color="gray">
              {nextInvoiceDate}
            </Text>
          </Grid>

          <Grid columns="1fr 1fr" gap="3">
            <Text weight="bold">Total due today</Text>
            <Text align="right">$0</Text>
          </Grid>

          <Grid columns="1fr 1fr" gap="3">
            <Text weight="bold">Total due next invoice</Text>
            <Text align="right">$10</Text>
          </Grid>
        </Box>

        <Card size="4">
          {payment.isProcessing() && (
            <>
              <Heading as="h4" mb="4">
                Processing...
              </Heading>
              <Skeleton count={2} height={50} />
            </>
          )}
          {!payment.isProcessing() && stripePromise && intent.isSuccess && (
            <Elements
              stripe={stripePromise}
              options={{
                appearance: { theme: "night" },
                clientSecret: intent.data.client_secret,
              }}
            >
              <AddCard />
            </Elements>
          )}
        </Card>
      </Grid>
    </Container>
  );
}

import { useDeleteScriptLine } from "@/api/scriptLines";
import { Speech } from "@/api/scriptLines";
import { useSpeech } from "@/api/speeches";
import { DotsVerticalIcon } from "@radix-ui/react-icons";
import {
  AlertDialog,
  Box,
  Button,
  DropdownMenu,
  Flex,
  IconButton,
} from "@radix-ui/themes";

import { downloadFile } from "@/util/download";

type Props = {
  setNewScriptLine: React.Dispatch<
    React.SetStateAction<"before" | "after" | undefined>
  >;
  speech: Speech;
  commercialId: number;
  scriptId: number;
  scriptLineFrontendGuid: string;
};

export const ScriptLineDropdown = ({
  setNewScriptLine,
  speech,
  commercialId,
  scriptId,
  scriptLineFrontendGuid,
}: Props) => {
  const { data } = useSpeech(
    commercialId,
    scriptId,
    scriptLineFrontendGuid,
    speech.id,
  );

  const deleteScriptLine = useDeleteScriptLine(
    commercialId,
    scriptId,
    scriptLineFrontendGuid,
  );

  const handleDeleteLine = async () => {
    await deleteScriptLine.mutateAsync();
  };

  const audioUrl = data?.voice_conversions
    ? data?.voice_conversions?.[data?.voice_conversions.length - 1]?.audio_file
        ?.url
    : data?.audio_file?.url;

  return (
    <>
      {/* TODO - Move me into my own component? */}
      <AlertDialog.Root>
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <IconButton
              size="1"
              variant="soft"
              color="gray"
              radius="none"
              className="Script__aside__add-button"
            >
              <DotsVerticalIcon />
            </IconButton>
          </DropdownMenu.Trigger>

          <DropdownMenu.Content
            onCloseAutoFocus={(e) => {
              e.preventDefault();
            }}
          >
            <DropdownMenu.Item onClick={() => setNewScriptLine("before")}>
              Insert line above
            </DropdownMenu.Item>
            <DropdownMenu.Item onClick={() => setNewScriptLine("after")}>
              Insert line below
            </DropdownMenu.Item>
            <DropdownMenu.Separator />
            <DropdownMenu.Item disabled={!audioUrl} asChild>
              <Box onClick={() => downloadFile(audioUrl)}>Download</Box>
            </DropdownMenu.Item>
            <DropdownMenu.Separator />
            <AlertDialog.Trigger>
              <DropdownMenu.Item disabled={!audioUrl} color="red">
                Delete line
              </DropdownMenu.Item>
            </AlertDialog.Trigger>
          </DropdownMenu.Content>
        </DropdownMenu.Root>

        <AlertDialog.Content>
          <AlertDialog.Title>Delete Line</AlertDialog.Title>
          <AlertDialog.Description>
            Are you sure you to delete this line? This cannot be undone.
          </AlertDialog.Description>

          <Flex gap="3" mt="4" justify="end">
            <AlertDialog.Cancel>
              <Button variant="soft" color="gray">
                Cancel
              </Button>
            </AlertDialog.Cancel>

            <AlertDialog.Action>
              <Button variant="solid" color="red" onClick={handleDeleteLine}>
                Delete line
              </Button>
            </AlertDialog.Action>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
    </>
  );
};

import { useRef } from "react";

export function useOnMount(callback: () => void) {
  const hasRun = useRef(false);

  if (!hasRun.current) {
    callback();
    hasRun.current = true;
  }
}

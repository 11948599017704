import {
  finalMix,
  mp3Transcode,
  sequentialCombine,
} from "@/hooks/ffmpeg/commands";
import { delay, fetchFileUrl, getFilename } from "@/hooks/ffmpeg/util";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { toBlobURL } from "@ffmpeg/util";
import { useCallback, useEffect, useState } from "react";

import { Deque } from "@/util/deque";
import { isDevelopment } from "@/util/environment";

type FFmpegLoggerType = {
  message: unknown;
};

export type SourceFilesType = {
  id: number;
  url: string;
  volume: `${number}dB`;
  panning: "left" | "center" | "right";
};

export const useFFmpeg = () => {
  const [jobs] = useState(new Deque<SourceFilesType[]>());
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isFfmpegRunning, setIsFfmpegRunning] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sourceFilesProcessed, setSourceFilesProcessed] = useState<
    SourceFilesType[]
  >([]);
  const [ffmpeg, setFfmpeg] = useState<FFmpeg>(new FFmpeg());
  const [backgroundAudio, setBackgroundAudio] = useState<SourceFilesType>();
  const [commercialLength, setCommercialLength] = useState<number>(0);
  const [filename, setFilename] = useState<string>("");
  const [combinedAudio, setCombinedAudio] = useState<string>("");

  const init = useCallback(async (): Promise<FFmpeg> => {
    const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/esm";

    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript"),
      wasmURL: await toBlobURL(
        `${baseURL}/ffmpeg-core.wasm`,
        "application/wasm",
      ),
    });

    return ffmpeg;
  }, [ffmpeg]);

  const loadFile = async (url: string): Promise<string> => {
    const filename = getFilename(url);
    await ffmpeg.writeFile(filename, await fetchFileUrl(url));

    return filename;
  };

  const process = async (sourceFiles: SourceFilesType[]): Promise<string[]> => {
    return await Promise.all(
      sourceFiles.map(async (sourceFile) => {
        const filename = await loadFile(sourceFile.url);
        return await mp3Transcode(ffmpeg, filename, sourceFile);
      }),
    );
  };

  const addCommercialLength = (length: number): void => {
    setCommercialLength(length);
  };

  const addBackgroundAudio = (sourceFile: SourceFilesType): void => {
    setBackgroundAudio(sourceFile);
  };

  const combine = async (sourceFiles: SourceFilesType[]): Promise<void> => {
    jobs.enqueueLast(sourceFiles);

    const exec = async () => {
      // Recursively check for running state
      if (isFfmpegRunning) {
        await delay(1);
        await exec();
      }

      // Grab the last combine job
      const combineJob = jobs.dequeueLast();

      // Clear all other remaining jobs
      jobs.clear();

      // Execute Combine
      if (combineJob) await executeCombine(combineJob);
    };

    await exec();
  };

  const executeCombine = async (
    sourceFiles: SourceFilesType[],
    attempts = 0,
  ): Promise<void> => {
    try {
      setIsLoading(true);
      setIsFfmpegRunning(true);
      setSourceFilesProcessed(sourceFiles);

      // load files into ffmpeg filesystem and transcode
      const files = await process(sourceFiles);

      // Combine main script lines sequentially
      const sequential = await sequentialCombine(ffmpeg, files);

      // Mix final output and add background audio if present
      const finalMixFiles = [sequential];

      if (backgroundAudio) {
        const backgroundAudioFile = await process([backgroundAudio]);
        finalMixFiles.push(backgroundAudioFile[0]);
      }

      const finalAudio = await finalMix(
        ffmpeg,
        finalMixFiles,
        commercialLength,
      );

      // Create final audio blob
      const fileData = await ffmpeg.readFile(finalAudio);
      const data = new Uint8Array(fileData as ArrayBuffer);
      const combined = URL.createObjectURL(
        new Blob([data.buffer], { type: "audio/mpeg" }),
      );

      setIsFfmpegRunning(false);
      setIsLoading(jobs.length !== 0);
      setFilename(finalAudio);
      setCombinedAudio(combined);
    } catch (e) {
      if (isDevelopment()) console.error(e);

      // Retry with exponential backoff
      const failedAttempts = attempts + 1;
      if (failedAttempts <= 3) {
        await delay(failedAttempts);
        await executeCombine(sourceFiles, failedAttempts);
      }
    } finally {
      await resetFfmpeg();
    }
  };

  const resetFfmpeg = async () => {
    if (ffmpeg) {
      const ffmpegInstance = await init();
      setFfmpeg(ffmpegInstance);
    }
  };

  const handleDownload = async () => {
    const request = await fetch(combinedAudio);
    const blob = await request.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const loggerCallback = ({ message }: FFmpegLoggerType) => {
    const enableLogger = false;
    if (isDevelopment() && enableLogger) console.log(message);
  };

  useEffect(() => {
    init()
      .then((instance) => {
        instance.on("log", loggerCallback);
        setFfmpeg(instance);
      })
      .then(() => {
        setIsReady(true);
      });

    return () => {
      ffmpeg.off("log", loggerCallback);
    };
  }, [init, setFfmpeg, setIsReady, ffmpeg]);

  return {
    isReady,
    isLoading,
    filename,
    sourceFilesProcessed,
    combinedAudio,
    backgroundAudio,
    addBackgroundAudio,
    commercialLength,
    addCommercialLength,
    combine,
    handleDownload,
  };
};

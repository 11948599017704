import { type CommercialResponse, prefetchCommercial } from "@/api/commercials";
import { prefetchCommercialScripts } from "@/api/scripts";
import { Box, Flex, Grid, Heading, Text } from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";
import { format } from "date-fns";
import { useEffect, useState } from "react";

import { AudioPlayer } from "../AudioPlayer";
import { CommercialRowDropdown } from "./CommercialRowDropdown";

type Props = {
  commercial: CommercialResponse;
  index: number;
};

const getAudioDuration = async (url: string): Promise<string> => {
  const audio = new Audio(url);

  return new Promise((resolve, reject) => {
    try {
      const audioHandler = () => {
        const seconds = Math.floor(audio.duration) % 60;
        const minutes = Math.floor(audio.duration / 60);
        const terryTime =
          minutes >= 1 ? `${minutes}m ${seconds}s` : `${seconds}s`;

        audio.removeEventListener("loadedmetadata", audioHandler);

        return resolve(terryTime);
      };

      audio.addEventListener("loadedmetadata", audioHandler);
    } catch (e) {
      reject(e);
    }
  });
};

export const CommercialRow = ({ commercial, index }: Props) => {
  const queryClient = useQueryClient();
  const [duration, setDuration] = useState<string | null>(null);
  const { created_at, name, final_audio, id } = commercial;

  const prefetch = async () => {
    await Promise.all([
      await prefetchCommercial(queryClient, id),
      await prefetchCommercialScripts(queryClient, id),
    ]);
  };

  useEffect(() => {
    const getAudio = async () => {
      if (!final_audio?.url) return;
      const duration = await getAudioDuration(final_audio?.url);
      setDuration(duration);
    };

    getAudio();
  }, [final_audio]);

  return (
    <Box
      className="DividedRow"
      px={{ md: "5" }}
      data-testid={`commercial-row-${index}`}
      data-id={commercial.id}
    >
      <Grid
        columns={{ initial: "1fr", md: "35% 1fr" }}
        gap={{ initial: "1", md: "5" }}
        pb={{ initial: "3", md: "0" }}
        align="start"
      >
        <Flex
          py={{ md: "5" }}
          pt={{ initial: "3" }}
          className="DividedRow__main"
          position="relative"
        >
          <Box px={{ initial: "5", md: "0" }}>
            <Link
              data-testid="commercial-index-row"
              onMouseOver={prefetch}
              to="/commercials/$commercialId"
              params={{
                commercialId: String(id),
              }}
              className="Link"
            >
              <Heading as="h3" size="7" data-testid="commercial-row-title">
                {name}
              </Heading>
              <Text>
                {duration && `${duration}  •  `}
                {format(created_at, "MM-dd-yyyy")}
              </Text>
            </Link>
          </Box>

          <CommercialRowDropdown commercial={commercial} />
        </Flex>

        <Box py={{ initial: "3", md: "5" }} px={{ initial: "5", md: "0" }}>
          {final_audio?.url && <AudioPlayer src={final_audio?.url} showTime />}
        </Box>
      </Grid>
    </Box>
  );
};

import { createFileRoute, redirect } from "@tanstack/react-router";

import { type Session, getSession } from "@/lib/session";

export const Route = createFileRoute("/_authenticated")({
  beforeLoad: async ({ location }) => {
    const session = getSession();

    if (!session) {
      throw redirect({
        to: "/",
        search: {
          // Use the current location to power a redirect after login
          // (Do not use `router.state.resolvedLocation` as it can
          // potentially lag behind the actual current location)
          // After login, call `router.history.push(search.redirect)`
          // to redirect the user to their original path
          // After login, call `router.history.push(search.redirect)`
          redirect: location.href,
        },
      });
    }

    if (session.account_type === "licensee") {
      licenseeOnboarding(session, location.pathname);
    }
  },
});

const licenseeOnboarding = (session: Session, pathname: string): void => {
  const onboardingPaths = ["/onboarding/profile", "/onboarding/billing"];
  if (onboardingPaths.includes(pathname)) return;

  if (!session.user_profile_present) {
    throw redirect({
      to: "/onboarding/profile",
    });
  }

  if (!session.billing_present && !session.trial) {
    throw redirect({
      to: "/onboarding/billing",
      search: {
        redirect_status: "pending",
      },
    });
  }
};

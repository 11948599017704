import { useCreateScriptLine } from "@/api/scriptLines";
import { Box, Grid } from "@radix-ui/themes";
import { useCallback, useEffect, useRef, useState } from "react";
import { useEditable } from "use-editable";

import { sanitizeText } from "@/util/sanitize";

type Props = {
  commercialId: number;
  scriptId: number;
  narratorId: number;
  newOrder: number;
  onSave: () => void;
};

export const NewScriptLine = ({
  commercialId,
  scriptId,
  narratorId,
  onSave,
  newOrder,
}: Props) => {
  const [text, setText] = useState("");
  const editableRef = useRef<HTMLDivElement>(null);
  const hasFocused = useRef(false);

  const onChange = useCallback((text: string) => {
    setText(sanitizeText(text));
  }, []);

  useEditable(editableRef, onChange);

  const scriptLineCreate = useCreateScriptLine(commercialId, scriptId);

  const scriptLineBlur = async () => {
    if (!text || text === "\n") {
      onSave(); // No text, cancel it.
      return;
    }

    await scriptLineCreate.mutateAsync({
      frontend_guid: crypto.randomUUID(),
      text,
      order: newOrder,
      type: "NarratorScriptLine",
      narrator_id: narratorId,
    });

    onSave();
  };

  useEffect(() => {
    if (
      !editableRef.current ||
      hasFocused.current ||
      scriptLineCreate.isPending
    )
      return;
    hasFocused.current = true;

    setTimeout(() => {
      if (!editableRef.current) return;
      editableRef?.current.focus();
    }, 100);
  });

  return (
    <Grid columns="35% 1fr" gap="0" className="DividedRow">
      <Box px="5" py="5" position="relative">
        <div ref={editableRef} onBlur={scriptLineBlur} tabIndex={-1}>
          {text}
        </div>
      </Box>
    </Grid>
  );
};

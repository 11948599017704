import * as Sentry from "@sentry/react";

export const sentryInit = () => {
  if (process.env.NODE_ENV !== "development") {
    Sentry.init({
      environment: process.env.NODE_ENV,
      debug: false,
      dsn: "https://64e11a3d9b05843c399be2dec1d96797@o4505676195954688.ingest.us.sentry.io/4506989374406656",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "app.adtwin.ai",
        /^https:\/\/oyi-rails.onrender\.com\/api/,
      ],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
};

export const sentryCapture = (error: unknown) => {
  if (process.env.NODE_ENV !== "development") {
    Sentry.captureException(error);
  }
};

import { useSignUp } from "@/api/auth";
import {
  Box,
  Card,
  Checkbox,
  Flex,
  Heading,
  Link as RadixLink,
  Text,
} from "@radix-ui/themes";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { Link } from "@tanstack/react-router";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import Turnstile from "react-turnstile";
import z from "zod";

import { Input } from "@/components/Field/Input";
import { Submit } from "@/components/Field/Submit";
import { Form } from "@/components/Form";
import { AuthLayout } from "@/components/Layouts/AuthLayout";

import { isAuthenticated } from "@/lib/session";

import { enableTurnstile } from "@/util/environment";

const signupSearchSchema = z.object({
  betaguest: z.boolean().optional(),
  julypromo: z.boolean().optional(),
});

export const Route = createFileRoute("/signup")({
  component: SignUpScreen,
  validateSearch: signupSearchSchema,
  beforeLoad: () => {
    if (isAuthenticated()) {
      throw redirect({
        to: "/commercials",
      });
    }
  },
});

function SignUpScreen() {
  const { betaguest = false, julypromo = false } = Route.useSearch();
  const signUp = useSignUp(betaguest || julypromo);

  const [tosAgree, setTosAgree] = useState<boolean>(false);

  return (
    <AuthLayout>
      <Card size="4">
        <Heading mb="4" as="h1" size="7">
          Sign Up
        </Heading>

        <Form
          action={signUp.mutateAsync}
          redirectUrl="/commercials"
          submitText="Sign Up"
          schema="signInWithEmail"
          isSuccess={signUp.isSuccess}
          isPending={signUp.isPending}
          isDisabled={!tosAgree}
        >
          <SignUpForm />

          <Text as="label" size="1" mt="2" mb="3">
            <Flex gap="2">
              <Checkbox onClick={() => setTosAgree(!tosAgree)} />
              <Box>
                I agree to the{" "}
                <RadixLink
                  href="https://adtwin.ai/terms-of-service"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service
                </RadixLink>{" "}
                and{" "}
                <RadixLink
                  href="https://adtwin.ai/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </RadixLink>
              </Box>
            </Flex>
          </Text>

          <Submit
            text="Sign Up"
            disabled={!tosAgree || signUp.isPending}
            loading={signUp.isPending}
          />
        </Form>
      </Card>

      <Flex mt="5" justify="center">
        <RadixLink size="2" asChild>
          <Link to="/">Already have an account? Sign in!</Link>
        </RadixLink>
      </Flex>
    </AuthLayout>
  );
}

const SignUpForm = () => {
  const { setValue } = useFormContext();
  const showTurnstile = enableTurnstile();

  return (
    <>
      <Input
        label="Email"
        type="email"
        name="email"
        placeholder="bucket@oyi.ai"
      />
      <Input
        label="Password"
        type="password"
        name="password"
        placeholder="********"
      />

      {showTurnstile && (
        <Turnstile
          sitekey={String(import.meta.env.VITE_CLOUDFLARE_TURNSTILE_SITE_KEY)}
          onVerify={(token: string) => {
            setValue("turnstile_token", token);
          }}
        />
      )}
    </>
  );
};

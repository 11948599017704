import { userConfirmation } from "@/api/userConfirmations";
import { Button, Container, Text } from "@radix-ui/themes";
import { createFileRoute, redirect, useNavigate } from "@tanstack/react-router";
import toast from "react-hot-toast";
import { z } from "zod";

import { ContentCard } from "@/components/ContentCard";

import { isAuthenticated } from "@/lib/session";

const confirmationSchema = z.object({
  confirmation_token: z.string(),
});

export const Route = createFileRoute("/users/confirmation")({
  component: ConfirmationScreen,
  validateSearch: (search) => {
    try {
      return confirmationSchema.parse(search);
    } catch (e) {
      throw redirect({
        to: "/",
      });
    }
  },
  beforeLoad: () => {
    if (isAuthenticated()) {
      throw redirect({
        to: "/commercials",
      });
    }
  },
  loaderDeps: ({ search: { confirmation_token } }) => ({
    confirmation_token,
  }),
  loader: ({ deps: { confirmation_token } }) =>
    userConfirmation({ confirmation_token }),
  onError: () => {
    toast.error("Your confirmation token is invalid.");
    throw redirect({
      to: "/",
    });
  },
});

function ConfirmationScreen() {
  const navigate = useNavigate();

  return (
    <Container size="1">
      <ContentCard>
        <ContentCard.Header>
          <ContentCard.Heading>Success!</ContentCard.Heading>
        </ContentCard.Header>
        <Text as="p">Your account is confirmed!</Text>
        <Button mt="6" onClick={() => navigate({ to: "/" })}>
          Sign In
        </Button>
      </ContentCard>
    </Container>
  );
}

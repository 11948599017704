import * as yup from "yup";

type IndexableSchema = {
  [index: string]: yup.ObjectSchema<yup.AnyObject>;
};

const email = yup
  .string()
  .email("Must be a valid email.")
  .required("Email is required.");

const password = yup.string().required("Password is required.");

const signInWithEmailSchema = yup.object({
  email,
  password,
  turnstile_token: yup.string().required("Are you sure you're human? 🤖"),
});

const signUpSchema = yup
  .object({
    email,
    password,
    account_type: yup.string().required("Account type is required."),
    turnstile_token: yup.string().required("Are you sure you're human? 🤖"),
  })
  .required();

const requestAccessWithEmailSchema = yup
  .object({
    email: yup
      .string()
      .email("Must be a valid email.")
      .required("Email is required."),
  })
  .required();

const changePasswordSchema = yup
  .object({
    current_password: password,
    new_password: password,
  })
  .required();

const onboardingProfileSchema = yup
  .object({
    first_name: yup.string().required("First Name is required."),
    last_name: yup.string().required("Last Name is required."),
    name: yup.string().required("Company name is required."),
    title: yup.string().required("Job title is required."),
  })
  .required();

const companySchema = yup
  .object({
    name: yup.string().required("Company Name is required."),
    address: yup.string(),
    city: yup.string(),
    state: yup.string().uppercase(),
    zip: yup.string().length(5, "Zip must be 5 digits"),
    industry: yup.string().required("Industry is required."),
    image: yup.string().optional(),
    title: yup.string().required("Job title is required."),
  })
  .required();

const artistSchema = yup
  .object({
    name: yup.string().required("Your name is required."),
    bio: yup.string().required("Your bio is required."),
    avatar: yup.string().optional(),
  })
  .required();

const createCampaignSchema = yup.object({
  name: yup.string().required("Campaign name is required."),
  tagline: yup
    .string()
    .required("Please include a brief tagline of the campaign proposal."),

  start_date: yup.date().required("Start date is required."),
  end_date: yup.date().required("End date is required."),
  budget: yup.number().required("Budget is required."),
  budget_interval: yup.string().required("Budget interval is required."),

  company: yup.number().required(),
  artist: yup.number().required(),
  regions: yup.array(yup.string()).required(),
  usage_rights: yup.array(yup.string()).required(),
  duration: yup.string().required(),
  exclusivity: yup.string().required(),
});

const createCommercialStep1 = yup
  .object({
    company_name: yup.string().required("Company name is required."),
    company_description: yup
      .string()
      .required("Company description is required."),
  })
  .required();

const createCommercialStep2 = yup
  .object({
    product_name: yup.string().required("Product name is required."),
    product_description: yup.string(),
  })
  .required();

const createCommercialStep3 = yup
  .object({
    narrator_id: yup.number().required("Narrator is required."),
  })
  .required();

const createCommercialStep4 = yup
  .object({
    background_music_id: yup.number().optional(),
  })
  .required();

const createCommercialStep5 = yup
  .object({
    name: yup.string().required(),
    length: yup.number().required(),
  })
  .required();

const createScript = yup
  .object({
    prompt: yup.string().required("Commercial prompt is required."),
    length: yup.string().required("Length is required."),
  })
  .required();

const campaignCreateStepName = yup
  .object({
    campaign_name: yup.string().required("Campaign name is required."),
  })
  .required();

const campaignCreateStepCommercial = yup
  .object({
    campaign_commercial: yup.string().required("Commercial name is required."),
  })
  .required();

export const schemas: IndexableSchema = {
  signInWithEmail: signInWithEmailSchema,
  signUp: signUpSchema,
  requestAccessWithEmail: requestAccessWithEmailSchema,
  changePassword: changePasswordSchema,
  onboardingProfile: onboardingProfileSchema,
  artist: artistSchema,
  company: companySchema,
  createCampaign: createCampaignSchema,
  createCommercialStep1,
  createCommercialStep2,
  createCommercialStep3,
  createCommercialStep4,
  createCommercialStep5,
  createScript,
  campaignCreateStepName,
  campaignCreateStepCommercial,
};

import { useSignIn } from "@/api/auth";
import { Box, Card, Flex, Heading, Link as RadixLink } from "@radix-ui/themes";
import { Link, createFileRoute, redirect } from "@tanstack/react-router";
import { useFormContext } from "react-hook-form";
import Turnstile from "react-turnstile";

import { Field } from "@/components/Field";
import { Submit } from "@/components/Field/Submit";
import { Form } from "@/components/Form";
import { AuthLayout } from "@/components/Layouts/AuthLayout";

import { isAuthenticated } from "@/lib/session";

import { enableTurnstile } from "@/util/environment";

export const Route = createFileRoute("/")({
  component: WelcomeScreen,
  beforeLoad: () => {
    if (isAuthenticated()) {
      throw redirect({
        to: "/commercials",
      });
    }
  },
});

function WelcomeScreen() {
  const signIn = useSignIn();

  return (
    <AuthLayout>
      <Card size="4">
        <Heading mb="4" as="h1" size="7">
          Sign In
        </Heading>

        <Form
          action={signIn.mutateAsync}
          redirectUrl="/commercials"
          submitText="Sign In"
          schema="signInWithEmail"
          isSuccess={signIn.isSuccess}
          isPending={signIn.isPending}
        >
          <FormInner />

          <Box mt="2">
            <Submit
              text={signIn.isPending ? "Signing in..." : "Sign in"}
              loading={signIn.isPending}
              disabled={signIn.isPending}
              testId="signInButton"
            />
          </Box>
        </Form>
      </Card>

      <Flex mt="5" justify="center">
        <RadixLink size="2" asChild>
          <Link to="/signup">Need an account? Sign up</Link>
        </RadixLink>
      </Flex>
    </AuthLayout>
  );
}

const FormInner = () => {
  const { setValue } = useFormContext();
  const showTurnstile = enableTurnstile();

  return (
    <>
      <Field
        label="Email"
        type="email"
        name="email"
        placeholder="bucket@oyi.ai"
      />
      <Field
        label="Password"
        type="password"
        name="password"
        placeholder="********"
      />

      {showTurnstile && (
        <Box mb="2">
          <Turnstile
            sitekey={String(import.meta.env.VITE_CLOUDFLARE_TURNSTILE_SITE_KEY)}
            onVerify={(token: string) => {
              setValue("turnstile_token", token);
              document.body.classList.add("turnstile");
            }}
          />
        </Box>
      )}
    </>
  );
};

import { useNarrators } from "@/api/narrators";
import { useScriptLine, useUpdateScriptLine } from "@/api/scriptLines";
import {
  useCreateSpeech,
  useSelectSpeech,
  useUpdateSpeech,
} from "@/api/speeches";
import { LayersIcon, MagicWandIcon, PlusIcon } from "@radix-ui/react-icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  IconButton,
  RadioGroup,
  Text,
} from "@radix-ui/themes";
import classnames from "classnames";
import { useEffect, useRef, useState } from "react";
import ContentEditable, { ContentEditableEvent } from "react-contenteditable";

import { FEATURE_FLAGS } from "@/lib/featureFlags";

import { sanitizeText } from "@/util/sanitize";

import { NewScriptLine } from "./NewScriptLine";
import { ScriptLineDropdown } from "./ScriptLineDropdown";
import { ScriptLineNarratorDropdown } from "./ScriptLineNarratorDropdown";
import { SpeechPlayer } from "./SpeechPlayer";
import "./scriptLine.scss";

type ScriptLineType = {
  commercialId: number;
  scriptId: number;
  scriptLineFrontendGuid: string;
  speechLibraryName: string;
};

export const ScriptLine = ({
  commercialId,
  scriptId,
  scriptLineFrontendGuid,
  speechLibraryName,
}: ScriptLineType) => {
  const [layersVisible, setLayersVisible] = useState(false);
  const [newScriptLine, setNewScriptLine] = useState<"before" | "after">();

  const speechSelect = useSelectSpeech(
    commercialId,
    scriptId,
    scriptLineFrontendGuid,
  );
  const speechUpdate = useUpdateSpeech(
    commercialId,
    scriptId,
    scriptLineFrontendGuid,
  );
  const createSpeech = useCreateSpeech(
    commercialId,
    scriptId,
    scriptLineFrontendGuid,
  );

  const { data: narratorData } = useNarrators({
    speech_library_name: speechLibraryName,
  });

  const {
    isLoading: dataIsLoading,
    data,
    refetch,
  } = useScriptLine(commercialId, scriptId, scriptLineFrontendGuid);

  const scriptLineUpdate = useUpdateScriptLine(
    commercialId,
    scriptId,
    scriptLineFrontendGuid,
  );

  const selectedSpeech = data?.speeches?.find((speech) => {
    return speech.selected;
  });

  const text = useRef(data?.text);

  // Instantiate the ref value when data.text changes.
  useEffect(() => {
    text.current = data?.text;
  }, [data?.text]);

  if (!data || !data.narrator || !narratorData) return;

  const scriptLineBlur = async () => {
    // This is fine 🔥
    if (!data || !data.narrator) return;
    if (!text.current) return;
    if (text.current === data.text) return;
    if (!selectedSpeech) return;

    // Ensure we have the latest version of the script line so narrators are
    // consistent.
    //
    // Invalidation appears to not be happening, but this does more or less
    // the same thing.
    const { data: scriptLine } = await refetch();
    if (!scriptLine || !scriptLine.narrator) return;

    await scriptLineUpdate.mutateAsync({
      frontend_guid: scriptLineFrontendGuid,
      text: String(text.current),
      order: scriptLine.order,
      type: scriptLine.type,
      narrator_id: scriptLine.narrator.id,
    });
  };

  const scriptLineChange = (e: ContentEditableEvent) => {
    text.current = sanitizeText(e.target.value);
  };

  const handleOnNewScriptSave = () => {
    setNewScriptLine(undefined);
  };

  const onCreateSpeech = async () => {
    if (!data.narrator) return;

    await createSpeech.mutateAsync({
      text: data.text,
      quality: "medium",
    });
  };

  const onSelectSpeech = async (value: string) => {
    if (!selectedSpeech || !data.speeches) return;

    const newSelected = data.speeches.find((speech) => {
      return speech.id === Number(value);
    });

    if (!newSelected) return;

    await speechSelect.mutateAsync({
      selectedId: Number(value),
      deselectedId: selectedSpeech.id,
    });
  };

  const isDisabled = dataIsLoading || speechUpdate.isPending;

  if (data.type === "SfxScriptLine" && !FEATURE_FLAGS.soundEffects.enabled)
    return null;

  return (
    <>
      {newScriptLine === "before" && (
        <NewScriptLine
          scriptId={scriptId}
          commercialId={commercialId}
          narratorId={data.narrator.id}
          newOrder={data.order}
          onSave={handleOnNewScriptSave}
        />
      )}

      <Grid
        columns={{ initial: "1fr", md: "35% 1fr" }}
        gap="0"
        className="DividedRow"
      >
        <Box px="5" py={{ initial: "3", md: "5" }} position="relative">
          {FEATURE_FLAGS.scriptNarrators.enabled && (
            <Heading as="h5" size="5" mb="3">
              {data.type === "CelebScriptLine" && data.celeb && data.celeb.name}
              {data.type === "NarratorScriptLine" && (
                <>
                  <ScriptLineNarratorDropdown
                    commercialId={commercialId}
                    scriptId={scriptId}
                    scriptLineFrontendGuid={scriptLineFrontendGuid}
                    speechLibraryName={speechLibraryName}
                    order={data.order}
                    scriptData={data}
                  />
                </>
              )}
            </Heading>
          )}

          <Text color="gray">
            <Flex gap="3" align="center">
              {data.type === "SfxScriptLine" ? (
                <>
                  <MagicWandIcon />
                  <em>
                    <ContentEditable
                      disabled={isDisabled}
                      onBlur={scriptLineBlur}
                      onChange={scriptLineChange}
                      html={text.current ?? data.text}
                    />
                  </em>
                </>
              ) : (
                <ContentEditable
                  disabled={isDisabled}
                  onBlur={scriptLineBlur}
                  onChange={scriptLineChange}
                  html={text.current ?? data.text}
                />
              )}
            </Flex>
          </Text>

          {selectedSpeech && (
            <ScriptLineDropdown
              setNewScriptLine={setNewScriptLine}
              speech={selectedSpeech}
              commercialId={commercialId}
              scriptId={scriptId}
              scriptLineFrontendGuid={scriptLineFrontendGuid}
            />
          )}
        </Box>

        <Box px="5" py={{ initial: "3", md: "5" }}>
          {data.speeches && data.speeches.length > 0 && (
            <>
              <Grid columns="30px 1fr" gap="3" width="auto">
                <Flex width="4" align="center">
                  <IconButton
                    data-testid="button-script-line-takes"
                    variant="ghost"
                    radius="full"
                    size="3"
                    onClick={() => setLayersVisible((state: boolean) => !state)}
                    className={
                      layersVisible
                        ? "LayersButton LayersButton--selected"
                        : "LayersButton"
                    }
                  >
                    <LayersIcon />
                  </IconButton>
                </Flex>

                <Box>
                  {selectedSpeech?.id && (
                    <SpeechPlayer
                      commercialId={commercialId}
                      scriptId={scriptId}
                      scriptLineFrontendGuid={scriptLineFrontendGuid}
                      speechId={selectedSpeech.id}
                      timestamp={data.timestamp}
                      isLoading={scriptLineUpdate.isPending}
                    />
                  )}
                </Box>
              </Grid>

              {layersVisible && (
                <Box className="SpeechSelector" mt="2" pr="9">
                  <RadioGroup.Root
                    defaultValue={String(selectedSpeech?.id)}
                    onValueChange={onSelectSpeech}
                    disabled={speechSelect.isPending}
                  >
                    {data.speeches.map((speech) => {
                      const classes = classnames("SpeechSelector__row", {
                        "SpeechSelector__row--selected":
                          speech.id === selectedSpeech?.id,
                      });

                      return (
                        <Flex
                          key={speech.id}
                          py="2"
                          align="center"
                          className={classes}
                        >
                          <RadioGroup.Item value={String(speech.id)} />

                          <Box flexGrow="1" ml="1" pl="6">
                            <SpeechPlayer
                              commercialId={commercialId}
                              scriptId={scriptId}
                              scriptLineFrontendGuid={scriptLineFrontendGuid}
                              speechId={speech.id}
                              timestamp={data.timestamp}
                              isDeleteable={
                                data.speeches.length > 1 &&
                                speech.id !== selectedSpeech?.id
                              }
                              isLoading={scriptLineUpdate.isPending}
                              variant="compact"
                              showDelete
                            />
                          </Box>
                        </Flex>
                      );
                    })}
                  </RadioGroup.Root>

                  <Box className="SpeechSelector__create" mt="2">
                    <Button
                      radius="full"
                      variant="soft"
                      size="1"
                      color="gray"
                      onClick={onCreateSpeech}
                      disabled={createSpeech.isPending}
                    >
                      <PlusIcon />
                      Generate New Take
                    </Button>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      </Grid>

      {newScriptLine === "after" && (
        <NewScriptLine
          scriptId={scriptId}
          commercialId={commercialId}
          narratorId={data.narrator.id}
          onSave={handleOnNewScriptSave}
          newOrder={data.order + 1}
        />
      )}
    </>
  );
};

import { Theme } from "@radix-ui/themes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "@tanstack/react-router";
import React, { ReactNode, useEffect, useRef } from "react";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import { Toaster } from "react-hot-toast";
import { SkeletonTheme } from "react-loading-skeleton";

import { IntercomClient } from "@/components/IntercomClient";

import { useCheckNetwork } from "./hooks/network";
import { useRootRef } from "./hooks/rootRef";
import "./index.scss";
import { analyticsInit } from "./lib/analytics";
import { router } from "./router";
import { sentryInit } from "./util/sentry";

sentryInit();
analyticsInit();

const tagManagerArgs = {
  gtmId: "GTM-T4CKL9Q4",
};

TagManager.initialize(tagManagerArgs);

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const queryClient = new QueryClient();

type RootProps = {
  children: ReactNode;
};

export const Root = ({ children }: RootProps) => {
  useCheckNetwork();

  const rootRef = useRef(null);
  const updateRootRef = useRootRef((state) => state.updateRef);

  useEffect(() => {
    if (!rootRef.current) return;

    updateRootRef(rootRef.current);
  }, [updateRootRef]);

  return <div ref={rootRef}>{children}</div>;
};

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Theme appearance="dark" panelBackground="solid" radius="small">
        <Root>
          <SkeletonTheme baseColor="#343639" highlightColor="#4c4d4e">
            <Toaster
              position="top-right"
              toastOptions={{
                style: {
                  fontSize: "14px",
                },
              }}
            />
            <RouterProvider router={router} />
            <IntercomClient />
          </SkeletonTheme>
        </Root>
      </Theme>
    </QueryClientProvider>
  </React.StrictMode>,
);

import { getStripeProducts } from "@/api/stripeProducts";
import { createSubscription } from "@/api/stripeSubscriptions";
import { useState } from "react";
import toast from "react-hot-toast";

import { setOnboardingField } from "@/lib/session";

const subscribe = async () => {
  const products = await getStripeProducts();
  const stripeProducts = products.stripe_products;

  // Get the last product price from the last product
  const lastProduct = stripeProducts[stripeProducts.length - 1];
  const productPrices = lastProduct.product_prices;
  const lastProductPriceId = productPrices[productPrices.length - 1].id;

  return await createSubscription({
    product_price_id: lastProductPriceId,
  });
};

export const useProcessPayment = (redirect_status: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isRedirect, setIsRedirect] = useState<boolean>(false);

  const isProcessing = (): boolean => {
    return !["pending", "requires_payment_method"].includes(redirect_status);
  };

  const process = async (): Promise<void> => {
    if (isLoading) return;

    setIsLoading(true);
    switch (redirect_status) {
      case "processing":
        toast.success(
          "Processing payment details. We'll update you when processing is complete.",
        );
        break;

      case "requires_payment_method":
        toast.error(
          "Failed to process payment details. Please try another payment method.",
        );
        break;
    }

    if (isProcessing()) {
      try {
        await subscribe();
        toast.success("Success! Your payment method has been saved.");
        setOnboardingField("billing_present", true);
        setIsSuccess(true);
        setIsRedirect(true);
      } catch (e) {
        toast.error(
          "Our billing system is currently not operational. Please contact us.",
        );
      }
    }

    setIsLoading(false);
  };

  return {
    isLoading,
    isSuccess,
    isRedirect,
    isProcessing,
    process,
  };
};

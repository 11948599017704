import { useCommercials } from "@/api/commercials";
import { Box, Button, Card, Flex, Heading } from "@radix-ui/themes";
import { Link, createFileRoute } from "@tanstack/react-router";

import { CommercialRow } from "@/components/CommercialRow";
import { CommercialRowSkeleton } from "@/components/CommercialRow/CommercialRowSkeleton";

export const Route = createFileRoute("/_authenticated/commercials/")({
  component: CommercialsIndex,
});

function CommercialsIndex() {
  const { data, isLoading } = useCommercials();

  return (
    <Box style={{ zIndex: 2, position: "relative" }} pt="6" pb="9" mb="8">
      <Flex
        mb="5"
        px="5"
        direction={{ initial: "column", md: "row" }}
        gap="3"
        justify="between"
      >
        <Heading as="h1" size="8" data-testid="page-title">
          Commercials
        </Heading>

        <Button
          data-testid="create-commercial-button"
          size="2"
          radius="full"
          asChild
        >
          <Link to="/commercials/new">Create Commercial</Link>
        </Button>
      </Flex>

      {isLoading && (
        <>
          <CommercialRowSkeleton />
          <CommercialRowSkeleton />
          <CommercialRowSkeleton />
        </>
      )}

      {data?.length === 0 && (
        <Card size="5" mx="5">
          <Flex
            align="center"
            justify="center"
            direction="column"
            gap="4"
            py="5"
          >
            <Heading as="h3" size="7" mb="3">
              New around here? Let&apos;s create your first commercial.
            </Heading>

            <Button size="3" radius="full" asChild>
              <Link to="/commercials/new">Get Started</Link>
            </Button>
          </Flex>
        </Card>
      )}

      {data?.map((commercial, index) => (
        <CommercialRow
          commercial={commercial}
          key={`${commercial.id}:${index}`}
          index={index}
        />
      ))}
    </Box>
  );
}

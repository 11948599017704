import { createFileRoute } from "@tanstack/react-router";
import { redirect } from "@tanstack/react-router";

import {
  CampaignCreateStepCommercial,
  CampaignCreateStepName,
  CampaignCreateStepSchedule,
} from "@/components/Forms/CampaignCreateForm";
import { CampaignCreateStepAudience } from "@/components/Forms/CampaignCreateForm/CampaignCreateStepAudience";
import { Wizard, WizardStep } from "@/components/Wizard";

import { FEATURE_FLAGS } from "@/lib/featureFlags";

export const Route = createFileRoute("/_authenticated/campaigns/new")({
  component: CampaignsNew,
  beforeLoad: () => {
    if (!FEATURE_FLAGS.campaigns.enabled) redirect({ to: "/commercials" });
  },
});

function CampaignsNew() {
  return (
    <Wizard>
      <WizardStep title="Let’s create a campaign.">
        <CampaignCreateStepName />
      </WizardStep>

      <WizardStep title="Pick a commercial or create a new one.">
        <CampaignCreateStepCommercial />
      </WizardStep>

      <WizardStep title="Set your date and plan.">
        <CampaignCreateStepSchedule />
      </WizardStep>

      <WizardStep
        title="Fine tune your audience."
        subtitle="We’ve already selected recommended audience options to maximize value."
      >
        <CampaignCreateStepAudience />
      </WizardStep>
    </Wizard>
  );
}

import { useAllCampaigns } from "@/api/campaigns";
import { Button, Card, Container, Flex, Heading } from "@radix-ui/themes";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { Link } from "@tanstack/react-router";

import { CampaignCard } from "@/components/CampaignCard";
import { LoadingCardGrid } from "@/components/LoadingCardGrid";

import { FEATURE_FLAGS } from "@/lib/featureFlags";

export const Route = createFileRoute("/_authenticated/campaigns/")({
  component: CampaignsIndex,
  beforeLoad: () => {
    if (!FEATURE_FLAGS.campaigns.enabled) redirect({ to: "/commercials" });
  },
});

function CampaignsIndex() {
  const { data, isLoading } = useAllCampaigns();

  return (
    <Container>
      <Flex mb="5" justify="between">
        <Heading as="h1" size="8">
          Campaigns
        </Heading>

        <Button size="2" asChild>
          <Link to="/campaigns/new">Create Campaign</Link>
        </Button>
      </Flex>

      {isLoading && <LoadingCardGrid count={3} />}

      {data?.length === 0 && (
        <Card size="5">
          <Flex align="center" justify="center" direction="column" gap="4">
            <Heading as="h4">
              It looks like you don&apos;t have any campaigns yet!
            </Heading>
            <Button size="2" asChild>
              <Link to="/campaigns/new">Create Campaign</Link>
            </Button>
          </Flex>
        </Card>
      )}

      {data?.map((campaign, index) => (
        <CampaignCard campaign={campaign} key={`${campaign.name}:${index}`} />
      ))}
    </Container>
  );
}

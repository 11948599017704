import { Box, Flex } from "@radix-ui/themes";

import { Container } from "@/components/Container";
import { Field } from "@/components/Field";
import { Submit } from "@/components/Field/Submit";
import { Form } from "@/components/Form/Form";

type Props = {
  onSubmit?: () => Promise<unknown>;
  active?: boolean;
};

export const CampaignCreateStepCommercial = ({ onSubmit, active }: Props) => {
  if (!onSubmit) return null;

  return (
    <Container size="2">
      <Form
        action={onSubmit}
        isSuccess
        isPending={false}
        schema="campaignCreateStepCommercial"
        isDisabled={!active}
      >
        <Flex gap="5" direction="column" mb="4">
          <Box>
            <Field
              data-testid="form-commercial-company-description"
              name="campaign_commercial"
              label="Commercial Name"
            />
          </Box>

          <Submit text="Continue" />
        </Flex>
      </Form>
    </Container>
  );
};

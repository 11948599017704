import {
  type CommercialResponse,
  useDeleteCommercial,
} from "@/api/commercials";
import { DotsVerticalIcon } from "@radix-ui/react-icons";
import {
  AlertDialog,
  Box,
  Button,
  DropdownMenu,
  Flex,
  IconButton,
} from "@radix-ui/themes";

import { downloadFile } from "@/util/download";

type Props = {
  commercial: CommercialResponse;
};

export const CommercialRowDropdown = ({ commercial }: Props) => {
  const deleteScriptLine = useDeleteCommercial(commercial.id);

  const handleDeleteCommercial = async () => {
    await deleteScriptLine.mutateAsync();
  };

  return (
    <>
      <AlertDialog.Root>
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <IconButton
              size="1"
              variant="soft"
              color="gray"
              radius="none"
              className="Script__aside__add-button"
              data-testid="commercial-dropdown-menu"
            >
              <DotsVerticalIcon />
            </IconButton>
          </DropdownMenu.Trigger>

          <DropdownMenu.Content
            onCloseAutoFocus={(e) => {
              e.preventDefault();
            }}
          >
            <DropdownMenu.Item disabled={!commercial?.final_audio?.url} asChild>
              <Box onClick={() => downloadFile(commercial?.final_audio?.url)}>
                Download
              </Box>
            </DropdownMenu.Item>
            <DropdownMenu.Separator />
            <AlertDialog.Trigger>
              <DropdownMenu.Item color="red">
                Delete commercial
              </DropdownMenu.Item>
            </AlertDialog.Trigger>
          </DropdownMenu.Content>
        </DropdownMenu.Root>

        <AlertDialog.Content>
          <AlertDialog.Title>Delete Commercial</AlertDialog.Title>
          <AlertDialog.Description>
            Are you sure you to delete this commercial? This cannot be undone.
          </AlertDialog.Description>

          <Flex gap="3" mt="4" justify="end">
            <AlertDialog.Cancel>
              <Button variant="soft" color="gray">
                Cancel
              </Button>
            </AlertDialog.Cancel>

            <AlertDialog.Action>
              <Button
                variant="solid"
                color="red"
                onClick={handleDeleteCommercial}
                data-testid="commercial-alert-delete-button"
              >
                Delete commercial
              </Button>
            </AlertDialog.Action>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
    </>
  );
};

import { type DirectUploadDelegate } from "@rails/activestorage";
import { format } from "date-fns";
import Cookies from "js-cookie";

import { type Session } from "./session";

const DirectUpload = async (
  file: File,
  url: string,
  delegate: DirectUploadDelegate,
  customHeaders: Record<string, string>,
) => {
  if (typeof window === "undefined") {
    return;
  }

  try {
    const activestorage = await import("@rails/activestorage");
    return new activestorage.DirectUpload(file, url, delegate, customHeaders);
  } catch (e) {
    console.log(`ACTIVE STORAGE ERROR: ${e}`);
  }
};

export type ActiveStorageCallbackType = ActiveStorage.Blob & {
  error?: Error;
  url: string;
};

export type CustomEventData = {
  fileName: string;
  fileType: string;
  progress: number;
  fileSize: string;
  duration: string;
  localId?: string;
};

export const getAudioDuration = async (file: File): Promise<string> => {
  const audioFileTypes = ["audio/wav", "audio/mpeg"];
  if (!audioFileTypes.includes(file.type)) return "n/a";

  const objectUrl = URL.createObjectURL(file);
  const audio = new Audio(objectUrl);

  return new Promise((resolve, reject) => {
    try {
      const audioHandler = () => {
        const length = new Date(0);
        length.setSeconds(audio.duration);

        audio.removeEventListener("loadedmetadata", audioHandler);

        if (length.getSeconds() > 0) return resolve(format(length, "m:ss"));
        if (length.getMinutes() > 0) return resolve(format(length, "m:ss"));
        if (length.getHours() > 0) return resolve(format(length, "H:mm:ss"));
      };

      audio.addEventListener("loadedmetadata", audioHandler);
    } catch (e) {
      reject(e);
    }
  });
};

const getMetadata = async (event: ProgressEvent, file: File) => {
  const progress = Math.floor((event.loaded / event.total) * 100);
  const fileSize = (file.size / (1024 * 1024)).toFixed(2) + "mb";
  const audioDuration = await getAudioDuration(file);

  return {
    fileName: file.name,
    fileType: file.type,
    progress,
    fileSize,
    duration: audioDuration ?? "n/a",
  };
};

const getSession = async (): Promise<Session | undefined> => {
  const data = Cookies.get("adtwin_session");

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (data) resolve(JSON.parse(String(data)));
      if (!data) reject("Cookie not found.");
    }, 100);
  });
};

export const upload = async (
  file: File,
  callback: (blob: ActiveStorageCallbackType) => Promise<void>,
  localId?: string,
): Promise<void> => {
  const session = await getSession();

  // Upload progress handler
  const handler: DirectUploadDelegate = {
    directUploadWillStoreFileWithXHR: (request: XMLHttpRequest) => {
      const progressHandler = async function(event: ProgressEvent) {
        const metadata = await getMetadata(event, file);

        if (metadata.progress === 100) {
          request.upload.removeEventListener("progress", progressHandler);
        }

        window.dispatchEvent(
          new CustomEvent<CustomEventData>("upload-progress", {
            detail: {
              ...metadata,
              ...(localId ? { localId } : {}),
            },
          }),
        );
      };

      request.upload.addEventListener("progress", progressHandler);
    },
  };

  const fileUpload = await DirectUpload(
    file,
    String(import.meta.env.VITE_API_URL) + "/api/direct_uploads",
    handler,
    {
      Authorization: `${session?.token}`,
    },
  );

  if (!fileUpload) {
    return;
  }

  fileUpload.create(
    async (error: Error, blob: ActiveStorage.Blob): Promise<void> => {
      if (error) {
        console.error(error);
        await callback({
          ...blob,
          url: "",
          error,
        });
        return;
      }

      await callback({
        ...blob,
        url:
          (import.meta.env.VITE_API_URL as string) +
          `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`,
      });
    },
  );
};

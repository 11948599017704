/**
 * Double Ended Queue
 */
export class Deque<T> {
  private items: T[] = [];

  /**
   * Enqueue an item to the beginning of the queue.
   * @param item The item to be enqueued.
   */
  enqueueFirst(item: T): void {
    this.items.unshift(item);
  }

  /**
   * Enqueue an item to the end of the queue.
   * @param item The item to be enqueued.
   */
  enqueueLast(item: T): void {
    this.items.push(item);
  }

  /**
   * Dequeue the first item of the queue.
   * @returns The dequeued item or undefined if the queue is empty.
   */
  dequeueFirst(): T | undefined {
    return this.items.shift();
  }

  /**
   * Dequeue the last item of the queue.
   * @returns The dequeued item or undefined if the queue is empty.
   */
  dequeueLast(): T | undefined {
    return this.items.pop();
  }

  /**
   * Get the first item in the queue without removing it.
   * @returns The first item in the queue or undefined if the queue is empty.
   */
  peekFirst(): T | undefined {
    return this.items[0];
  }

  /**
   * Get the last item in the queue without removing it.
   * @returns The last item in the queue or undefined if the queue is empty.
   */
  peekLast(): T | undefined {
    return this.items[this.items.length - 1];
  }

  /**
   * Get the length of the queue.
   * @returns The number of items in the queue.
   */
  get length(): number {
    return this.items.length;
  }

  /**
   * Check if the queue is empty.
   * @returns True if the queue is empty, false otherwise.
   */
  isEmpty(): boolean {
    return this.items.length === 0;
  }

  /**
   * Clear the queue by removing all items.
   */
  clear(): void {
    this.items = [];
  }
}

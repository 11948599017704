import { Container, Flex } from "@radix-ui/themes";
import React from "react";

import { Footer } from "@/components/Footer";

type Props = {
  children: React.ReactNode;
};

export const AuthLayout = ({ children }: Props) => {
  return (
    <Flex
      direction="column"
      justify="between"
      style={{ minHeight: "calc(100vh - 64px)" }}
    >
      <Flex flexGrow="1" align="center">
        <Container size="1">{children}</Container>
      </Flex>

      <Footer />
    </Flex>
  );
};

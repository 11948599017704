import { useQuery } from "@tanstack/react-query";

import { axiosInstance } from "@/lib/api";

export type BackgroundMusic = {
  id: number;
  name: string;
  genre: string;
  audio_file: {
    url: string;
  };
  updated_at: string;
  created_at: string;
};

export const getBackgroundMusic = async (): Promise<BackgroundMusic[]> => {
  const { data } = await axiosInstance.get("/api/background_musics");
  return data;
};

export const useBackgroundMusic = () => {
  return useQuery({
    queryKey: ["background-music"],
    queryFn: getBackgroundMusic,
    refetchOnWindowFocus: false,
  });
};

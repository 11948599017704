import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { axiosInstance } from "@/lib/api";

export const scriptLineCreateSchema = z.object({
  frontend_guid: z.string(),
  text: z.string(),
  order: z.number(),
  type: z.enum(["NarratorScriptLine", "SfxScriptLine", "CelebScriptLine"]),
  narrator_id: z.number(),
});

export const scriptLineUpdateSchema = z.object({
  text: z.string(),
});

export type ScriptLineCreate = z.infer<typeof scriptLineCreateSchema>;
export type ScriptLineUpdate = z.infer<typeof scriptLineUpdateSchema>;

export type Speech = {
  id: number;
  type: string;
  text: string;
  voice: string;
  model: string;
  quality: string;
  aasm_state: string;
  selected: boolean;
  script_line_id: number;
  audio_file?: {
    url: string;
  };
};

type NarratorOrCelebType = {
  id: number;
  name: string;
  display_name: string;
  user_id: number;
  bio: string;
  slug: string;
  open_ai_voice: string;
  speech_library_name: string;
  created_at: string;
  updated_at: string;
};

export type ScriptLineTypes =
  | "NarratorScriptLine"
  | "SfxScriptLine"
  | "CelebScriptLine";

export type ScriptLineResponse = {
  id: number;
  frontend_guid: string;
  order: number;
  type: ScriptLineTypes;
  text: string;
  timestamp: string;
  narrator?: NarratorOrCelebType;
  celeb?: NarratorOrCelebType;
  speeches: Speech[];
};

const getScriptLines = async (
  commercialId: number,
  scriptId: number,
): Promise<ScriptLineResponse> => {
  const { data } = await axiosInstance.get<ScriptLineResponse>(
    `/api/commercials/${commercialId}/scripts/${scriptId}/script_lines`,
  );

  if (!data) throw new Error("Script not ready.");

  return data;
};

const getScriptLine = async (
  commercialId: number,
  scriptId: number,
  scriptLineFrontendGuid: string,
): Promise<ScriptLineResponse> => {
  const { data } = await axiosInstance.get<ScriptLineResponse>(
    `/api/commercials/${commercialId}/scripts/${scriptId}/script_lines/${scriptLineFrontendGuid}`,
  );

  if (!data) throw new Error("Script not ready.");

  return data;
};

const createScriptLine = async (
  commercialId: number,
  scriptId: number,
  params: ScriptLineCreate,
): Promise<ScriptLineResponse> => {
  const payload = scriptLineCreateSchema.parse(params);
  const { data } = await axiosInstance.post<ScriptLineResponse>(
    `/api/commercials/${commercialId}/scripts/${scriptId}/script_lines`,
    {
      script_line: payload,
    },
  );

  return data;
};

const deleteScriptLine = async (
  commercialId: number,
  scriptId: number,
  scriptLineFrontendGuid: string,
): Promise<void> => {
  await axiosInstance.delete(
    `/api/commercials/${commercialId}/scripts/${scriptId}/script_lines/${scriptLineFrontendGuid}`,
  );
};

export const useScriptLines = (
  commercialId: number,
  scriptId: number,
  opts?: object,
) => {
  return useQuery({
    queryKey: ["script-lines", scriptId],
    queryFn: async () => getScriptLines(commercialId, scriptId),
    retryDelay: 5000,
    retry: true,
    refetchOnWindowFocus: false,
    staleTime: 2 * 60 * 1000, // 2 Minutes
    ...opts,
  });
};

// Note - We may want to have retry polling be optional depending on the context.
export const useScriptLine = (
  commercialId: number,
  scriptId: number,
  scriptLineFrontendGuid: string,
  opts?: object,
) => {
  return useQuery({
    queryKey: ["script-lines", scriptLineFrontendGuid],
    queryFn: async () =>
      getScriptLine(commercialId, scriptId, scriptLineFrontendGuid),
    retryDelay: 5000,
    retry: true,
    refetchOnWindowFocus: false,
    staleTime: 2 * 60 * 1000, // 2 Minutes
    ...opts,
  });
};

export const useCreateScriptLine = (commercialId: number, scriptId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (
      params: ScriptLineCreate,
    ): Promise<ScriptLineResponse> => {
      return await createScriptLine(commercialId, scriptId, params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["scripts", scriptId],
      });
      queryClient.invalidateQueries({
        queryKey: ["commercial-scripts", commercialId],
      });
    },
  });
};

export const useUpdateScriptLine = (
  commercialId: number,
  scriptId: number,
  scriptLineFrontendGuid: string,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: ScriptLineCreate): Promise<void> => {
      await deleteScriptLine(commercialId, scriptId, scriptLineFrontendGuid);
      await createScriptLine(commercialId, scriptId, params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["script-lines", scriptLineFrontendGuid],
      });
      queryClient.invalidateQueries({
        queryKey: ["commercial-scripts", commercialId],
      });
    },
  });
};

export const useDeleteScriptLine = (
  commercialId: number,
  scriptId: number,
  scriptLineFrontendGuid: string,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (): Promise<void> => {
      return await deleteScriptLine(
        commercialId,
        scriptId,
        scriptLineFrontendGuid,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["scripts", scriptId],
      });

      // This is due to useAllScripts being based on the commercialId
      queryClient.invalidateQueries({
        queryKey: ["commercial-scripts", commercialId],
      });
    },
  });
};

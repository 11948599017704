import { useSignOut } from "@/api/auth";
import { useUserCompany } from "@/api/companies";
import { useStripeBillingPortal } from "@/api/stripeBillingPortal";
import { useStripeCustomer } from "@/api/stripeCustomers";
import logo from "@/assets/adtwin-light.svg";
import { useSession } from "@/hooks/session";
import {
  CardStackIcon,
  ChatBubbleIcon,
  GearIcon,
  LockOpen1Icon,
  PersonIcon,
} from "@radix-ui/react-icons";
import { Avatar, Box, Button, DropdownMenu, Flex } from "@radix-ui/themes";
import { Link, useMatchRoute, useNavigate } from "@tanstack/react-router";

import { FEATURE_FLAGS } from "@/lib/featureFlags";

import "./navigation.scss";

export const Navigation = () => {
  const { session } = useSession();

  return (
    <Box
      px="5"
      py="3"
      style={{
        position: "relative",
        zIndex: 3,
      }}
    >
      <Flex direction="row" justify="between" align="center">
        <Link to="/" style={{ display: "flex" }}>
          <img src={logo} height="22" width="120" alt="Oyi Logo" />
        </Link>

        {session ? (
          <>
            <Pages />
            <UserDropdown userId={session.id} />
          </>
        ) : (
          <Flex gap="4">
            <Button radius="full" size="3" color="gray" asChild highContrast>
              <Link to="/">Sign in</Link>
            </Button>

            <Button radius="full" size="3" asChild>
              <Link to="/signup">Sign up</Link>
            </Button>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

const Pages = () => {
  const matchRoute = useMatchRoute();

  const isOnboarding =
    matchRoute({ to: "/onboarding/profile", fuzzy: true }) ||
    matchRoute({ to: "/onboarding/billing", fuzzy: true });

  if (isOnboarding) return null;

  return (
    <Flex direction="row" align="center" gap="3">
      {FEATURE_FLAGS.campaigns.enabled && (
        <Button
          variant={
            matchRoute({ to: "/campaigns", fuzzy: true }) ? "solid" : "outline"
          }
          color="gray"
          size="2"
          radius="full"
          className="NavItem"
          asChild
        >
          <Link to="/campaigns">Campaigns</Link>
        </Button>
      )}

      <Button
        variant={
          matchRoute({ to: "/commercials", fuzzy: true }) ? "solid" : "outline"
        }
        color="gray"
        size="2"
        radius="full"
        className="NavItem"
        asChild
      >
        <Link to="/commercials">Commercials</Link>
      </Button>
    </Flex>
  );
};

const UserDropdown = ({ userId }: { userId: number }) => {
  const navigate = useNavigate();
  const signOut = useSignOut();
  const company = useUserCompany(userId);
  const customer = useStripeCustomer();

  const onSignOut = async () => {
    await signOut.mutateAsync();
    navigate({ to: "/" });
  };

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <Box role="button" tabIndex={0}>
          <Avatar
            style={{ border: "1px solid #333" }}
            size="4"
            radius="full"
            src={String(import.meta.env.VITE_API_URL) + company.data?.image}
            fallback={
              <Flex justify="center" align="center">
                <PersonIcon width="24" height="24" />
              </Flex>
            }
          />
        </Box>
      </DropdownMenu.Trigger>

      <DropdownMenu.Content size="2" style={{ minWidth: "10rem" }}>
        <DropdownMenu.Item role="link" tabIndex={0} asChild>
          <Link to="/settings">
            <Flex align="center" gap="2">
              <GearIcon width="16" />
              Settings
            </Flex>
          </Link>
        </DropdownMenu.Item>

        {customer.isSuccess && <BillingPortalItem />}

        <DropdownMenu.Item role="link" tabIndex={0} id="intercom-trigger">
          <Flex align="center" gap="2">
            <ChatBubbleIcon width="16" /> Chat with us
          </Flex>
        </DropdownMenu.Item>

        <DropdownMenu.Item onClick={onSignOut} role="link" tabIndex={0}>
          <Flex align="center" gap="2">
            <LockOpen1Icon width="16" /> Sign out
          </Flex>
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

const BillingPortalItem = () => {
  const portal = useStripeBillingPortal();

  const handleClick = () => {
    if (portal.isSuccess) {
      window.open(portal.data.url, "_blank");
    }
  };

  return (
    <DropdownMenu.Item
      onClick={handleClick}
      role="link"
      tabIndex={0}
      disabled={!portal.isSuccess}
    >
      <Flex align="center" gap="2">
        <CardStackIcon width="16" />
        Billing
      </Flex>
    </DropdownMenu.Item>
  );
};

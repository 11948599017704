import { useQuery } from "@tanstack/react-query";

import { axiosInstance } from "@/lib/api";

type StripeProductPriceTiers = {
  unit_amount: number;
  up_to: string | number;
}

type StripeProductPrice = {
  id: number;
  product_price_id: string;
  nickname: string;
  recurring_interval: string;
  currency: string;
  tiers?: StripeProductPriceTiers[];
  created_at: string;
  updated_at: string;
}

export type StripeProduct = {
  id: number;
  product_id: string;
  name: string;
  active: boolean;
  created_at: string;
  updated_at: string;
  product_prices: StripeProductPrice[];
}

export type StripeProductsResponse = {
  stripe_products: StripeProduct[];
};

export const getStripeProducts = async (): Promise<StripeProductsResponse> => {
  const { data } = await axiosInstance.get("/api/stripe_products");
  return data;
};

export const useGetStripeProducts = () => {
  return useQuery({
    queryKey: ["stripe-products"],
    queryFn: getStripeProducts,
  });
};

import { AxiosError } from "axios";

type ErrorJson = Error[] | { [key: string]: string[] };

type ErrorData = {
  error_description?: string;
  error?: Error;
  errors?: ErrorJson;
};

const parseErrors = (errors: ErrorJson): string => {
  if (Array.isArray(errors)) {
    return errors.join(", ");
  }

  return Object.keys(errors)
    .map((key) => {
      return `${key} ${errors[key].join(", ")}`;
    })
    .join(", ");
};

export const getError = (error: AxiosError): string => {
  if (!error.response) return error.message;

  const data = error.response.data as ErrorData;

  if (data.error_description) {
    return data.error_description;
  }

  if (data.error) {
    return data.error.message;
  }

  if (data.errors) {
    return parseErrors(data.errors);
  }

  console.error(
    "This is likely a Rails error that is not JSON. Check the logs.",
  );
  return "Server Error. Please try again in a few moments.";
};

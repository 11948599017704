import { Box, Flex, Heading, RadioCards, Text } from "@radix-ui/themes";

import { Container } from "@/components/Container";
import { RadioCard } from "@/components/Field/RadioCard";
import { Submit } from "@/components/Field/Submit";
import { Form } from "@/components/Form/Form";

type Props = {
  onSubmit?: () => Promise<unknown>;
  active?: boolean;
};

const LOCATION_OPTIONS = [
  {
    title: "Hyper Local",
    description: "Target customers within a mile radius of your location.",
  },
  {
    title: "Local",
    description: "Target customers within a 20 mile radius of your location.",
  },
  {
    title: "Regional",
    description: "Target customers within a 50 mile radius of your location.",
  },
  {
    title: "Greater Region",
    description: "Target customers within a 150 mile radius of your location.",
  },
];

export const CampaignCreateStepAudience = ({ onSubmit, active }: Props) => {
  if (!onSubmit) return null;

  return (
    <Form
      action={onSubmit}
      isSuccess
      isPending={false}
      // schema="campaignCreateStepCommercial"
      isDisabled={!active}
    >
      <Flex gap="5" direction="column" mb="4">
        <Box>
          <Container size="2">
            <Heading size="7" mb="3">
              Location
            </Heading>
          </Container>

          <RadioCard name="location">
            {LOCATION_OPTIONS.map((option) => (
              <RadioCards.Item value={option.title} key={option.title}>
                <Flex direction="column" width="100%">
                  <Text weight="bold" size="4" mb="2">
                    {option.title}
                  </Text>
                  <Text size="2">{option.description}</Text>
                </Flex>
              </RadioCards.Item>
            ))}
          </RadioCard>
        </Box>
        <Container size="2">
          <Submit text="Continue" />
        </Container>
      </Flex>
    </Form>
  );
};

import { addDays, getTime, isAfter } from "date-fns";
import Cookies from "js-cookie";

import { isProduction } from "@/util/environment";

export type Session = {
  token: string;
  jti: string;
  email: string;
  id: number;
  is_internal: boolean;
  user_profile_present: boolean;
  company_present: boolean;
  people_present: boolean;
  billing_present?: boolean;
  account_type: "licensor" | "licensee";
  expiration_date?: number;
  trial: boolean;
};

type OnboardingFields =
  | "user_profile_present"
  | "company_present"
  | "people_present"
  | "billing_present"
  | "trial";

const SESSION_EXPIRATION_IN_DAYS = 15;
const COOKIE_NAME = "adtwin_session";

export const setSession = (data: Session): void => {
  const session = {
    ...data,
    expiration_date: getTime(addDays(Date.now(), SESSION_EXPIRATION_IN_DAYS)),
  };

  Cookies.set(COOKIE_NAME, JSON.stringify(session), {
    secure: isProduction(),
    expires: session.expiration_date,
  });
};

export const setOnboardingField = (
  field: OnboardingFields,
  value: boolean = true,
): void => {
  const session = getSession();
  if (!session) throw new Error("User is not authenticated.");

  session[field] = value;

  Cookies.set(COOKIE_NAME, JSON.stringify(session), {
    secure: isProduction(),
    expires: session.expiration_date,
  });
};

export const deleteSession = (): void => {
  Cookies.remove(COOKIE_NAME);
};

export const getSession = (): Session | undefined => {
  const sessionCookie = Cookies.get(COOKIE_NAME);
  if (!sessionCookie) return;

  const session: Session = JSON.parse(String(sessionCookie));

  // Check if session is active
  const isActive = isAfter(Number(session.expiration_date), Date.now());
  if (!isActive) return;

  return session;
};

export const getAnalyticsSession = (): Partial<Session> | undefined => {
  const session = getSession();
  if (!session) return;

  return {
    id: session.id,
    email: session.email,
    is_internal: session.is_internal,
    account_type: session.account_type,
    billing_present: session.billing_present,
    company_present: session.company_present,
    user_profile_present: session.user_profile_present,
    people_present: session.people_present,
  };
};

export const getUserId = (): number => {
  const session = getSession();

  if (!session || !session?.id) {
    throw new Error("User ID not associated with session.");
  }

  return session.id;
};

export const isAuthenticated = (): boolean => {
  const session = getSession();
  return Boolean(session);
};

import Intercom from "@intercom/messenger-js-sdk";

export const IntercomClient = () => {
  Intercom({
    app_id: "sgdhsjzs",
    custom_launcher_selector: "#intercom-trigger",
    hide_default_launcher: true,
  });

  return null;
};

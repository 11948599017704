import { createFileRoute } from "@tanstack/react-router";

import { CommercialCreateForm } from "@/components/Forms/CommercialCreateForm/CommercialCreateForm";

export const Route = createFileRoute("/_authenticated/commercials/new")({
  component: CommercialsNew,
});

function CommercialsNew() {
  return <CommercialCreateForm />;
}

import { useCommercial } from "@/api/commercials";
import { useCommercialScripts } from "@/api/scripts";
import { DownloadIcon, ReloadIcon, Share2Icon } from "@radix-ui/react-icons";
import {
  Badge,
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Text,
} from "@radix-ui/themes";
import { Link, createFileRoute } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { CombinedAudioPlayer } from "@/components/AudioPlayer/CombinedAudioPlayer";
import { ScriptLine } from "@/components/CommercialEditor";
import { CommercialLayout } from "@/components/Layouts/CommercialLayout/CommercialLayout";

import { subscribe } from "@/lib/pusher";

import { downloadFile } from "@/util/download";

export const Route = createFileRoute(
  "/_authenticated/commercials/$commercialId/",
)({
  component: CommercialsEdit,
});

function CommercialsEdit() {
  const { commercialId } = Route.useParams();
  const {
    data,
    refetch: refetchCommercial,
    isLoading,
  } = useCommercial(Number(commercialId));
  const {
    data: scriptsData,
    isLoading: isScriptsLoading,
    refetch: refetchScriptData,
  } = useCommercialScripts(Number(commercialId));

  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isCombining, setIsCombining] = useState<boolean>(false);

  const hasScripts = !isLoading && scriptsData && scriptsData.length > 0;
  const hasScriptLines = hasScripts && scriptsData[0]?.script_lines.length > 0;
  const scriptId = hasScripts ? scriptsData[0].id : null;

  useEffect(() => {
    if (!scriptId) return;

    const subscription = subscribe(
      `script_${scriptId}`,
      "script_status_change",
      async (data) => {
        switch (data.new_state) {
          case "speeches_generated":
            await refetchScriptData();
            break;
          case "combining":
            await refetchScriptData();
            setIsCombining(true);
            break;
          case "combined":
            setIsCombining(false);
            break;
          default:
            break;
        }
      },
    );

    return () => {
      const isPending =
        !subscription.subscribed && subscription.subscriptionPending;
      if (isPending) return;
      subscription.unsubscribe();
    };
  }, [scriptId, refetchScriptData]);

  const saveAndDownload = async () => {
    setIsDownloading(true);

    const { data: updatedData } = await refetchCommercial();
    if (!updatedData || !updatedData.final_audio) {
      setIsDownloading(false);
      toast.error("Your commercial cannot be downloaded. Please wait.");
    }

    await downloadFile(updatedData?.final_audio.url);
    setIsDownloading(false);
  };

  return (
    <>
      <CommercialLayout>
        <Box style={{ zIndex: 2, position: "relative" }} pt="6" pb="9" mb="8">
          {!data && isLoading ? (
            <Loading />
          ) : (
            <Box>
              <Box className="Script__aside" px="5">
                <Flex justify="between" align="end">
                  <Box>
                    <Badge mb="1">Commercial</Badge>

                    <Heading as="h1" size="8" data-testid="commercial-title">
                      {data?.name}
                    </Heading>
                  </Box>
                  <Flex direction="row" gap="3">
                    <Button
                      color="gray"
                      variant="surface"
                      radius="full"
                      asChild
                    >
                      <Link
                        to="/s/$commercialId"
                        params={{ commercialId }}
                        target="_blank"
                      >
                        Share
                        <Share2Icon />
                      </Link>
                    </Button>
                    <Button
                      color="gray"
                      loading={isDownloading}
                      disabled={isDownloading || isCombining || !hasScriptLines}
                      onClick={saveAndDownload}
                      radius="full"
                    >
                      {isCombining ? (
                        <>
                          Download
                          <ReloadIcon className="animate-spin" />
                        </>
                      ) : (
                        <>
                          Download
                          <DownloadIcon />
                        </>
                      )}
                    </Button>
                  </Flex>
                </Flex>

                <Box style={{ width: "35%" }} pr="6">
                  {!isScriptsLoading && (
                    <>
                      {hasScripts && !hasScriptLines && (
                        <Card size="4" mt="4">
                          <Flex align="center" justify="center" gap="3">
                            <ReloadIcon className="animate-spin" /> Generating
                            Script...
                          </Flex>
                        </Card>
                      )}
                    </>
                  )}
                </Box>
              </Box>

              <Box mt="5" position="relative">
                {isScriptsLoading && <Loading />}
                {scriptsData &&
                  data &&
                  scriptsData[0]?.script_lines.map((scriptLine) => (
                    <ScriptLine
                      key={scriptLine.frontend_guid}
                      commercialId={Number(commercialId)}
                      scriptId={scriptsData[0].id}
                      scriptLineFrontendGuid={scriptLine.frontend_guid}
                      speechLibraryName={data.speech_library_name}
                    />
                  ))}
              </Box>
            </Box>
          )}
        </Box>
      </CommercialLayout>

      {scriptsData && scriptsData[0]?.aasm_state !== "fresh" && (
        <CombinedAudioPlayer
          commercialId={commercialId}
          scriptId={String(scriptsData[0].id)}
        />
      )}
    </>
  );
}

function Loading() {
  return (
    <Text size="5">
      <Flex
        align="center"
        justify="center"
        position="absolute"
        width="100%"
        gap="3"
      >
        <ReloadIcon className="animate-spin" /> Loading
      </Flex>
    </Text>
  );
}

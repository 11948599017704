import { useNarrators } from "@/api/narrators";
import {
  type ScriptLineResponse,
  useUpdateScriptLine,
} from "@/api/scriptLines";
import { CaretDownIcon } from "@radix-ui/react-icons";
import { Button, DropdownMenu, Text } from "@radix-ui/themes";
import capitalize from "lodash/capitalize";

type Props = {
  commercialId: number;
  scriptId: number;
  scriptLineFrontendGuid: string;
  speechLibraryName: string;
  order: number;
  scriptData: ScriptLineResponse;
};

export const ScriptLineNarratorDropdown = ({
  commercialId,
  scriptId,
  scriptLineFrontendGuid,
  speechLibraryName,
  order,
  scriptData,
}: Props) => {
  const { data: narratorData } = useNarrators({
    speech_library_name: speechLibraryName,
  });

  const scriptLineUpdate = useUpdateScriptLine(
    commercialId,
    scriptId,
    scriptLineFrontendGuid,
  );

  const onNarratorChange = async (value: string) => {
    await scriptLineUpdate.mutateAsync({
      frontend_guid: scriptLineFrontendGuid,
      text: scriptData.text,
      order,
      type: scriptData.type,
      narrator_id: Number(value),
    });
  };

  return (
    <>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <Button variant="ghost" color="gray">
            <Text size="4" weight="bold" color="gray" highContrast>
              {capitalize(scriptData.narrator?.display_name)} <CaretDownIcon />
            </Text>
          </Button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Content
          onCloseAutoFocus={(e) => {
            e.preventDefault();
          }}
        >
          {narratorData?.map((narrator) => (
            <DropdownMenu.Item
              key={narrator.id}
              onClick={() => onNarratorChange(narrator.id.toString())}
            >
              {narrator.name}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </>
  );
};

import { ReloadIcon } from "@radix-ui/react-icons";
import { Box, Card, Flex, IconButton, Text } from "@radix-ui/themes";

type Props = {
  variant?: "compact";
};

export const AudioPlayerSkeleton = ({ variant }: Props) => {
  const size = variant === "compact" ? 12 : 18;

  return (
    <Card className="AudioPlayer">
      <Flex gap="3" align="center">
        <IconButton
          radius="full"
          disabled
          size={variant === "compact" ? "1" : "4"}
        >
          <ReloadIcon className="animate-spin" width={size} height={size} />
        </IconButton>

        <Box flexGrow="1">
          <Text size={variant === "compact" ? "2" : "3"} color="gray">
            Generating Audio...
          </Text>
        </Box>
      </Flex>
    </Card>
  );
};

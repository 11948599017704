import { Box, Flex, Grid, Heading, Skeleton, Text } from "@radix-ui/themes";

import { AudioPlayerSkeleton } from "@/components/AudioPlayer/AudioPlayerSkeleton";

export const CommercialRowSkeleton = () => (
  <Box className="DividedRow" px="5">
    <Grid columns="35% 1fr" gap="5" align="start">
      <Flex py="5" pr="5" direction="column" className="DividedRow__main">
        <Box>
          <Skeleton>
            <Heading as="h3" size="7">
              Lorem ipsum dolor sit
            </Heading>
          </Skeleton>
        </Box>

        <Text>
          <Skeleton>Lorem ipsum dolor sit</Skeleton>
        </Text>
      </Flex>

      <Box py="5">
        <AudioPlayerSkeleton />
      </Box>
    </Grid>
  </Box>
);

import { Flex, RadioGroup, Text } from "@radix-ui/themes";
import { useFormContext } from "react-hook-form";

import { Label } from "./Label";

export type RadioOption = {
  label: string;
  value: string;
};

type Props = {
  name: string;
  options: Array<RadioOption>;
  id?: string;
};

export const Radio = ({ name, options, id, ...rest }: Props) => {
  const { register } = useFormContext();

  return (
    <RadioGroup.Root>
      <Flex gap="2" direction="column">
        {options.map(({ value, label }, index) => (
          <Text key={`formRadio:${name}:${label}`} as="label" size="2">
            <Flex gap="2">
              <RadioGroup.Item
                {...register(name)}
                id={`${index}:${id ?? name}`}
                value={value}
                {...rest}
              />
              <Label text={`${index}:${id ?? name}`} htmlFor={name} />
            </Flex>
          </Text>
        ))}
      </Flex>
    </RadioGroup.Root>
  );
};

import { useBackgroundMusic } from "@/api/backgroundMusic";
import { ScriptLineResponse } from "@/api/scriptLines";
import { useCommercialScripts, useUpdateScript } from "@/api/scripts";
import { SpeechResponse, getSpeech } from "@/api/speeches";
import { SourceFilesType, useFFmpeg } from "@/hooks/ffmpeg";
import { Box, Flex, Select, Text } from "@radix-ui/themes";
import { useQueries } from "@tanstack/react-query";
import isEqual from "lodash/isEqual";
import { useEffect, useMemo, useState } from "react";

import { AudioPlayer } from "@/components/AudioPlayer";

import { AudioPlayerSkeleton } from "./AudioPlayerSkeleton";

type Props = {
  commercialId: string;
  scriptId: string;
};

const DEFAULT_AUDIO_FILES: SpeechQuery[] = [];
const DEFAULT_SCRIPT_LINES: ScriptLineResponse[] = [];

type SpeechQuery = {
  commercialId: number;
  scriptId: number;
  scriptLineFrontendGuid: string;
  id: number;
};

export const CombinedAudioPlayer = ({ commercialId, scriptId }: Props) => {
  const ffmpeg = useFFmpeg();
  const bgMusic = useBackgroundMusic();
  const { data: scriptsData } = useCommercialScripts(Number(commercialId));
  const updateScripts = useUpdateScript(Number(commercialId), Number(scriptId));
  const [bgMusicId, setBgMusicId] = useState<number | undefined>();

  const hasScripts = scriptsData && scriptsData.length > 0;
  const scriptLines = hasScripts
    ? scriptsData[0].script_lines
    : DEFAULT_SCRIPT_LINES;

  const commercialLength = hasScripts ? scriptsData[0].length : 0;

  const queryArgs = useMemo(() => {
    if (!hasScripts) return DEFAULT_AUDIO_FILES;

    const _queryArgs: SpeechQuery[] = scriptLines.reduce(
      (accum, scriptLine): SpeechQuery[] => {
        // Ignore non-narrator lines for now.
        if (scriptLine.type !== "NarratorScriptLine") return accum;

        // Pick out only the "selected: true" speeches.
        const speech = scriptLine.speeches.find((speech) => speech.selected);
        if (!speech) return accum;

        return [
          ...accum,
          {
            commercialId: Number(commercialId),
            scriptId: Number(scriptId),
            scriptLineFrontendGuid: scriptLine.frontend_guid,
            id: speech.id,
          } as SpeechQuery,
        ];
      },
      [] as SpeechQuery[],
    );

    return _queryArgs;
  }, [commercialId, hasScripts, scriptLines, scriptId]);

  const queries = useMemo(
    () =>
      queryArgs.map(
        ({ commercialId, scriptId, scriptLineFrontendGuid, id }) => ({
          queryKey: ["speeches", id],
          queryFn: async () => {
            const data = await getSpeech(
              commercialId,
              scriptId,
              scriptLineFrontendGuid,
              id,
            );
            return data;
          },
          refetchOnWindowFocus: false,
          staleTime: 30 * 60 * 1000, // 30 Minutes
        }),
      ),
    [queryArgs],
  );

  // Do we need to memoize this? Every response is a new object.
  const results = useQueries({ queries: queries });

  const getSource = (data: SpeechResponse): string | undefined => {
    if (data?.voice_conversions) {
      return data.voice_conversions[data.voice_conversions.length - 1]
        ?.audio_file?.url;
    }

    return data?.audio_file?.url;
  };

  const audioFiles = results.reduce((accum, file) => {
    if (!file.data) return accum;
    return [
      ...accum,
      {
        id: file.data.id,
        url: getSource(file.data),
        panning: "center",
        volume: "0dB",
      } as SourceFilesType,
    ];
  }, [] as SourceFilesType[]);

  const hasAllUrls =
    audioFiles.every(({ url }) => url) &&
    queryArgs.length === audioFiles.length;

  useEffect(() => {
    if (!scriptsData) return;

    if (
      !ffmpeg.backgroundAudio ||
      ffmpeg.backgroundAudio?.id !== scriptsData[0].background_music?.id
    ) {
      ffmpeg.addCommercialLength(commercialLength);

      if (scriptsData[0].background_music) {
        ffmpeg.addBackgroundAudio({
          id: scriptsData[0].background_music.id,
          url: scriptsData[0].background_music.audio_file.url,
          volume: "0dB",
          panning: "left",
        });
      }
    }
  }, [ffmpeg, commercialLength, scriptsData]);

  useEffect(() => {
    const audioFileIds = audioFiles.map((file) => file.id);
    const sourceFilesProcessedIds = ffmpeg.sourceFilesProcessed.map(
      (file) => file.id,
    );

    if (
      ffmpeg.isReady &&
      hasAllUrls &&
      ffmpeg.backgroundAudio &&
      ffmpeg.backgroundAudio.url &&
      (!isEqual(audioFileIds, sourceFilesProcessedIds) ||
        ffmpeg.backgroundAudio.id !== bgMusicId)
    ) {
      setBgMusicId(ffmpeg.backgroundAudio.id);
      ffmpeg.combine(audioFiles);
    }
  }, [audioFiles, hasAllUrls, commercialLength, ffmpeg, bgMusicId]);

  return (
    <Box>
      <>
        {bgMusic.isSuccess && scriptsData && (
          <Box className="MixTools">
            <Flex direction="row" mt="4" ml="6" gap="3">
              <Box mt="1">
                <Text size="2">Background Music</Text>
              </Box>
              <Select.Root
                size="2"
                onValueChange={async (id) => {
                  await updateScripts.mutateAsync({
                    background_music_id: Number(id),
                  });
                }}
                defaultValue={
                  scriptsData[0].background_music
                    ? String(scriptsData[0].background_music?.id)
                    : String(bgMusic.data[0].id)
                }
              >
                <Select.Trigger />
                <Select.Content>
                  <Select.Group>
                    {bgMusic.data.map((music) => (
                      <Select.Item key={music.id} value={String(music.id)}>
                        {music.name}
                      </Select.Item>
                    ))}
                  </Select.Group>
                </Select.Content>
              </Select.Root>
            </Flex>
          </Box>
        )}
      </>

      <Box className="FullAudioPlayer">
        <>
          {ffmpeg.isLoading && <AudioPlayerSkeleton />}
          {ffmpeg.isReady && !ffmpeg.isLoading && ffmpeg.combinedAudio && (
            <Box data-testid="combined-audio-player">
              <AudioPlayer
                src={ffmpeg.combinedAudio}
                showVolumeControl
                showTime
              />
            </Box>
          )}
        </>
      </Box>
    </Box>
  );
};

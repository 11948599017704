import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { axiosInstance } from "@/lib/api";

export const stripeSubscriptionSchema = z.object({
  product_price_id: z.number(),
});

export type StripeSubscriptionParams = z.infer<typeof stripeSubscriptionSchema>;

export type StripeSubscription = {
  id: number;
  active: boolean;
  cancel_at: number | null;
  cancel_at_period_end: boolean;
  subscription_id: string;
  created_at: string;
  updated_at: string;
};

export type StripeSubscriptionsResponse = {
  subscriptions: StripeSubscription[];
};

export const getSubscriptions =
  async (): Promise<StripeSubscriptionsResponse> => {
    const { data } = await axiosInstance.get("/api/stripe_subscriptions");
    return data;
  };

export const createSubscription = async (
  params: StripeSubscriptionParams,
): Promise<StripeSubscription> => {
  const payload = stripeSubscriptionSchema.parse(params);
  const { data } = await axiosInstance.post("/api/stripe_subscriptions", {
    stripe_subscription: {
      stripe_product_price: {
        id: payload.product_price_id,
      },
    },
  });

  return data;
};

export const useGetSubscriptions = () => {
  return useQuery({
    queryKey: ["stripe-subscriptions"],
    queryFn: getSubscriptions,
  });
};

export const useCreateSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: StripeSubscriptionParams) =>
      createSubscription(params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["stripe-subscriptions"] });
    },
  });
};

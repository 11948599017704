import { useChangePassword } from "@/api/auth";
import { CaretRightIcon } from "@radix-ui/react-icons";
import { Box, Button, Container, Dialog, Flex, Text } from "@radix-ui/themes";
import { createFileRoute } from "@tanstack/react-router";

import { Field } from "@/components/Field";
import { Form } from "@/components/Form";
import { CompanyForm, ProfileForm } from "@/components/Forms/Settings";

import { getSession } from "@/lib/session";

export const Route = createFileRoute("/_authenticated/settings")({
  component: SettingsShow,
});

function SettingsShow() {
  const changePassword = useChangePassword();
  const session = getSession();

  return (
    <Container>
      <ProfileForm />

      {session && session.account_type === "licensee" && (
        <CompanyForm userId={session.id} />
      )}

      <Box mt="4" p="4">
        <Dialog.Root>
          <Dialog.Trigger>
            <Button variant="ghost" size="4" style={{ width: "100%" }}>
              <Flex direction="row" justify="between" style={{ width: "100%" }}>
                <Text as="p">Change Password</Text>
                <CaretRightIcon height="27" width="27" />
              </Flex>
            </Button>
          </Dialog.Trigger>

          <Dialog.Content style={{ maxWidth: 450 }}>
            <Dialog.Title>Change Password</Dialog.Title>
            <Dialog.Description size="2" mb="4">
              Your password should include a combination of numbers, letters,
              and special characters (!$@%)
            </Dialog.Description>

            <Form
              action={changePassword.mutateAsync}
              isSuccess={changePassword.isSuccess}
              isPending={changePassword.isPending}
              schema="changePassword"
              submitText="Change Password"
              successMessage="Password successfully changed!"
              showButton
            >
              <Field
                type="password"
                name="current_password"
                label="Current Password"
                placeholder="********"
              />
              <Field
                type="password"
                name="new_password"
                label="New Password"
                placeholder="********"
              />
            </Form>
          </Dialog.Content>
        </Dialog.Root>
      </Box>
    </Container>
  );
}

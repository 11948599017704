import { type SourceFilesType } from "@/hooks/ffmpeg";
import { fetchFile } from "@ffmpeg/util";

export const delay = (attempts: number) => {
  return new Promise((resolve) => setTimeout(resolve, 10 ** attempts));
};

export const fetchFileUrl = async (url: string) => {
  if (!url.includes("http")) {
    url = String(import.meta.env.VITE_API_URL) + url;
  }

  return await fetchFile(url);
};

export const getFilename = (url: string): string => {
  return url.split("/").pop() as string;
};

export const getStreams = (files: string[]): string => {
  return files.map((_, index) => `[${index}:a]`).join("");
};

// 70% pan for left/right panning
export const volumePanningFilters = (sourceFile: SourceFilesType): string => {
  const filters = [
    "[0][0]amerge=inputs=2[padded];",
    "[padded]apad=pad_dur=0.25[stereo],",
    `[stereo]volume=${sourceFile.volume}[vol],`,
  ];

  switch (sourceFile.panning) {
    case "center":
      filters.push("[vol]pan=stereo|c0=1*FL+1*FR|c1=1*FR+1*FL[out]");
      break;
    case "left":
      filters.push("[vol]pan=stereo|c0=0.7*FR|c1=0.3*FL[out]");
      break;
    case "right":
      filters.push("[vol]pan=stereo|c0=0.3*FR|c1=0.7*FL[out]");
      break;
  }

  return filters.join("");
};

export const compressAndLimitFilters = (
  files: string[],
  length: number,
): string => {
  if (files.length !== 2) {
    throw new Error("Compress and Limit Error: must include two tracks.");
  }

  return [
    "[0]acompressor=",
    "attack=5:",
    "release=100:",
    "threshold=0.1:",
    "ratio=10:",
    "knee=6:",
    "makeup=10",
    "[compvox];",
    "[compvox]highpass=f=100[highpassvox];",
    "[highpassvox]alimiter=",
    "level_in=1:",
    "level_out=1:",
    "limit=0.5:",
    "attack=5:",
    "release=100:",
    "level=disabled",
    "[limitedvox];",
    "[limitedvox][1]amix=inputs=2:dropout_transition=99999[compressedmix];",
    `[compressedmix]afade=t=in:${length - 1}:`,
    `t=out:${length}:`,
    `st=${length - 1}`,
    "[fade];",
    `[fade]atrim=end=${length}[mix]`,
  ].join("");
};

import { useQuery } from "@tanstack/react-query";

import { axiosInstance } from "@/lib/api";

export type SpeechLibraryResponse = {
  id: number;
  name: string;
  github: string;
  type: "TtsLibrary" | "VtsLibrary";
  created_at: string;
  updated_at: string;
};

const getSpeechLibraries = async (): Promise<SpeechLibraryResponse[]> => {
  const { data } = await axiosInstance.get<SpeechLibraryResponse[]>(
    "/api/speech_libraries",
  );

  return data;
};

export const useSpeechLibraries = () => {
  return useQuery({
    queryKey: ["speech-libraries"],
    queryFn: getSpeechLibraries,
  });
};

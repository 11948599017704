import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";

export const useCheckNetwork = () => {
  const [toastId, setToastId] = useState<string | null>(null);

  const handleNetworkChange = useCallback(() => {
    const isOnline = navigator.onLine;

    if (!isOnline && !toastId) {
      const id = toast.loading(
        "You are offline. Please check your network connection.",
      );
      setToastId(id);
    }

    if (isOnline && toastId) {
      toast.dismiss(toastId);
      setToastId(null);
    }
  }, [toastId]);

  useEffect(() => {
    // Add event listeners
    window.addEventListener("online", handleNetworkChange);
    window.addEventListener("offline", handleNetworkChange);

    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener("online", handleNetworkChange);
      window.removeEventListener("offline", handleNetworkChange);
    };
  }, [handleNetworkChange]);
};

import { Flex } from "@radix-ui/themes";

import { Container } from "@/components/Container";
import { Field } from "@/components/Field";
import { Submit } from "@/components/Field/Submit";
import { Form } from "@/components/Form";

type Props = {
  onSubmit?: () => Promise<unknown>;
  active?: boolean;
};

export const CommercialCreateStepProductInfo = ({
  onSubmit,
  active,
}: Props) => {
  if (!onSubmit) return null;

  return (
    <Container size="2">
      <Form
        action={onSubmit}
        isSuccess={true}
        isPending={false}
        schema="createCommercialStep2"
        isDisabled={!active}
      >
        <Flex gap="5" direction="column" mb="4">
          <Field
            data-testid="commercial-create-product-name"
            name="product_name"
            label="What is the name of your product?"
            placeholder="Ecto-2 Ghost Blaster"
          />

          <Field
            data-testid="commercial-create-product-description"
            type="textarea"
            name="product_description"
            label="Briefly describe your product."
            placeholder="The Ecto-2 is the latest and greatest in ghost hunting technology. With twice the power and trip the accuracy, this new generation of ghost hunting equipment enables the professional to capture two ghosts simultaneously with ease."
            maxCharacters={10000}
            optional={true}
          />

          <Submit
            text="Continue"
            disabled={!active}
            data-testid="commercial-create-product-submit-button"
          />
        </Flex>
      </Form>
    </Container>
  );
};

import { Flex, Select as RadixSelect } from "@radix-ui/themes";
import { useController } from "react-hook-form";

import { InputError } from "./InputError";
import { Label } from "./Label";
import "./select.scss";

export type SelectOption = {
  label: string;
  value: string;
};

export type SelectProps = {
  options: SelectOption[];
  label: string;
  name: string;
  placeholder: string;
  defaultValue?: string | null;
  onValueChange?: ((value: string) => void) | null;
  "data-test-id"?: string;
};

export const Select = ({
  label,
  name,
  options,
  placeholder,
  defaultValue = null,
  onValueChange = null,
  "data-test-id": dataTestId,
  ...rest
}: SelectProps) => {
  const {
    field: { onChange },
    fieldState: { error },
  } = useController({ name, defaultValue });

  const _error = error?.message as string;

  return (
    <Flex direction="column">
      <Label text={label} htmlFor={name} />

      <RadixSelect.Root
        onValueChange={(value) => {
          onChange(value);
          if (onValueChange) {
            onValueChange(value);
          }
        }}
        defaultValue={defaultValue ?? ""}
        size="3"
      >
        <RadixSelect.Trigger
          placeholder={placeholder}
          data-testid={dataTestId}
          {...rest}
        />
        <RadixSelect.Content>
          {options.map((option, index) => (
            <RadixSelect.Item
              key={index}
              value={option.value}
              data-testid={`${dataTestId}-option`}
            >
              {option.label}
            </RadixSelect.Item>
          ))}
        </RadixSelect.Content>
      </RadixSelect.Root>

      <InputError error={_error} />
    </Flex>
  );
};

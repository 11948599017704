import { useSession } from "@/hooks/session";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";

import { axiosInstance } from "@/lib/api";
import { setOnboardingField, setSession } from "@/lib/session";

import { getStripeCustomer } from "../stripeCustomers";
import { getSubscriptions } from "../stripeSubscriptions";

const signInSchema = z.object({
  email: z.string().email(),
  password: z.string(),
  turnstile_token: z.string(),
});

type SignIn = z.infer<typeof signInSchema>;

type SignInUser = {
  id: number;
  email: string;
  jti: string;
  account_type: "licensor" | "licensee";
  user_profile_present: boolean;
  company_present: boolean;
  people_present: boolean;
  trial: boolean;
};

export type SignInResponse = {
  user: SignInUser;
};

export const signInRequest = async (params: SignIn): Promise<void> => {
  const payload = signInSchema.parse(params);
  const { turnstile_token, ...user } = payload;
  const { data, headers } = await axiosInstance.post<SignInResponse>(
    "/users/sign_in",
    {
      user,
      "cf-turnstile-response": turnstile_token,
    },
  );

  // the api should really throw an error code
  // currently just returns { oyi: false }
  if (!data.user) {
    console.error(data);
    throw new Error("Invalid login");
  }

  setSession({
    ...data.user,
    is_internal: data.user.email.includes("@oyi.ai"),
    token: headers["authorization"],
  });

  // Set billing present field after session so we can auth
  try {
    const stripeCustomer = await getStripeCustomer();
    const stripeSubscription = await getSubscriptions();
    const billing_present =
      stripeCustomer.payment_methods.data.length > 0 &&
      stripeSubscription.subscriptions.find((sub) => sub.active) !== null;

    setOnboardingField("billing_present", billing_present);
  } catch (e) {
    // This will fail before the user has a profile
    setOnboardingField("billing_present", false);
  }
};

export const useSignIn = () => {
  const updateSession = useSession((state) => state.updateSession);

  return useMutation({
    mutationFn: signInRequest,
    onSuccess: updateSession,
  });
};

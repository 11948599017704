import { Button, Spinner } from "@radix-ui/themes";

type SubmitProps = {
  text: string;
  disabled?: boolean;
  loading?: boolean;
  testId?: string;
  "data-testid"?: string;
};

export const Submit = ({
  text,
  disabled = false,
  loading = false,
  testId,
  "data-testid": dataTestId,
}: SubmitProps) => {
  return (
    <Button
      size="3"
      style={{ width: "100%", border: "none" }}
      disabled={disabled}
      type="submit"
      data-testid={testId || dataTestId}
    >
      <Spinner loading={loading} />
      {text}
    </Button>
  );
};

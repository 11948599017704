import { type NarratorResponse } from "@/api/narrators";
import { Avatar, Badge, Box, Card, Flex, Grid, Text } from "@radix-ui/themes";
import { useController } from "react-hook-form";

type NarratorSelectOptionParams = {
  id: number;
  avatar: string;
  name: string;
  narrator_name: string;
  description: string;
  tags: string[];
  handleSelect: (arg1: string) => void;
  value: string;
};

type NarratorSelectParams = {
  narrators: NarratorResponse[];
  name: string;
};

const NarratorSelectOption = ({
  id,
  avatar,
  narrator_name,
  description,
  tags,
  handleSelect,
  value,
}: NarratorSelectOptionParams) => {
  if (!avatar.startsWith("http") && !avatar.startsWith("blob")) {
    avatar = String(import.meta.env.VITE_API_URL) + avatar;
  }

  const isSelected = value === String(id);

  const handleKeypress = (event: React.KeyboardEvent) => {
    event.preventDefault();

    const { key } = event;
    const openKeys = ["Enter", " "];
    if (openKeys.includes(key)) handleSelect(String(id));
  };

  return (
    <Box
      onClick={() => handleSelect(String(id))}
      onKeyDown={handleKeypress}
      tabIndex={0}
      role="button"
      data-testid="narrator-tile"
    >
      <Card
        style={{
          border: isSelected ? "1px solid #FFF" : "1px solid transparent",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        <Flex gap="3" align="center">
          <Avatar
            style={{ border: "1px solid #FFF" }}
            size="6"
            src={avatar}
            radius="full"
            fallback="T"
          />

          <Box>
            <Text as="div" size="5" weight="bold">
              {narrator_name}
            </Text>
            <Text as="div" size="2" color="gray">
              {description}
            </Text>

            <Flex direction="row" gap="1" mt="2">
              {tags.length > 0 &&
                tags.map((tag, i) => (
                  <Badge key={i} color="gray" variant="solid">
                    {tag.toUpperCase()}
                  </Badge>
                ))}
            </Flex>
          </Box>
        </Flex>
      </Card>
    </Box>
  );
};

export const NarratorSelect = ({ narrators, name }: NarratorSelectParams) => {
  const {
    field: { value, onChange },
  } = useController({ name });

  const handleSelect = (id: string) => {
    onChange(id);
  };

  return (
    <Grid columns={{ initial: "1fr", md: "1fr 1fr" }} gap="3">
      {narrators.map((narrator) => (
        <NarratorSelectOption
          key={narrator.id}
          id={narrator.id}
          name={name}
          avatar={narrator.avatar}
          narrator_name={narrator.name}
          description={narrator.bio}
          tags={narrator.tags}
          handleSelect={handleSelect}
          value={value}
        />
      ))}
    </Grid>
  );
};

import { router } from "@/router";
import { type RouterEvent } from "@tanstack/react-router";

import { isDevelopment } from "@/util/environment";

import { type Session, getAnalyticsSession } from "./session";
import { getTraceData } from "@/util/trace";

type MetadataValue = Session | string | undefined;
type Metadata = Record<string, MetadataValue>;

export const trackEvent = (name: string, metadata?: Metadata): void => {
  if (typeof window === "undefined" && isDevelopment()) {
    console.warn("analytics events error.");
    return;
  }

  const userSession = getAnalyticsSession();
  const collectedMetadata = {
    ...metadata,
    ...userSession,
  };

  if (isDevelopment()) {
    console.log("Analytics Event: ", name, collectedMetadata);
  }

  window.sa_event(name, collectedMetadata);
};

const eventName = (from: string, href: string): string => {
  const segments = href.split("/").filter(Boolean);

  if (from !== '/' && segments.length === 0) {
    return "sign_out";
  }

  if (segments.length === 0) {
    return "signin_index";
  }

  if (segments.length === 1) {
    return `${segments[0]}_index`;
  }

  if (segments.length === 2 && segments[1] === "new") {
    return `${segments[0]}_new`;
  }

  if (segments.length === 2 && !isNaN(Number(segments[1]))) {
    return `${segments[0]}_edit`;
  }

  return href.slice(1).split("?")[0].replace(/\/\d+/g, "").replace(/\//g, "_");
};

export const analyticsInit = (): void => {
  const ips: string[] = [];
  router.subscribe("onResolved", async (event: RouterEvent) => {
    if (ips.length === 0) {
      const trace = await getTraceData();
      ips.push(trace.ip);
    }

    trackEvent(eventName(event.fromLocation.href, event.toLocation.href), {
      ip: ips[0],
      url: event.toLocation.href,
      search_params: event.toLocation.searchStr,
      referrer: event.fromLocation.href,
    });
  });
};

import { create } from "zustand";

type RootRefState = {
  rootRef: HTMLElement | null;
  updateRef: (arg1: HTMLElement) => void;
};

/*
  This insanity is utilized to pass the upper-most DOM node within 
  the scope of Radix to leaf components like `Calendar.tsx`, 
  enabling them to inherit Radix styling they wouldn't receive 
  when mounted to the body.
*/

export const useRootRef = create<RootRefState>((set) => ({
  rootRef: null,
  updateRef: (ref: HTMLElement) =>
    set(() => ({
      rootRef: ref,
    })),
}));

import { useWizard } from "@/hooks/wizard";
import { Box, Flex, Heading, Text } from "@radix-ui/themes";
import classNames from "classnames";
import { motion, useMotionValue } from "framer-motion";
import { cloneElement, useEffect, useRef } from "react";

import { Container } from "@/components/Container";

import "./WizardStep.scss";

type Props = {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
  onSubmit?: () => void;
  step?: number;
  active?: boolean;
};

export const WizardStep = ({
  children,
  title,
  subtitle,
  step = 0,
  active,
  onSubmit,
}: Props) => {
  const ref = useRef(null);
  const opacity = useMotionValue(0.3);
  const { setRef } = useWizard();

  useEffect(() => {
    if (!ref.current) return;

    setRef(step, ref.current);
  }, [step, ref, setRef]);

  useEffect(() => {
    if (!active) return;
    opacity.set(1);
  }, [active, opacity]);

  const classes = classNames("WizardStep", {
    "WizardStep--active": active,
  });

  return (
    <Box
      my="9"
      ref={ref}
      className={classes}
      data-testid={`wizard-step-${step}`}
    >
      <Box style={{ width: "100%" }}>
        <motion.div style={{ opacity }}>
          <Container size="2" mb="5">
            <Flex
              direction={{ initial: "column", sm: "row" }}
              gap="3"
              ml={{ initial: "0", sm: "-8" }}
            >
              <div className="WizardStep__Count">{step + 1}</div>

              <Box>
                <Heading as="h3" size="7">
                  {title}
                </Heading>
                {subtitle && (
                  <Text size="2" color="gray">
                    {subtitle}
                  </Text>
                )}
              </Box>
            </Flex>
          </Container>

          {cloneElement(children as any, {
            step,
            onSubmit,
            active,
          })}
        </motion.div>
      </Box>
    </Box>
  );
};

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { axiosInstance } from "@/lib/api";

export const campaignSchema = z.object({
  company_id: z.number(),
  name: z.string(),
  narrator_id: z.string(),
});

export const campaignSchemaPartial = campaignSchema.partial();

export type Campaign = z.infer<typeof campaignSchema>;
export type CampaignPartial = z.infer<typeof campaignSchemaPartial>;

type AasmState = "draft" | "active" | "paused" | "completed";

export type CampaignResponse = Campaign & {
  id: number;
  slug: string;
  user_id: number;
  aasm_state: AasmState;
  created_at: string;
  updated_at: string;
};

const getAllCampaigns = async (): Promise<CampaignResponse[]> => {
  const { data } =
    await axiosInstance.get<CampaignResponse[]>("/api/campaigns");

  return data;
};

const getCampaign = async (id: number): Promise<CampaignResponse> => {
  const { data } = await axiosInstance.get<CampaignResponse>(
    `/api/campaigns/${id}`,
  );

  return data;
};

export const createCampaign = async (
  params: Campaign,
): Promise<CampaignResponse> => {
  const payload = campaignSchema.parse(params);
  const { data } = await axiosInstance.post<CampaignResponse>(
    "/api/campaigns",
    {
      campaign: {
        ...payload,
        narrator_id: Number(payload.narrator_id),
      },
    },
  );

  return data;
};

const updateCampaign = async (
  id: number,
  params: CampaignPartial,
): Promise<CampaignResponse> => {
  const payload = campaignSchemaPartial.parse(params);
  const { data } = await axiosInstance.patch<CampaignResponse>(
    `/api/campaigns/${id}`,
    {
      campaign: payload,
    },
  );

  return data;
};

const deleteCampaign = async (id: number): Promise<void> => {
  await axiosInstance.delete(`/api/campaigns/${id}`);
};

const createCampaignCounterOffer = async (
  id: number,
  params: Campaign,
): Promise<CampaignResponse> => {
  const payload = campaignSchema.parse(params);
  const { data } = await axiosInstance.post(
    `/api/campaigns/${id}/counter_offers`,
    {
      campaign: payload,
    },
  );

  return data;
};

export const useAllCampaigns = () => {
  return useQuery({
    queryKey: ["campaigns"],
    queryFn: getAllCampaigns,
  });
};

export const useCampaign = (id: number, args = {}) => {
  return useQuery({
    queryKey: ["campaigns", id],
    queryFn: () => getCampaign(id),
    ...args,
  });
};

export const useCreateCampaign = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: Campaign) => createCampaign(params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["campaigns"] });
    },
  });
};

export const useUpdateCampaign = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: Campaign) => updateCampaign(id, params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["campaigns", id] });
    },
  });
};

export const useDeleteCampaign = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => deleteCampaign(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["campaigns", id] });
    },
  });
};

export const useCreateCampaignCounterOffer = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: Campaign) => createCampaignCounterOffer(id, params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["campaigns", id] });
    },
  });
};

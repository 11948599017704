import { useSpeech } from "@/api/speeches";
import { useDeleteSpeech } from "@/api/speeches";
import { TrashIcon } from "@radix-ui/react-icons";
import { Box, Button, Flex, IconButton } from "@radix-ui/themes";
import { AlertDialog } from "@radix-ui/themes";
import { useEffect } from "react";

import { AudioPlayerSkeleton } from "@/components/AudioPlayer/AudioPlayerSkeleton";

import { subscribe } from "@/lib/pusher";

import { AudioPlayer } from "../AudioPlayer";

type SpeechPlayerType = {
  commercialId: number;
  scriptId: number;
  scriptLineFrontendGuid: string;
  speechId: number;
  timestamp: string;
  isDeleteable?: boolean;
  showDelete?: boolean;
  isLoading?: boolean;
  variant?: "compact";
};

export const SpeechPlayer = ({
  commercialId,
  scriptId,
  scriptLineFrontendGuid,
  speechId,
  isDeleteable,
  showDelete = false,
  isLoading = false,
  variant,
}: SpeechPlayerType) => {
  const { data, isSuccess, refetch } = useSpeech(
    commercialId,
    scriptId,
    scriptLineFrontendGuid,
    speechId,
  );

  const deleteSpeech = useDeleteSpeech(
    commercialId,
    scriptId,
    scriptLineFrontendGuid,
    speechId,
  );

  useEffect(() => {
    const speechChangeSubscription = subscribe(
      `speech_${speechId}`,
      "speech_status_change",
      async (data) => {
        if (data.new_state === "generated") {
          speechChangeSubscription.unsubscribe();
          await refetch();
        }
      },
    );
  }, [speechId, refetch]);

  const onDeleteSpeech = async () => {
    if (isDeleteable) await deleteSpeech.mutateAsync();
  };

  const getSource = (): string | undefined => {
    if (data?.voice_conversions) {
      return data.voice_conversions[data.voice_conversions.length - 1]
        ?.audio_file?.url;
    }

    return data?.audio_file?.url;
  };

  const src = getSource();

  if (!src || isLoading) return <AudioPlayerSkeleton variant={variant} />;
  if (!src) return null;

  const _src = !src.includes("http")
    ? String(import.meta.env.VITE_API_URL) + src
    : src;

  return (
    <>
      {isSuccess && data && data.audio_file && (
        <Flex gap="4" align="center">
          <Box flexGrow="1">
            <AudioPlayer src={_src} variant={variant} />
          </Box>

          {isDeleteable && (
            <AlertDialog.Root>
              <AlertDialog.Trigger>
                <Flex align="center">
                  <IconButton variant="soft" radius="full" color="gray">
                    <TrashIcon />
                  </IconButton>
                </Flex>
              </AlertDialog.Trigger>

              <AlertDialog.Content>
                <AlertDialog.Title>Delete Take</AlertDialog.Title>
                <AlertDialog.Description size="2">
                  Are you sure you want to delete this take? This cannot be
                  undone.
                </AlertDialog.Description>

                <Flex gap="3" mt="4" justify="end">
                  <AlertDialog.Cancel>
                    <Button variant="soft" color="gray">
                      Cancel
                    </Button>
                  </AlertDialog.Cancel>
                  <AlertDialog.Action>
                    <Button
                      variant="solid"
                      color="red"
                      onClick={onDeleteSpeech}
                    >
                      Delete take
                    </Button>
                  </AlertDialog.Action>
                </Flex>
              </AlertDialog.Content>
            </AlertDialog.Root>
          )}

          {!isDeleteable && showDelete && (
            <Flex align="center">
              <IconButton variant="soft" radius="full" color="gray" disabled>
                <TrashIcon />
              </IconButton>
            </Flex>
          )}
        </Flex>
      )}
    </>
  );
};

import { useMutation } from "@tanstack/react-query";
import { z } from "zod";

import { axiosInstance } from "@/lib/api";

import { signInRequest } from "./signIn";

export const signUpSchema = z.object({
  email: z.string().email(),
  password: z.string(),
  account_type: z.union([z.literal("licensee"), z.literal("licensor")]),
  turnstile_token: z.string(),
});

export type SignUp = z.infer<typeof signUpSchema>;

const signUpRequest = async (
  params: SignUp,
  isTrialCustomer: boolean,
): Promise<void> => {
  params.account_type = "licensee";
  const payload = signUpSchema.parse(params);
  const { turnstile_token, ...rest } = payload;
  await axiosInstance.post("/users", {
    user: { ...rest, trial: isTrialCustomer },
    "cf-turnstile-response": turnstile_token,
  });
};

export const useSignUp = (isTrialCustomer: boolean) => {
  return useMutation({
    mutationFn: async (params: SignUp) => {
      await signUpRequest(params, isTrialCustomer);
      await signInRequest(params);
    },
  });
};

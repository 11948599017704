import { type ScriptState } from "@/api/scripts";
import { type SpeechState } from "@/api/speeches";
import Pusher from "pusher-js";
import * as PusherTypes from "pusher-js";

import { isProduction } from "@/util/environment";

type StatusChange<T> = {
  id: number;
  event: string;
  previous_state: T;
  new_state: T;
};

// To create new events
// Create a new type for the event
// and add the event name to the map below
type PusherEventMap = {
  speech_status_change: StatusChange<SpeechState>;
  script_status_change: StatusChange<ScriptState>;
};

type PusherCallback<T> = (arg: T) => void;

export const pusher = new Pusher(String(import.meta.env.VITE_PUSHER_APP_KEY), {
  cluster: String(import.meta.env.VITE_PUSHER_APP_CLUSTER),
});

export const subscribe = <Event extends keyof PusherEventMap>(
  channel: string,
  event: Event,
  callback: PusherCallback<PusherEventMap[Event]>,
): PusherTypes.Channel => {
  return pusher
    .subscribe(channel)
    .unbind(event)
    .bind(event, function (data: PusherEventMap[Event]) {
      callback(data);
    });
};

pusher.connection.bind("error", function (error: unknown) {
  if (!isProduction()) console.error(error);
});

pusher.connection.bind("state_change", function (states: unknown) {
  if (!isProduction()) console.log(states);
});

Pusher.log = (message: unknown) => {
  if (!isProduction()) console.log(message);
};

import { Flex, Checkbox as RadixCheckbox, Text } from "@radix-ui/themes";
import { useFormContext } from "react-hook-form";

import { InputError } from "./InputError";
import { Label } from "./Label";

export type Option = {
  label: string;
  value: string;
};

export type CheckboxProps = {
  options: Option[];
  label: string;
  name: string;
  id?: string;
};

export const Checkbox = ({
  name,
  label,
  options,
  id,
  ...rest
}: CheckboxProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const error = errors[name]?.message as string;

  return (
    <Flex direction="column">
      <Label text={label} htmlFor={id ?? name} />

      <Flex direction="row" gap="5">
        {options.map((option: Option) => (
          <Text as="label" size="2" key={option.value}>
            <Flex gap="2">
              <RadixCheckbox
                size="2"
                id={id ?? name}
                {...register(name)}
                {...rest}
              />
              {option.label}
            </Flex>
          </Text>
        ))}
      </Flex>

      <InputError error={error} />
    </Flex>
  );
};

import { useUserCompanies } from "@/api/companies";
import { Box, Flex } from "@radix-ui/themes";
import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { Container } from "@/components/Container";
import { Field } from "@/components/Field";
import { Submit } from "@/components/Field/Submit";
import { Form } from "@/components/Form/Form";

import { getUserId } from "@/lib/session";

type Props = {
  onSubmit?: () => Promise<unknown>;
  active?: boolean;
};

export const CommercialCreateStepCompanyInfo = ({
  onSubmit,
  active,
}: Props) => {
  if (!onSubmit) return null;

  return (
    <Container size="2">
      <Form
        action={onSubmit}
        isSuccess
        isPending={false}
        schema="createCommercialStep1"
        isDisabled={!active}
      >
        <Inner />
      </Form>
    </Container>
  );
};

const Inner = () => {
  const { setValue } = useFormContext();
  const userCompanies = useUserCompanies(getUserId());
  const companyName = useWatch({ name: "company_name" });
  const selectedCompany = userCompanies?.data?.find(
    ({ name }) => name === companyName,
  );
  const disabled = userCompanies.isSuccess && !companyName;

  useEffect(() => {
    setValue("company_description", selectedCompany?.description);
  }, [selectedCompany, setValue]);

  return (
    <Flex gap="5" direction="column" mb="4">
      {userCompanies.isSuccess && (
        <Field
          data-testid="commercial-create-company-name"
          label="Company Name"
          name="company_name"
          type="combobox"
          values={userCompanies.data.map((company) => company.name)}
          noResultsText="Create a new company"
          placeholder="Select an existing company, or create a new one."
        />
      )}

      <Box style={{ opacity: disabled ? 0.25 : 1 }}>
        <Field
          data-testid="commercial-create-company-description"
          name="company_description"
          label="Company Description"
          placeholder="Ghostblasters makes ghost hunting equipment for the modern ghost hunting professional."
          disabled={!companyName}
        />
      </Box>

      <Submit
        text="Continue"
        disabled={!companyName}
        data-testid="commercial-create-company-submit-button"
      />
    </Flex>
  );
};

import {
  Box,
  Card, Flex,
} from "@radix-ui/themes";
import Skeleton from "react-loading-skeleton";

type LoadingCardProps = {
  count: number;
};

export const LoadingCardGrid = ({ count = 3 }: LoadingCardProps) => {
  return [...Array(count)].map((_, i: number) => (
    <Card key={i} mb="5" size="2">
      <Flex align="start" gap="5">
        <Box>
          <Skeleton count={1} width={96} height={96} />
        </Box>
        <Box style={{ flex: 1 }}>
          <Skeleton count={3} />
        </Box>
      </Flex>
    </Card>
  ));
};

import { useCreateUserAndCompanyProfile } from "@/api/userProfiles";
import { Box, Flex } from "@radix-ui/themes";
import { createFileRoute, redirect } from "@tanstack/react-router";

import { ContentCard } from "@/components/ContentCard";
import { Field } from "@/components/Field";
import { Submit } from "@/components/Field/Submit";
import { Form } from "@/components/Form";
import { AuthLayout } from "@/components/Layouts/AuthLayout";

import { getSession, setOnboardingField } from "@/lib/session";

export const Route = createFileRoute("/_authenticated/onboarding/profile/")({
  component: OnboardingCompanyShow,
  beforeLoad: () => {
    const session = getSession();

    if (session && session.company_present && session.user_profile_present) {
      throw redirect({
        to: "/commercials",
      });
    }
  },
});

const WEEKDAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function OnboardingCompanyShow() {
  const createProfile = useCreateUserAndCompanyProfile();

  const d = new Date();
  const day = WEEKDAYS[d.getDay()];

  return (
    <AuthLayout>
      <ContentCard>
        <ContentCard.Header>
          <ContentCard.Heading>Happy {day}.</ContentCard.Heading>
          <ContentCard.SubHeading>
            Let&apos;s start with a few basic details.
          </ContentCard.SubHeading>
        </ContentCard.Header>

        <Form
          action={createProfile.mutateAsync}
          callback={async () => {
            setOnboardingField("user_profile_present");
          }}
          isSuccess={createProfile.isSuccess}
          isPending={createProfile.isPending}
          redirectUrl="/onboarding/billing?redirect_status=pending"
          schema="onboardingProfile"
        >
          <Flex gap="4" direction="column">
            <Field
              type="image"
              name="image"
              label=""
              placeholder="Upload Image"
            />

            <Field
              id="user-first-name"
              name="first_name"
              label="First Name"
              placeholder="Harold"
            />
            <Field
              id="user-last-name"
              name="last_name"
              label="Last Name"
              placeholder="Ramis"
            />

            <Field
              name="name"
              label="Company Name"
              placeholder="Oyi Labs Inc."
            />
            <Field name="title" label="Title" placeholder="Senior Manager" />
          </Flex>

          <Box mt="5">
            <Submit text="Create Profile" />
          </Box>
        </Form>
      </ContentCard>
    </AuthLayout>
  );
}

import { type BackgroundMusic } from "@/api/backgroundMusic";
import { type NarratorResponse } from "@/api/narrators";
import { type ChangeEvent, type FocusEvent } from "react";
import { type Accept } from "react-dropzone";

import { Calendar } from "./Calendar";
import { Checkbox } from "./Checkbox";
import { Combobox } from "./Combobox";
import { File } from "./File";
import { ImageField } from "./ImageField";
import { Input, type RadixInputTypes } from "./Input";
import { Money } from "./Money";
import { MusicSelect } from "./MusicSelect";
import { NarratorSelect } from "./NarratorSelect";
import { Radio } from "./Radio";
import { Select } from "./Select";
import { SelectStates } from "./SelectStates";
import { TextArea } from "./TextArea";

type TextTypes = "text" | "email" | "password" | "number";
type FieldTypes =
  | TextTypes
  | "calendar"
  | "checkbox"
  | "textfield"
  | "textarea"
  | "combobox"
  | "radio"
  | "select"
  | "select-states"
  | "select-narrator"
  | "select-music"
  | "file"
  | "image"
  | "money";

export type Option = {
  label: string;
  value: string;
};

type FieldProps = {
  id?: string;
  name: string;
  optional?: boolean;
  maxCharacters?: number;
  narrators?: NarratorResponse[];
  music?: BackgroundMusic[];
  defaultValue?: string;
  onValueChange?: ((value: string) => void) | null;
  onBlur?: ((input: FocusEvent<HTMLInputElement>) => void) | null;
  onChange?: ((input: ChangeEvent<HTMLInputElement>) => void) | null;
  accept?: Accept;
  direction?: "horizontal" | "vertical";
  helper?: string;
  hideLabel?: boolean;
  options?: Option[];
  values?: string[];
  multi?: boolean;
  placeholder?: string;
  noResultsText?: string;
  label: string;
  type?: FieldTypes;
  rows?: number;
  cols?: number;
  wrap?: "hard" | "soft" | "off";
  disabled?: boolean;
};

export const Field = ({
  accept,
  name,
  options = [],
  values = [],
  placeholder = "",
  defaultValue,
  label,
  type = "text",
  optional = false,
  maxCharacters = 100,
  narrators = [],
  music = [],
  noResultsText = "No results.",
  ...rest
}: FieldProps) => {
  switch (type) {
    case "calendar":
      return (
        <Calendar
          name={name}
          label={label}
          id={name}
          defaultValue={defaultValue}
          {...rest}
        />
      );
    case "checkbox":
      return (
        <Checkbox
          name={name}
          label={label}
          id={name}
          options={options}
          {...rest}
        />
      );
    case "file":
      return (
        <File name={name} label={label} id={name} accept={accept} {...rest} />
      );
    case "image":
      return (
        <ImageField
          name={name}
          label={label}
          id={name}
          defaultValue={defaultValue}
          {...rest}
        />
      );
    case "money":
      return (
        <Money label={label} name={name} placeholder={placeholder} {...rest} />
      );
    case "radio":
      return <Radio name={name} options={options} {...rest} />;
    case "select":
      return (
        <Select
          placeholder={placeholder}
          label={label}
          name={name}
          options={options}
          defaultValue={defaultValue}
          {...rest}
        />
      );
    case "select-states":
      return (
        <SelectStates
          label={label}
          name={name}
          defaultValue={defaultValue}
          {...rest}
        />
      );
    case "select-narrator":
      return <NarratorSelect narrators={narrators} name={name} />;
    case "select-music":
      return <MusicSelect music={music} name={name} />;
    case "textarea":
      return (
        <TextArea
          label={label}
          name={name}
          defaultValue={defaultValue}
          placeholder={placeholder}
          optional={optional}
          maxCharacters={maxCharacters}
          {...rest}
        />
      );
    case "combobox":
      return (
        <Combobox
          label={label}
          name={name}
          values={values}
          placeholder={placeholder}
          noResultsText={noResultsText}
          {...rest}
        />
      );

    default:
      return (
        <Input
          type={type as RadixInputTypes}
          label={label}
          name={name}
          defaultValue={defaultValue}
          placeholder={placeholder}
          {...rest}
        />
      );
  }
};

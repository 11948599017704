import { type BackgroundMusic } from "@/api/backgroundMusic";
import { Box, Card, Flex, Grid, Text } from "@radix-ui/themes";
import { useController } from "react-hook-form";

import { SimpleAudioPlayer } from "../SimpleAudioPlayer/SimpleAudioPlayer";

type MusicSelectOptionParams = {
  id: number;
  src: string;
  name: string;
  music_name: string;
  description: string;
  handleSelect: (arg1: string) => void;
  value: string;
};

type MusicSelectParams = {
  music: BackgroundMusic[];
  name: string;
};

const MusicSelectOption = ({
  id,
  src,
  music_name,
  handleSelect,
  description,
  value,
}: MusicSelectOptionParams) => {
  if (!src.startsWith("http") && !src.startsWith("blob")) {
    src = String(import.meta.env.VITE_API_URL) + src;
  }

  const isSelected = value === String(id);

  const handleKeypress = (event: React.KeyboardEvent) => {
    event.preventDefault();

    const { key } = event;
    const openKeys = ["Enter", " "];
    if (openKeys.includes(key)) handleSelect(String(id));
  };

  return (
    <Box
      width="320px"
      onClick={() => handleSelect(String(id))}
      onKeyDown={handleKeypress}
      tabIndex={0}
      role="button"
      data-testid="music-tile"
    >
      <Card
        style={{
          border: isSelected ? "1px solid #FFF" : "1px solid transparent",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        <Flex gap="3" align="center">
          <SimpleAudioPlayer src={src} />
          <Box>
            <Text as="div" size="5" weight="bold">
              {music_name}
            </Text>
            <Text as="div" size="2" color="gray">
              {description}
            </Text>
          </Box>
        </Flex>
      </Card>
    </Box>
  );
};

export const MusicSelect = ({ music, name }: MusicSelectParams) => {
  const {
    field: { value, onChange },
  } = useController({ name });

  const handleSelect = (id: string) => {
    onChange(id);
  };

  return (
    <Grid columns={{ initial: "1fr", md: "1fr 1fr" }} gap="3">
      {music.map((track) => (
        <MusicSelectOption
          key={track.id}
          id={track.id}
          src={track.audio_file.url}
          music_name={track.name}
          name={name}
          description={track.genre}
          handleSelect={handleSelect}
          value={value}
        />
      ))}
    </Grid>
  );
};

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { axiosInstance } from "@/lib/api";

import { getCommercial } from "./commercials";

const commercialShareSchema = z.object({
  commercial_id: z.number(),
  published: z.boolean(),
});

type CommercialShare = z.infer<typeof commercialShareSchema>;

export type CommercialShareResponse = Omit<CommercialShare, "commercial_id"> & {
  id: number;
  name: string;
  guid: string;
  published: boolean;
  narrator_name: string;
  created_at: string;
  updated_at: string;
};

const getCommercialShare = async (
  id: number | null,
): Promise<CommercialShareResponse | null> => {
  if (!id) return null;

  const { data } = await axiosInstance.get<CommercialShareResponse>(
    `/api/commercial_shares/${id}`,
  );

  return data;
};

const createCommercialShare = async (
  params: CommercialShare,
): Promise<CommercialShareResponse> => {
  const payload = commercialShareSchema.parse(params);
  const { data } = await axiosInstance.post<CommercialShareResponse>(
    "/api/commercial_shares",
    {
      commercial_share: payload,
    },
  );

  return data;
};

const updateCommercialShare = async (
  id: number,
  params: CommercialShare,
): Promise<CommercialShareResponse> => {
  const payload = commercialShareSchema.parse(params);
  const { data } = await axiosInstance.patch<CommercialShareResponse>(
    `/api/commercial_shares/${id}`,
    {
      commercial_share: payload,
    },
  );

  return data;
};

const deleteCommercialShare = async (id: number): Promise<void> => {
  await axiosInstance.delete(`/api/commercial_shares/${id}`);
};

export const useCommercialShare = (id: number | null) => {
  return useQuery({
    queryKey: ["commercial_share", id],
    queryFn: async () => getCommercialShare(id),
    retry: true,
    retryDelay: 3000,
    refetchOnWindowFocus: false,
  });
};

export const useFindOrCreateCommercialShare = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: CommercialShare) => {
      const commercial = await getCommercial(params.commercial_id);

      if (commercial.commercial_share_id) {
        return await getCommercialShare(commercial.commercial_share_id);
      }

      return await createCommercialShare(params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["commercial_share"] });
    },
  });
};

export const useUpdateCommercialShare = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: CommercialShare) => {
      const commercialShare = await updateCommercialShare(id, params);
      return commercialShare;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["commercial_share", id] });
    },
  });
};

export const useDeleteCommercialShare = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (): Promise<void> => {
      return await deleteCommercialShare(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["commercial_share", id],
      });
    },
  });
};

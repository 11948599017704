import { isDevelopment } from "./environment";

type CloudflareTraceData = {
  fl?: string; // Cloudflare Ray ID
  h?: string; // HTTP Host header
  ip: string; // Client IP address
  ts?: string; // Unix timestamp (seconds)
  visit_scheme?: string; // Request scheme (http or https)
  uag?: string; // User Agent string
  colo?: string; // Cloudflare data center
  sliver?: string; // Cloudflare sliver name
  http?: string; // HTTP version
  loc?: string; // Country code
  tls?: string; // TLS version
  sni?: string; // SNI value
  warp?: string; // Warp+ status
  gateway?: string; // Gateway status
  rbi?: string; // Random Body Inline status
  kex?: string; // Key exchange algorithm
};

export const getTraceData = async (): Promise<CloudflareTraceData> => {
  try {
    const response = await fetch("https://www.cloudflare.com/cdn-cgi/trace", {
      cache: "force-cache",
    });
    const text = await response.text();
    const lines = text.trim().split("\n");

    const data: CloudflareTraceData = lines.reduce((acc, line) => {
      const [key, value] = line.split("=");
      acc[key as keyof CloudflareTraceData] = value;
      return acc;
    }, {} as CloudflareTraceData);

    return data;
  } catch (error) {
    if (isDevelopment()) {
      console.error("Error fetching Cloudflare trace:", error);
    }

    return {
      ip: "127.0.0.1",
    };
  }
};

import { Box } from "@radix-ui/themes";

import "./commercialLayout.scss";

type Props = {
  children: React.ReactNode;
};

export const CommercialLayout = ({ children }: Props) => {
  return (
    <Box>
      <Box className="audio-grid-background">
        <Box className="audio-striped-background">
          <Box className="audio-scrollview">{children}</Box>
        </Box>
      </Box>
    </Box>
  );
};

import { Flex, Text, TextField } from "@radix-ui/themes";
import { useFormContext } from "react-hook-form";

import { InputError } from "./InputError";
import { Label } from "./Label";

export type RadixInputTypes =
  | "number"
  | "url"
  | "email"
  | "password"
  | "date"
  | "search"
  | "time"
  | "text"
  | "hidden"
  | "tel"
  | "datetime-local"
  | "month"
  | "week";

type Props = {
  id?: string;
  label: string;
  name: string;
  defaultValue?: string;
  helper?: string;
  placeholder?: string | null;
  register?: unknown;
  type?: RadixInputTypes;
  addon?: string;
};

export const Input = ({
  id,
  helper,
  label,
  name,
  type = "text",
  defaultValue = "",
  placeholder = null,
  ...rest
}: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const error = errors[name]?.message as string;

  return (
    <Flex direction="column">
      <Label text={label} htmlFor={id ?? name} />

      <TextField.Root
        placeholder={placeholder ?? ""}
        {...register(name)}
        id={id ?? name}
        type={type}
        defaultValue={defaultValue}
        size="3"
        {...rest}
      />

      {helper && (
        <Text size="1" color="gray" mt="1">
          {helper}
        </Text>
      )}

      <InputError error={error} />
    </Flex>
  );
};

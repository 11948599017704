import { useQuery } from "@tanstack/react-query";

import { axiosInstance } from "@/lib/api";
import { getUserProfile } from "./userProfiles";

type PaymentMethod = {
  id: string;
  card: {
    brand: string;
    exp_month: number;
    exp_year: number;
    last4: "string";
  };
  created: number;
  livemode: boolean;
  type: string;
};

type PaymentMethods = {
  object: string;
  data: PaymentMethod[];
  has_more: boolean;
  url: string;
};

export type StripeCustomerResponse = {
  id: number;
  customer_id: string;
  payment_methods: PaymentMethods;
  created_at: string;
  updated_at: string;
};

export const getStripeCustomer = async (): Promise<StripeCustomerResponse> => {
  const { data } = await axiosInstance.get("/api/stripe_customer");
  return data;
};

const createStripeCustomer = async (): Promise<StripeCustomerResponse> => {
  const { data } = await axiosInstance.post("/api/stripe_customer");
  return data;
};

export const useStripeCustomer = () => {
  return useQuery({
    queryKey: ["stripe-customer"],
    queryFn: async () => {
      await getUserProfile();

      try {
        return await getStripeCustomer();
      } catch (e) {
        return await createStripeCustomer();
      }
    },
    refetchOnWindowFocus: false,
  });
};

import * as Ariakit from "@ariakit/react";
import { matchSorter } from "match-sorter";
import { startTransition, useMemo, useState } from "react";
import { useController } from "react-hook-form";

import { InputError } from "./InputError";
import "./combobox.scss";

type ComboboxParams = {
  id?: string;
  label: string;
  name: string;
  defaultValue?: string;
  values: string[];
  placeholder?: string;
  noResultsText?: string;
};

export const Combobox = ({
  id,
  label,
  name,
  values,
  placeholder = "Search...",
  noResultsText = "No results.",
  ...rest
}: ComboboxParams) => {
  const {
    field: { onChange },
    formState: { errors },
  } = useController({ name });

  const error = errors[name]?.message as string;

  const [searchValue, setSearchValue] = useState("");
  const matches = useMemo(
    () => matchSorter(values, searchValue),
    [searchValue, values],
  );

  const onValueSet = (value: string) => {
    setSearchValue(value);
    onChange(value);
  };

  return (
    <Ariakit.ComboboxProvider
      setValue={(value) => {
        startTransition(() => onValueSet(value));
      }}
    >
      <Ariakit.ComboboxLabel className="label">{label}</Ariakit.ComboboxLabel>
      <Ariakit.Combobox
        id={id ?? name}
        placeholder={placeholder}
        className="combobox"
        {...rest}
      />
      <InputError error={error} />

      <Ariakit.ComboboxPopover gutter={8} sameWidth className="popover">
        <Ariakit.ComboboxGroup>
          {matches.map((value) => (
            <Ariakit.ComboboxItem
              key={value}
              value={value}
              className="combobox-item"
            />
          ))}
        </Ariakit.ComboboxGroup>

        {matches.length === 0 && (
          <Ariakit.ComboboxItem
            key={noResultsText}
            value={noResultsText}
            className="combobox-item"
            setValueOnClick={false}
          />
        )}
      </Ariakit.ComboboxPopover>
    </Ariakit.ComboboxProvider>
  );
};

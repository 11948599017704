import { create } from "zustand";

type State = {
  refs: HTMLDivElement[];
  currentStep: number;
  data: Record<string, unknown>;
};

type Actions = {
  setRef: (arg1: number, arg2: HTMLDivElement) => void;
  incrementStep: () => void;
  setData: (arg1: Record<string, unknown>) => void;
};

export const useWizard = create<State & Actions>((set) => ({
  currentStep: 0,
  refs: [],
  data: {},
  setRef: (index: number, ref: HTMLDivElement) => {
    set((state) => {
      // This leaves empty array indices, which JS wont loop over
      // until they're set by WizardStep's 🧙
      const newRefs = [...state.refs];
      newRefs[index] = ref;

      return {
        refs: newRefs,
      };
    });
  },
  incrementStep: () => {
    set((state) => ({
      currentStep: state.currentStep + 1,
    }));
  },
  setData: (data) => {
    set((state) => ({
      data: {
        ...state.data,
        ...data,
      },
    }));
  },
}));

import { create } from "zustand";

import { type Session, getSession, isAuthenticated } from "@/lib/session";

type SessionState = {
  session: Session | undefined;
  loggedIn: boolean;
  updateSession: () => void;
};

export const useSession = create<SessionState>((set) => ({
  session: getSession(),
  loggedIn: isAuthenticated(),
  updateSession: () => {
    set(() => ({
      session: getSession(),
      loggedIn: isAuthenticated(),
    }));
  },
}));

import { Text } from "@radix-ui/themes";

type LabelProps = {
  text: string;
  htmlFor: string;
};

export const Label = ({ text, htmlFor }: LabelProps) => (
  <Text as="label" size="2" mb="1" htmlFor={htmlFor}>
    {text}
  </Text>
);

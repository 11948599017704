import { Wizard, WizardStep } from "@/components/Wizard";

import {
  CommercialCreateStepCompanyInfo,
  CommercialCreateStepMeta,
  CommercialCreateStepMusic,
  CommercialCreateStepNarrator,
  CommercialCreateStepProductInfo,
} from "./Steps";

export const CommercialCreateForm = () => {
  return (
    <Wizard>
      <WizardStep
        title="Let's get started."
        subtitle="Tell us a bit about the company you're advertising."
      >
        <CommercialCreateStepCompanyInfo />
      </WizardStep>

      <WizardStep title="Tell us about the product.">
        <CommercialCreateStepProductInfo />
      </WizardStep>

      <WizardStep title="Pick the voice for your commercial.">
        <CommercialCreateStepNarrator />
      </WizardStep>

      <WizardStep title="Set the mood with some tunes.">
        <CommercialCreateStepMusic />
      </WizardStep>

      <WizardStep title="Last step...">
        <CommercialCreateStepMeta />
      </WizardStep>
    </Wizard>
  );
};

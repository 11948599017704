import { useUpdateUserCompany, useUserCompany } from "@/api/companies";
import { Box, Flex, Grid } from "@radix-ui/themes";
import Skeleton from "react-loading-skeleton";

import { ContentCard } from "@/components/ContentCard";
import { Field } from "@/components/Field";
import { Form } from "@/components/Form";

type CompanyFormProps = {
  userId: number;
};

export const CompanyForm = ({ userId }: CompanyFormProps) => {
  const company = useUserCompany(userId);
  const companyUpdate = useUpdateUserCompany(userId);

  return (
    <ContentCard>
      <ContentCard.Header>
        <ContentCard.Heading>Company Info</ContentCard.Heading>
      </ContentCard.Header>

      {company.isLoading && <Skeleton count={3} />}

      {company.isSuccess && (
        <Form
          action={companyUpdate.mutateAsync}
          isSuccess={companyUpdate.isSuccess}
          isPending={companyUpdate.isPending}
          successMessage="Company Info Updated!"
          schema="company"
          submitText="Update Company"
          showButton
        >
          <Grid
            gap="4"
            columns={{
              xs: "1",
              md: "2fr 1fr",
            }}
          >
            <Flex direction="column" gap="4">
              <Field
                name="name"
                defaultValue={company.data.name}
                label="Company Name"
                placeholder="Oyi Labs Inc."
              />
              <Field
                name="address"
                defaultValue={company.data.address}
                label="Address"
                placeholder="14 North Moore Street"
              />
              <Field
                name="industry"
                defaultValue={company.data.industry}
                label="Industry"
                placeholder="AI Tech"
              />
              <Field
                name="title"
                defaultValue={company.data.title}
                label="Title"
                placeholder="Senior Manager"
              />
            </Flex>

            <Box>
              <Field
                type="image"
                name="image"
                defaultValue={company.data.image}
                label=""
                placeholder="Upload Image"
              />
            </Box>
          </Grid>
          <Grid
            gap="4"
            columns={{
              xs: "1",
              sm: "3",
            }}
          >
            <Field
              name="city"
              defaultValue={company.data.city}
              label="City"
              placeholder="New York"
            />
            <Field
              type="select-states"
              name="state"
              defaultValue={company.data.state}
              label="State"
              placeholder="NY"
            />
            <Field
              type="number"
              defaultValue={company.data.zip}
              name="zip"
              label="Zip"
              placeholder="10013"
            />
          </Grid>
        </Form>
      )}
    </ContentCard>
  );
};

import {
  PauseIcon,
  PlayIcon,
  SpeakerLoudIcon,
  SpeakerOffIcon,
} from "@radix-ui/react-icons";
import {
  Box,
  Button,
  Card,
  Flex,
  IconButton,
  Slider,
  Text,
} from "@radix-ui/themes";
import { useWavesurfer } from "@wavesurfer/react";
import { useRef, useState } from "react";

import "./audioPlayer.scss";

type AudioPlayerProps = {
  src: string;
  isLoading?: boolean;
  showVolumeControl?: boolean;
  showTime?: boolean;
  variant?: "compact";
};

const formatTime = (seconds: number) =>
  [seconds / 60, seconds % 60]
    .map((v) => `0${Math.floor(v)}`.slice(-2))
    .join(":");

export const AudioPlayer = ({
  src,
  isLoading = false,
  showVolumeControl = false,
  showTime = false,
  variant,
}: AudioPlayerProps) => {
  const [volume, setVolume] = useState<number>(1);
  const [muted, setMuted] = useState<boolean>(false);
  const containerRef = useRef(null);

  if (!src.startsWith("http") && !src.startsWith("blob")) {
    src = String(import.meta.env.VITE_API_URL) + src;
  }

  const { wavesurfer, isPlaying, currentTime } = useWavesurfer({
    container: containerRef,
    url: src,
    // https://www.radix-ui.com/colors
    progressColor: "color(display-p3 0.129 0.18 0.369)",
    waveColor: "color(display-p3 0.354 0.445 0.866)",
    height: "auto",
    barWidth: 3,
    barGap: 1,
    barRadius: 10,
  });

  const togglePlayPause = (): void => {
    if (!wavesurfer) return;
    setMuted(false);
    wavesurfer.setVolume(volume);
    wavesurfer.playPause();
  };

  const changeVolume = (value: number[]): void => {
    const newVolume = value[0] / 100;
    setVolume(newVolume);
    setMuted(false);
    wavesurfer && wavesurfer.setVolume(newVolume);
  };

  const toggleVolume = (): void => {
    if (!wavesurfer) return;

    if (muted) {
      setMuted(false);
      wavesurfer.setVolume(volume);
    }

    if (!muted) {
      setMuted(true);
      wavesurfer.setVolume(0);
    }
  };

  const size = variant === "compact" ? 12 : 18;
  return (
    <Card className="AudioPlayer" size="1">
      <Flex gap="5" justify="center" align="center">
        <Box>
          <IconButton
            disabled={isLoading}
            radius="full"
            variant={isPlaying ? "solid" : "soft"}
            onClick={togglePlayPause}
            size={variant === "compact" ? "1" : "4"}
          >
            {isPlaying ? (
              <PauseIcon width={size} height={size} />
            ) : (
              <PlayIcon width={size} height={size} />
            )}
          </IconButton>
        </Box>

        <Box
          flexGrow="1"
          height={variant === "compact" ? "1.5rem" : "3rem"}
          ref={containerRef}
        />

        {showTime && (
          <Flex
            width="5rem"
            className="audio-time"
            mr={showVolumeControl ? "0" : "2"}
            gap="1"
          >
            <Box width="3rem">
              <Text as="p" size="1" align="center">
                {formatTime(currentTime)}
              </Text>
            </Box>
            <Text as="p" size="1">
              /
            </Text>
            <Text as="p" size="1">
              {formatTime(Number(wavesurfer?.getDuration()))}
            </Text>
          </Flex>
        )}

        {showVolumeControl && (
          <Flex
            direction="row"
            align="center"
            className="audio-slider"
            gap="3"
            pr="5"
          >
            <Button
              disabled={isLoading}
              radius="full"
              variant="ghost"
              onClick={toggleVolume}
              style={{ width: 24, height: 24 }}
            >
              <>
                {!muted && <SpeakerLoudIcon width={18} height={18} />}
                {muted && <SpeakerOffIcon width={18} height={18} />}
              </>
            </Button>

            <Slider
              disabled={isLoading}
              defaultValue={[100]}
              onValueChange={changeVolume}
              radius="full"
              size="1"
            />
          </Flex>
        )}
      </Flex>
    </Card>
  );
};

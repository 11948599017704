import { Flex } from "@radix-ui/themes";
import { useMemo } from "react";
import { type Accept, useDropzone } from "react-dropzone";
import { useController } from "react-hook-form";

import { Label } from "./Label";

type FileProps = {
  name: string;
  id?: string;
  accept?: Accept | undefined;
  label: string;
};

const baseStyle = {
  flex: 1,
  display: "flex",
  background: "#fafafa",
  alignItems: "center",
  padding: "20px",
  borderStyle: "solid",
  borderColor: "#e5e5e5",
  borderWidth: "1px",
  borderRadius: "4px",
  color: "#aaa",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  background: "#fff",
  borderColor: "#ccc",
};

const acceptStyle = {
  borderColor: "#00e676",
  color: "#666",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export const File = ({ id, name, accept, label }: FileProps) => {
  const {
    field: { onChange },
  } = useController({ name });

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    isFocused,
  } = useDropzone({
    onDrop: onChange,
    accept: accept ?? {
      "audio/wav": [".wav"],
      "audio/mpeg": [".mp3"],
      "audio/mp4": [".mp4", ".m4a"],
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  const acceptedFile = acceptedFiles[0];

  return (
    <Flex direction="column">
      <Label text={label} htmlFor={id ?? name} />

      <div {...getRootProps({ style })}>
        <input {...getInputProps()} id={id ?? name} />

        {acceptedFile ? (
          <p>{acceptedFile.name}</p>
        ) : (
          <>
            {isDragActive ? (
              <p>Drop the file here...</p>
            ) : (
              <p>Click or drag .wav file</p>
            )}
          </>
        )}
      </div>
    </Flex>
  );
};

import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { z } from "zod";

import { axiosInstance } from "@/lib/api";
import { getSession } from "@/lib/session";

import { getBackgroundMusic } from "./backgroundMusic";
import { type Campaign, createCampaign } from "./campaigns";
import { getUserCompanies } from "./companies";
import { type ScriptCreate, createScript } from "./scripts";

const commercialSchema = z.object({
  name: z.string(),
  product_name: z.string(),
  product_description: z.string(),
  speech_library_id: z.string(),
  campaign_id: z.number(),
  company_id: z.number(),
});

const commercialCombineSchema = z.object({
  combine_commercial: z.boolean(),
});

type Commercial = z.infer<typeof commercialSchema>;
type CombineCommercial = z.infer<typeof commercialCombineSchema>;

type AasmState =
  | "fresh"
  | "generating_dictations"
  | "dictations_generated"
  | "complete";

export type CommercialResponse = Commercial & {
  id: number;
  name: string;
  user_id: number;
  commercial_share_id: number | null;
  speech_library_id: number;
  speech_library_name: string;
  aasm_state: AasmState;
  settings: null;
  created_at: string;
  updated_at: string;
  final_audio: {
    url?: string;
  };
};

export const getCommercial = async (
  id: number,
): Promise<CommercialResponse> => {
  const { data } = await axiosInstance.get<CommercialResponse>(
    `/api/commercials/${id}`,
  );

  // If error is thrown Tanstack will keep retrying.
  // Note (Rob) - Disabling this as all aasm_states are fresh,
  // and we tentatively need to separate out the polling request
  // to support the new UI flow.

  // if (data.aasm_state !== "complete") {
  //   throw new Error("Waiting for audio to generate.");
  // }
  return data;
};

const createCommercial = async (
  params: Commercial,
): Promise<CommercialResponse> => {
  const payload = commercialSchema.parse(params);
  const { data } = await axiosInstance.post<CommercialResponse>(
    "/api/commercials",
    {
      commercial: payload,
    },
  );

  return data;
};

const updateCommercial = async (
  commercialId: number,
  params: Commercial,
): Promise<CommercialResponse> => {
  const payload = commercialSchema.parse(params);
  const { data } = await axiosInstance.patch<CommercialResponse>(
    `/api/commercials/${commercialId}`,
    {
      commercial: payload,
    },
  );

  return data;
};

const combineCommercial = async (
  commercialId: number,
  params: CombineCommercial,
): Promise<CommercialResponse> => {
  const payload = commercialCombineSchema.parse(params);
  const { data } = await axiosInstance.patch<CommercialResponse>(
    `/api/commercials/${commercialId}`,
    {
      commercial: payload,
    },
  );

  return data;
};

const deleteCommercial = async (commercialId: number): Promise<void> => {
  await axiosInstance.delete(`/api/commercials/${commercialId}`);
};

const getAllCommercials = async (): Promise<CommercialResponse[]> => {
  const { data } =
    await axiosInstance.get<CommercialResponse[]>("/api/commercials/");

  return data;
};

export const prefetchCommercial = async (
  queryClient: QueryClient,
  commercialId: number,
) => {
  await queryClient.prefetchQuery({
    queryKey: ["commercials", commercialId],
    queryFn: async () => getCommercial(commercialId),
    staleTime: 5000,
  });
};

export const useCommercials = () => {
  return useQuery({
    queryKey: ["commercials"],
    queryFn: getAllCommercials,
    refetchOnWindowFocus: false,
  });
};

export const useCommercial = (id: number, opts?: object) => {
  return useQuery({
    queryKey: ["commercials", id],
    queryFn: async () => getCommercial(id),
    retry: true,
    retryDelay: 3000,
    refetchOnWindowFocus: false,
    ...opts,
  });
};

export const useCreateCommercial = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: Commercial) => {
      const commercial = await createCommercial(params);
      return commercial;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["commercials"] });
    },
  });
};

export type CompleteCommercial = Campaign &
  ScriptCreate &
  Commercial & {
    company_name: string;
    company_description: string;
  };

export const useCreateCompleteCommercial = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: CompleteCommercial) => {
      const session = getSession();

      if (!session) return;

      const company = await getUserCompanies(session?.id);
      const campaign = await createCampaign({
        name: params.name,
        company_id: company[0].id,
        narrator_id: String(params.narrator_id),
      });

      const commercial = await createCommercial({
        name: params.name,
        product_name: params.product_name,
        product_description: params.product_description,
        campaign_id: campaign.id,
        speech_library_id: params.speech_library_id,
        company_id: params.company_id,
      });

      const backgroundMusic = await getBackgroundMusic();
      const noMusic = backgroundMusic.find((music) => {
        return music.name === "No Music";
      });

      if (noMusic && !params.background_music_id) {
        params.background_music_id = noMusic.id;
      }

      await createScript(commercial.id, {
        commercial_id: commercial.id,
        length: params.length,
        background_music_id: params.background_music_id,
        narrator_id: String(params.narrator_id),
      });

      return commercial;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["campaigns"] });
      queryClient.invalidateQueries({ queryKey: ["commercials"] });
    },
  });
};

export const useUpdateCommercial = (commercialId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: Commercial) => {
      const commercial = await updateCommercial(commercialId, params);
      return commercial;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["commercials"] });
    },
  });
};

export const useCombineCommercial = (commercialId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      return await combineCommercial(commercialId, {
        combine_commercial: true,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["commercials"] });
      queryClient.invalidateQueries({ queryKey: ["commercial", commercialId] });
    },
  });
};

export const useDeleteCommercial = (commercialId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (): Promise<void> => {
      return await deleteCommercial(commercialId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["commercials"],
      });
    },
  });
};

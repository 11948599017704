import { router } from "@/router";
import axios, { type AxiosError } from "axios";

import { deleteSession, getSession } from "@/lib/session";

import { sentryCapture } from "@/util/sentry";

export const axiosInstance = axios.create({
  baseURL: String(import.meta.env.VITE_API_URL),
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

axiosInstance.interceptors.request.use((config) => {
  const controller = new AbortController();
  const session = getSession();

  if (session) {
    config.headers.Authorization = session.token;
  }

  if (!navigator.onLine) {
    controller.abort();
  }

  return {
    ...config,
    signal: controller.signal,
  };
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (!error.response) return Promise.reject(error);

    if (error.response.status === 401) {
      router.navigate({
        to: "/",
      });

      deleteSession();
    }

    if (error.response.status >= 500) {
      sentryCapture(error);
    }

    return Promise.reject(error);
  },
);

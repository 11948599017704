import { useUpdateUserProfile, useUserProfile } from "@/api/userProfiles";
import { Grid } from "@radix-ui/themes";
import Skeleton from "react-loading-skeleton";

import { ContentCard } from "@/components/ContentCard";
import { Field } from "@/components/Field";
import { Form } from "@/components/Form";

export const ProfileForm = () => {
  const user = useUserProfile();
  const updateUser = useUpdateUserProfile();

  return (
    <ContentCard>
      <ContentCard.Header>
        <ContentCard.Heading>Personal Info</ContentCard.Heading>
        <ContentCard.SubHeading>
          Modify your non-public personal information.
        </ContentCard.SubHeading>
      </ContentCard.Header>

      {user.isLoading && <Skeleton count={3} />}
      {user.isSuccess && (
        <Form
          action={updateUser.mutateAsync}
          isPending={updateUser.isPending}
          isSuccess={updateUser.isSuccess}
          successMessage="Personal Info Updated!"
          schema="onboardingProfile"
          submitText="Update Profile"
          showButton
        >
          <Grid
            gap="4"
            columns={{
              sm: "2",
              xs: "1",
            }}
          >
            <Field
              id="user-first-name"
              name="first_name"
              defaultValue={user.data.first_name}
              label="First Name"
              placeholder="Harold"
            />
            <Field
              id="user-last-name"
              name="last_name"
              defaultValue={user.data.last_name}
              label="Last Name"
              placeholder="Ramis"
            />
          </Grid>
          <Field
            id="user-address"
            name="address"
            defaultValue={user.data.address}
            label="Address"
            placeholder="14 North Moore Street"
          />
          <Grid
            gap="4"
            columns={{
              sm: "3",
              xs: "1",
            }}
          >
            <Field
              id="user-city"
              name="city"
              defaultValue={user.data.city}
              label="City"
              placeholder="New York"
            />
            <Field
              id="user-state"
              type="select-states"
              name="state"
              label="State"
              placeholder="NY"
              defaultValue={user.data.state}
            />
            <Field
              id="user-zip"
              type="number"
              name="zip"
              defaultValue={user.data.zip}
              label="Zip"
              placeholder="10013"
            />
          </Grid>
        </Form>
      )}
    </ContentCard>
  );
};

import { ContainerProps, Container as RadixContainer } from "@radix-ui/themes";

type Props = {
  children: React.ReactNode;
  size?: "1" | "2" | "3" | "4";
};

export const Container = ({
  children,
  size,
  ...rest
}: Props & ContainerProps) => (
  <RadixContainer size={size} px={{ initial: "5", sm: "6", md: "7" }} {...rest}>
    {children}
  </RadixContainer>
);

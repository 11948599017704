import { useQuery } from "@tanstack/react-query";

import { axiosInstance } from "@/lib/api";

export type StripeSetupIntentResponse = {
  id: string;
  client_secret: string;
  customer: string;
};

const createStripeSetupIntent = async (): Promise<StripeSetupIntentResponse> => {
  const { data } = await axiosInstance.post("/api/stripe_setup_intent");
  return data;
}

export const useCreateStripeSetupIntent = () => {
  return useQuery({
    queryKey: ["stripe-setup-intent"],
    queryFn: createStripeSetupIntent,
    retry: true,
  });
}

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { axiosInstance } from "@/lib/api";

export const speechSchema = z.object({
  quality: z.string(),
  text: z.string(),
});

export const speechUpdateSchema = z.object({
  id: z.number(),
  selected: z.boolean(),
});

export type Speech = z.infer<typeof speechSchema>;
export type SpeechUpdate = z.infer<typeof speechUpdateSchema>;
export type SpeechState = "queued" | "generating" | "generated" | "failed";
export type SpeechSelectUpdate = {
  selectedId: number;
  deselectedId: number;
};
export type VoiceConversion = {
  id: number;
  audio_file?: {
    url: string;
  };
};

export type SpeechResponse = Speech & {
  id: number;
  type: string;
  model: string;
  aasm_state: SpeechState;
  selected: boolean;
  script_line_id: number;
  audio_file?: {
    url: string;
  };
  voice_conversions?: VoiceConversion[];
};

export const getSpeech = async (
  commercialId: number,
  scriptId: number,
  scriptLineFrontendGuid: string,
  id: number,
): Promise<SpeechResponse> => {
  const { data } = await axiosInstance.get<SpeechResponse>(
    `/api/commercials/${commercialId}/scripts/${scriptId}/script_lines/${scriptLineFrontendGuid}/speeches/${id}`,
  );

  return data;
};

const getSpeeches = async (
  commercialId: number,
  scriptId: number,
  scriptLineFrontendGuid: string,
): Promise<SpeechResponse[]> => {
  const { data } = await axiosInstance.get(
    `/api/commercials/${commercialId}/scripts/${scriptId}/script_lines/${scriptLineFrontendGuid}/speeches`,
  );

  return data;
};

const createSpeech = async (
  commercialId: number,
  scriptId: number,
  scriptLineFrontendGuid: string,
  params: Speech,
): Promise<SpeechResponse[]> => {
  const payload = speechSchema.parse(params);

  const { data } = await axiosInstance.post(
    `/api/commercials/${commercialId}/scripts/${scriptId}/script_lines/${scriptLineFrontendGuid}/speeches`,
    {
      speech: payload,
    },
  );

  return data;
};

const updateSpeech = async (
  commercialId: number,
  scriptId: number,
  scriptLineFrontendGuid: string,
  params: SpeechUpdate,
): Promise<SpeechResponse[]> => {
  const payload = speechUpdateSchema.parse(params);
  const { data } = await axiosInstance.patch(
    `/api/commercials/${commercialId}/scripts/${scriptId}/script_lines/${scriptLineFrontendGuid}/speeches/${payload.id}`,
    {
      speech: {
        selected: payload.selected,
      },
    },
  );

  return data;
};

const deleteSpeech = async (
  commercialId: number,
  scriptId: number,
  scriptLineFrontendGuid: string,
  id: number,
): Promise<void> => {
  await axiosInstance.delete(
    `/api/commercials/${commercialId}/scripts/${scriptId}/script_lines/${scriptLineFrontendGuid}/speeches/${id}`,
  );
};

export const useSpeech = (
  commercialId: number,
  scriptId: number,
  scriptLineFrontendGuid: string,
  id: number,
) => {
  return useQuery({
    queryKey: ["speeches", id],
    queryFn: () =>
      getSpeech(commercialId, scriptId, scriptLineFrontendGuid, id),
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000,
  });
};

export const useSpeeches = (
  commercialId: number,
  scriptId: number,
  scriptLineFrontendGuid: string,
) => {
  return useQuery({
    queryKey: ["script-line-speeches", scriptLineFrontendGuid],
    queryFn: () => getSpeeches(commercialId, scriptId, scriptLineFrontendGuid),
    staleTime: 2 * 60 * 1000, // 2 Minutes
  });
};

export const useCreateSpeech = (
  commercialId: number,
  scriptId: number,
  scriptLineFrontendGuid: string,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: Speech) =>
      createSpeech(commercialId, scriptId, scriptLineFrontendGuid, params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["script-lines", scriptLineFrontendGuid],
      });
    },
  });
};

export const useSelectSpeech = (
  commercialId: number,
  scriptId: number,
  scriptLineFrontendGuid: string,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: SpeechSelectUpdate) => {
      await updateSpeech(commercialId, scriptId, scriptLineFrontendGuid, {
        id: params.deselectedId,
        selected: false,
      });

      return updateSpeech(commercialId, scriptId, scriptLineFrontendGuid, {
        id: params.selectedId,
        selected: true,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["commercial-scripts", commercialId],
      });

      queryClient.invalidateQueries({
        queryKey: ["script-lines", scriptLineFrontendGuid],
      });
    },
  });
};

export const useUpdateSpeech = (
  commercialId: number,
  scriptId: number,
  scriptLineFrontendGuid: string,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: SpeechUpdate) =>
      updateSpeech(commercialId, scriptId, scriptLineFrontendGuid, params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["commercial-scripts", commercialId],
      });

      queryClient.invalidateQueries({
        queryKey: ["script-lines", scriptLineFrontendGuid],
      });
    },
  });
};

export const useDeleteSpeech = (
  commercialId: number,
  scriptId: number,
  scriptLineFrontendGuid: string,
  id: number,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () =>
      deleteSpeech(commercialId, scriptId, scriptLineFrontendGuid, id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["script-lines", scriptLineFrontendGuid],
      });
    },
  });
};

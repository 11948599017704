import { Flex, TextField } from "@radix-ui/themes";
import { useController } from "react-hook-form";
import { NumericFormat } from "react-number-format";

import { InputError } from "./InputError";
import { Label } from "./Label";

type Props = {
  id?: string;
  label: string;
  name: string;
  placeholder?: string;
  register?: unknown;
};

const CustomInput = () => <TextField.Root size="3" />;
export const Money = ({ id, label, name, placeholder, ...rest }: Props) => {
  const {
    field: { onChange },
    fieldState: { error },
  } = useController({ name });

  const _error = error?.message as string;

  return (
    <Flex direction="column">
      <Label text={label} htmlFor={id || name} />

      <NumericFormat
        id={id || name}
        thousandSeparator=","
        prefix={"$"}
        onValueChange={(values) => {
          onChange(+values.value);
        }}
        {...rest}
        customInput={CustomInput}
        placeholder={placeholder}
      />

      <InputError error={_error} />
    </Flex>
  );
};

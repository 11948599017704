import { useFindOrCreateCommercialShare } from "@/api/commercialShares";
import { useOnMount } from "@/hooks/onMount";
import { ReloadIcon } from "@radix-ui/react-icons";
import { Box, Container, Flex, Heading } from "@radix-ui/themes";
import { createFileRoute } from "@tanstack/react-router";
import toast from "react-hot-toast";

import { sleep } from "@/util/async";

export const Route = createFileRoute("/s/$commercialId")({
  component: Share,
});

function Share() {
  const { commercialId } = Route.useParams();

  const findOrCreateCommercialShare = useFindOrCreateCommercialShare();

  const openLink = (guid: string) => {
    window.location.href = `${String(import.meta.env.VITE_MARKETING_SITE_URL)}/s/${guid}`;
  };

  const share = async () => {
    const data = await findOrCreateCommercialShare.mutateAsync({
      commercial_id: Number(commercialId),
      published: true,
    });

    if (!data) {
      toast.error("There was an issue creating your shareable commercial.");
      return;
    }

    // adding artificial delay
    // cause she fast
    await sleep(1);
    openLink(data.guid);
  };

  useOnMount(share);

  return (
    <Container>
      <Flex mb="5" align="center" justify="center" mt="9">
        <Box mt="1" mr="4">
          <ReloadIcon className="animate-spin" />
        </Box>
        <Heading as="h3">Loading your shareable commercial...</Heading>
      </Flex>
    </Container>
  );
}

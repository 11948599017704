import { yupResolver } from "@hookform/resolvers/yup";
import { ReloadIcon } from "@radix-ui/react-icons";
import { Button, Flex } from "@radix-ui/themes";
import { type UseMutateAsyncFunction } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import axios, { AxiosError } from "axios";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { schemas } from "@/lib/schemas";

import { getError } from "@/util/error";

type MutationFunction = UseMutateAsyncFunction<any, Error, any, any>;
type Props = {
  action: MutationFunction;
  callback?: () => Promise<void>;
  children: React.ReactNode | Array<React.ReactNode>;
  successMessage?: string;
  isPending: boolean;
  isSuccess: boolean;
  isDisabled?: boolean;
  redirectUrl?: string;
  schema?: string;
  submitText?: string;
  showButton?: boolean;
  dirtyButton?: boolean;
  buttonVariant?:
    | "classic"
    | "solid"
    | "soft"
    | "surface"
    | "outline"
    | undefined;
  buttonSize?: "1" | "2" | "3" | "4" | undefined;
  formArgs?: object;
};

export const Form = ({
  action,
  callback,
  children,
  successMessage,
  isPending,
  redirectUrl,
  schema,
  isDisabled = false,
  submitText = "Submit",
  showButton = false,
  buttonVariant = "solid",
  buttonSize = "3",
  formArgs = {},
}: Props) => {
  const navigate = useNavigate();
  const _formArgs = schema
    ? { ...formArgs, resolver: yupResolver(schemas[schema]) }
    : formArgs;
  const methods = useForm(_formArgs);
  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(async (formData) => {
    try {
      await action(formData);

      if (callback) {
        await callback();
      }

      if (redirectUrl) {
        await navigate({ to: redirectUrl });
        return;
      }

      if (successMessage) {
        toast.success(successMessage);
        return;
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(getError(error as AxiosError));
        return;
      }

      console.error(error);
    }
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} encType="multipart/form-data">
        <Flex direction="column" gap="3">
          {children}
        </Flex>

        {showButton && (
          <Button
            mt="5"
            type="submit"
            disabled={isPending || isDisabled}
            variant={buttonVariant}
            size={buttonSize}
          >
            {isPending && (
              <>
                <ReloadIcon className="animate-spin" /> Loading
              </>
            )}
            {!isPending && submitText}
          </Button>
        )}
      </form>
    </FormProvider>
  );
};

import { useNarrators } from "@/api/narrators";
import { useSpeechLibraries } from "@/api/speechLibraries";
import { useSession } from "@/hooks/session";
import { Flex } from "@radix-ui/themes";
import { useWatch } from "react-hook-form";

import { Container } from "@/components/Container";
import { Field, type Option } from "@/components/Field";
import { Submit } from "@/components/Field/Submit";
import { Form } from "@/components/Form";

import { isProduction } from "@/util/environment";

type Props = {
  onSubmit?: () => Promise<unknown>;
  active?: boolean;
};

export const CommercialCreateStepNarrator = ({ onSubmit, active }: Props) => {
  if (!onSubmit) return null;

  return (
    <Form
      action={onSubmit}
      isSuccess={true}
      isPending={false}
      schema="createCommercialStep3"
      isDisabled={!active}
    >
      <InnerForm />
    </Form>
  );
};

// Totally not a hack
const DEFAULT_LIBRARY_NAME = isProduction() ? "Open Ai" : "RVC";

const InnerForm = () => {
  const { session } = useSession();

  const speechLibraryId = useWatch({ name: "speech_library_id" });
  const { data: speechLibraryData } = useSpeechLibraries();

  const selectedSpeechLibraryName =
    speechLibraryData?.find(({ id }) => {
      return id === +speechLibraryId;
    })?.name ?? DEFAULT_LIBRARY_NAME;

  const { data: narratorData } = useNarrators({
    speech_library_name: selectedSpeechLibraryName,
  });

  if (!speechLibraryData) return null;

  const formattedSpeechLibraries: Option[] =
    speechLibraryData?.map(
      ({ id, name }): Option => ({
        label: name,
        value: String(id),
      }),
    ) ?? ([] as Option[]);

  const defaultSpeechLibrary = speechLibraryData.find(
    ({ name }) => name === DEFAULT_LIBRARY_NAME,
  );

  return (
    <Container size="2">
      <Flex gap="5" direction="column" mb="4">
        {session?.is_internal && (
          <Field
            data-testid="form-commercial-speech-library"
            name="speech_library_id"
            label="Speech Library"
            type="select"
            options={formattedSpeechLibraries}
            placeholder="Select Speech Library..."
            defaultValue={String(defaultSpeechLibrary?.id)}
          />
        )}

        <Field
          data-testid="form-commercial-narrator"
          name="narrator_id"
          label="Narrator"
          type="select-narrator"
          narrators={narratorData}
        />

        <Submit
          text="Continue"
          data-testid="commercial-create-narrator-submit-button"
        />
      </Flex>
    </Container>
  );
};
